/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type Dimension = 0 | 0 | 1 | 1 | 2 | 2 | 3 | -3 | -2 | -1 | -1;

export const Dimension = {
    NUMBER_0: 0 as Dimension,
    NUMBER_02: 0 as Dimension,
    NUMBER_1: 1 as Dimension,
    NUMBER_12: 1 as Dimension,
    NUMBER_2: 2 as Dimension,
    NUMBER_22: 2 as Dimension,
    NUMBER_3: 3 as Dimension,
    NUMBER_MINUS_3: -3 as Dimension,
    NUMBER_MINUS_2: -2 as Dimension,
    NUMBER_MINUS_1: -1 as Dimension,
    NUMBER_MINUS_12: -1 as Dimension
};

