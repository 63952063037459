import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsNgModule } from '@seech/icons-ng';
// import { TaskSummaryComponent } from '../general/detail/components/task-summary/task-summary.component';
import { ColorbystatusDirective } from './directives/color-by-status/color-by-status.directive';
@NgModule({
  declarations: [
    // TaskSummaryComponent,
    ColorbystatusDirective
    ],
  imports: [
    CommonModule,
    IconsNgModule,
    ],
  exports: [
    // TaskSummaryComponent,
    ColorbystatusDirective
    ],
})
export class SharedModule {}
