import { Injectable } from '@angular/core';
import { ReviewService as ReviewClient, ReviewRequest } from '../../../../generated';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private reviewClient: ReviewClient) {}

  /**
   * Creates new reviews.
   * @param {Review[]} reviews - An array of reviews to be created.
   * @returns {Observable<Review>} An Observable that emits a response indicating the success of the operation.
   */
  create(reviews: ReviewRequest[]) {
    return this.reviewClient.reviewCreate(reviews);
  }

  /**
   * Retrieves the reviews given by the current user for a specific project.
   * @param {string} projectId - The ID of the project to retrieve reviews for.
   * @param {number | undefined} page - The page number of reviews to retrieve.
   * @param {number | undefined} size - The size of the page.
   * @param {string | undefined} orderBy - The field to order reviews by.
   * @returns {Observable<Review[]>} An Observable that emits a response indicating the success of the operation.
   */
  getCurrentUserReviewByProjectId(
    projectId: string,
    page?: number,
    size?: number,
    orderBy?: string
  ) {
    return this.reviewClient.reviewGetCurrentUserReviewByProjectId(
      projectId,
      page,
      size,
      orderBy
    );
  }

  /**
   * Retrieves the reviews received by the current user for a specific project.
   * @param {string} reviewerId - The ID of the reviewer.
   * @param {string | undefined} orderBy - The field to order reviews by.
   * @returns {Observable<Review[]>} An Observable that emits a response indicating the success of the operation.
   */
  reviewGetCurrentUserReviewByReviewerId(reviewerId: string, orderBy?: string) {
    return this.reviewClient.reviewGetCurrentUserReviewByReviewerId(
      reviewerId,
      orderBy
    );
  }

  /**
   * Retrieves summary information for the reviewee.
   * @returns {Observable<RevieweeInfo>} An Observable that emits a response indicating the success of the operation.
   */
  getRevieweeSummary() {
    return this.reviewClient.reviewGetRevieweeSummary();
  }

  /**
   * Retrieves reviews for a specific reviewee and badge.
   * @param {string} revieweeId - The ID of the reviewee.
   * @param {string} badgeId - The ID of the badge.
   * @param {number | undefined} page - The page number of reviews to retrieve.
   * @param {number | undefined} size - The size of the page.
   * @param {string | undefined} orderBy - The field to order reviews by.
   * @returns {Observable<Review[]>} An Observable that emits a response indicating the success of the operation.
   */
  getReviews(
    revieweeId: string,
    badgeId?: string,
    page?: number,
    size?: number,
    orderBy?: string
  ) {
    return this.reviewClient.reviewGetReviews(
      revieweeId,
      badgeId,
      page,
      size,
      orderBy
    );
  }

  /**
   * Retrieves reviews for a specific reviewee and badge.
   * @param {string} reviewerId - The ID of the reviewee.
   * @param {string} projectId - The ID of the badge.
   * @returns {Observable<Review[]>} An Observable that emits a response indicating the success of the operation.
   */
  reviewGetReviewsByReviewerIdProjectId(
    reviewerId: string,
    projectId: string
  ) {
    return this.reviewClient.reviewGetReviewsByReviewerIdProjectId(
      reviewerId,
      projectId
    );
  }
}
