<ng-template #sharedContent>
  <div class="details">
    <div class="details-first flex-wrap items-start">
      <div *ngIf="!isFetchingProfessionalServices" class="flex justify-center">
        <div class="!h-10 w-10" [routerLink]="profileRoute">
          <img
            *ngIf="professionalItem.user.imageURL !== ''"
            height="300"
            width="150"
            [ngSrc]="professionalItem.user.imageURL || 'src//'"
            alt="icon"
            class="icon !h-full rounded-2xl !w-full object-cover"
          />

          <div
            *ngIf="professionalItem.user.imageURL === ''"
            class="w-full rounded-full bg-secondary text-white-100 h-full font-medium flex items-center text-xl p-1 justify-center"
          >
            <p class="">
              {{ professionalItem.user.name | initials }}
            </p>
          </div>
        </div>

        <div class="mx-2">
          <div class="d-flex align-items-center justify-content-start">
            <div class="">
              <p
                class="job"
                (click)="closeModal()"
                [routerLink]="profileRoute"
                role="button"
              >
                {{ professionalItem.user.name }}
              </p>
            </div>
            <div *ngIf="professionalItem.isSaved" class="mx-2">
              <i
                class="text-md text-[#009429]"
                [sch-icon]="'star-badge-fill'"
              ></i>
            </div>
          </div>

          <div class="at-tag">
            <p class="text-sm font-normal text-[#48525F]">
              &#64;{{ professionalItem.user.username }}
            </p>
          </div>
        </div>
      </div>

      <div class="mx-2" *ngIf="isFetchingProfessionalServices">
        <seech-placeholder
          [width]="'8.125rem'"
          [height]="'3.75rem'"
          [shape]="'rect'"
        ></seech-placeholder>
      </div>
      <div *ngIf="!isFetchingProfessionalServices">
        <div class="flex flex-col items-center">
          <div class="flex align-stretch">
            <button
              [disabled]="isBookMarking"
              (click)="bookmark()"
              class="button-save !text-secondary"
            >
              <span
                [sch-icon]="
                  professionalItem.isSaved ? 'bookmark-fill' : 'bookmark'
                "
                class="me-0"
              ></span>
            </button>

            <ng-container
              *ngIf="showHireButton && professionalItem.user.id !== user?.id"
            >
              <button (click)="hirePro()" class="button-hire">Hire</button>
            </ng-container>
          </div>

          <div class="stats text-[11px] mt-2 text-[#48525F]">
            <p class="my-1">
              Completed Jobs: {{ professionalItem.user.totalCompletedJobs }}
            </p>
            <p class="my-1">
              Member since {{ professionalItem.user.createdOn | date : 'yyyy' }}
            </p>
          </div>
        </div>

        <!-- <div class="star-ratings my-2 flex items-center">
        <app-profindar-star-rating [rate]="4"></app-profindar-star-rating>
        <div>
          <p class="text-grey-200 font-[400] mx-1 text-xs opacity-60">
            (25 Reviews)
          </p>
        </div>
      </div> -->
      </div>

      <div class="mx-2" *ngIf="isFetchingProfessionalServices">
        <seech-placeholder
          [width]="'8.125rem'"
          [height]="'3.75rem'"
          [shape]="'rect'"
        ></seech-placeholder>
      </div>
    </div>

    <div class="details-body">
      <div class="details-second mx-3 !py-3">
        <p class="job-details !capitalize !font-normal">
          Professional Overview
        </p>
      </div>

      <!-- <div class="details-third !font-normal">
      <div class="item">
        <p class="item-title my-2">Budget</p>
        <div class="item-name">$1200 / Hour</div>
      </div>
      <div class="item">
        <p class="item-title">Tasks</p>
        <div class="item-name">Plumbing</div>
        <div class="item-name">Carpentry</div>
        <div class="item-name">Carpentry</div>
      </div>
    </div> -->

      <!-- <div class="details-fourth details-third">

    </div> -->

      <div class="details-fifth details-fourth details-third mx-3">
        <div class="item my-1">
          <p class="item-title my-1">Services</p>
          <div *ngIf="!isFetchingProfessionalServices" class="flex flex-wrap">
            <div
              *ngFor="
                let service of professionalItem.servicesSummary | splitString
              "
              class="item-name my-1 mr-1"
            >
              {{ service }}
            </div>
          </div>
          <!-- <div *ngIf="!isFetchingProfessionalServices" class="item-name">Carpentry</div>
        <div *ngIf="!isFetchingProfessionalServices" class="item-name">Carpentry</div> -->

          <div *ngIf="isFetchingProfessionalServices">
            <seech-placeholder
              [width]="'10rem'"
              [height]="'1.25rem'"
              [shape]="'rect'"
            ></seech-placeholder>
          </div>
        </div>

        <!-- <div class="item !my-5">
        <p class="item-title my-1">Service Delivery</p>
        <div *ngIf="!isFetchingProfessionalServices" class="item-name">Onsite</div>
        <div *ngIf="!isFetchingProfessionalServices" class="item-name">Remote</div>
        <div *ngIf="!isFetchingProfessionalServices" class="item-name">Mobile</div>

        <div  *ngIf="isFetchingProfessionalServices">
          <seech-placeholder [width]="'10rem'" [height]="'1.25rem'" [shape]="'rect'" ></seech-placeholder>
        </div>
      </div> -->

        <p class="title mt-3">Location</p>
        <p *ngIf="!isFetchingProfessionalServices" class="address">
          <span [sch-icon]="'location-outline'"></span>
          {{ professionalItem.user.location }}
        </p>

        <div *ngIf="isFetchingProfessionalServices">
          <seech-placeholder
            [width]="'10rem'"
            [height]="'1.25rem'"
            [shape]="'rect'"
          ></seech-placeholder>
        </div>

        <div class="mt-3">
          <p class="title">Badges Earned</p>
          <div class="button-groups">
            <div
              *ngIf="professionalItem.badgesSummary as badgesSummary"
              class="tags flex items-center overflow-x-scroll w-[95%]"
            >
              <ng-container
                *ngFor="
                  let badge of badgesArray(professionalItem.badgesSummary)
                "
              >
                <app-badge [label]="badge"></app-badge>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="star-ratings mt-3">
          <div class="">
            <p class="title">Ratings</p>
          </div>
          <div
            *ngIf="!isFetchingProfessionalServices"
            class="flex items-center"
          >
            <app-profindar-star-rating
              [(ngModel)]="professionalItem.reviewScore"
              [starCount]="5"
              [disabled]="true"
            ></app-profindar-star-rating>

            <div>
              <p class="text-grey-200 font-[400] mx-1 text-xs opacity-60">
                ({{ professionalItem.reviewCount || 0 }} Reviews)
              </p>
            </div>
          </div>

          <div *ngIf="isFetchingProfessionalServices">
            <seech-placeholder
              [width]="'10rem'"
              [height]="'1.25rem'"
              [shape]="'rect'"
            ></seech-placeholder>
          </div>
        </div>

        <div class="mt-3">
          <p class="title">About</p>
          <p *ngIf="!isFetchingProfessionalServices" class="desc">
            {{ professionalItem.user.bio }}
          </p>

          <div *ngIf="isFetchingProfessionalServices">
            <seech-placeholder
              [width]="'10rem'"
              [height]="'40px'"
              [shape]="'rect'"
            ></seech-placeholder>
          </div>
        </div>
      </div>

      <div class="details-sixth mx-3">
        <p class="title">Projects</p>

        <div class="past-projects">
          <div class="experience-status flex gap-2 items-start flex-wrap">
            <div class="w-auto mb-2">
              <div
                class="py-1 w-auto px-2 text-xs flex justify-between items-center rounded-full active"
              >
                <div>
                  <i
                    class="text-sm mr-2 bg-[#FDF2E7] rounded-full p-1"
                    [sch-icon]="'folder'"
                  ></i>
                </div>
                <div>
                  <p>
                    Active (
                    {{ professionalItem.projectStatusCounts['active'] || 0 }}
                    )
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <div
                class="py-1 w-auto px-2 text-xs flex justify-between items-center rounded-full completed"
              >
                <div>
                  <i
                    class="text-sm mr-2 completed-icon rounded-full p-1"
                    [sch-icon]="'folder'"
                  ></i>
                </div>
                <div>
                  <p>
                    Completed ({{
                      professionalItem.projectStatusCounts['completed'] || 0
                    }})
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <div
                class="py-1 w-auto px-2 text-xs flex justify-between items-center rounded-full abandoned"
              >
                <div>
                  <i
                    class="text-sm mr-2 abandoned-icon rounded-full p-1"
                    [sch-icon]="'folder'"
                  ></i>
                </div>
                <div>
                  <p>
                    Abandoned (
                    {{ professionalItem.projectStatusCounts['abandoned'] || 0 }}
                    )
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-between mt-4">
          <div class="gallery-title">
            <p class="font-medium text-lg">Gallery</p>
          </div>
          <div class="count">
            <p class="text-secondary">(0)</p>
          </div>
        </div>

        <div class="gallery-image-items flex items-center mt-3">
          <!-- <div
          *ngFor="let number of [].constructor(4); let i = index"
          class="img-item w-[80%] text-center mx-2 shadow-sm"
        >
          <div class="g-img h-16">
            <img
              class="h-full object-cover"
              src="{{ cdnBaseUrl }}temp/gallery-item1.png"
              alt="images"
            />
          </div>
          <div class="img-title text-[11px] font-medium p-2">
            <p>Plumbing</p>
          </div>
          <div class="img-action-btn text-[10px] px-2 pb-2">
            <button class="bg-secondary w-full rounded-full">View</button>
          </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="isMobile; else nonModalContent">
  <app-general-modal padding="2px" label="">
    <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
  </app-general-modal>
</ng-container>

<!-- Directly display the content when not using a modal -->
<ng-template #nonModalContent>
  <div *ngTemplateOutlet="sharedContent"></div>
</ng-template>
