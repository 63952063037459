import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserSummary } from '@profindar/shared-ng';

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss'],
})
export class ParticipantListComponent {
  @Input() particpants: UserSummary[] = [];
  @Output() particpantClick: EventEmitter<UserSummary> = new EventEmitter();

}
