import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CONSTANT } from '../../../constants';
import { User } from '@profindar/shared-ng';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @HostBinding('style.height')
  height = '100%';
  cdnBaseUrl = environment.cdnBaseUrl;
  @Output() readonly profileClick: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() readonly exploreClick: EventEmitter<void> =
    new EventEmitter<void>();
  route = CONSTANT.ROUTE;
  @Input({ required: true }) unreadNotificationCount = 0;
  @Input({ required: true }) user: User | null = null;
  @Input({ required: true }) nameLabel = '';
  @Input({ required: true }) unreadMessagesCount = 0;
  @Input({ required: true }) showSidenav!:boolean;


  public get showExplore() : boolean {
    return !this.showSidenav;
  }

}
