import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { SessionService } from './session.service';
import { SKIP_OPTION, Session, Token } from '@profindar/shared-ng';
import { StorageService } from './storage.service';
import { UtilitiesService } from '@seech/core-ng';
import { CONSTANT } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private freshToken$?: Observable<Token>;
  
  constructor(
    private http: HttpClient, 
    private sessionService: SessionService, 
    private storageService: StorageService,
    private utilitiesService: UtilitiesService) { }

  getCurrentToken() {
    const session = this.storageService.getItem<Session>(CONSTANT.STORAGE_KEYS.CURRENT_SESSION)
    return session?.token;
  }

  new_access_token(refreshToken: string) {
    const headers: Record<string, string> = { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Headers': 'Content-Type',
      'refreshToken': refreshToken
    };

    if (!environment.production) headers['client_id'] = environment.CLIENT_ID // needed for localhost / debugging only

    // return this.isAuthenticated() ? of(this.getCachedToken()) : this.getFreshToken(headers);

    if (this.isAuthenticated()) {
      this.freshToken$ = undefined;
      return of(this.getCachedToken());
    } 

    return this.getFreshToken(headers)
  }

  private getFreshToken(headers: any) {
    if (!this.freshToken$) {
      this.freshToken$ = this.http.get<Token>(`${environment.AUTH_API_URL}user/renew-token`, { headers, context: new HttpContext().set(SKIP_OPTION.ALL, true) })
          .pipe(
            tap(response => this.updateToken(response)),
            shareReplay(1)
          );
    };

    return this.freshToken$;
  }

  getCachedToken() {
    const session = this.sessionService.getCurrentSession();
    return session?.token;
  }

  //================================= Update token =============================================

  updateToken(token: Token) {
    const session = this.sessionService.getCurrentSession();

    if (token?.access_token && session?.token) {
      token.refresh_token = !token.refresh_token ? session.token.refresh_token : token.refresh_token; //preserve existing refresh token if new RT is null
      session.token = token;
      this.sessionService.updateSession(session);
    }
  }

  //================================= Validate token =============================================

  public isAuthenticated(): boolean {
    const session = this.sessionService.getCurrentSession();
    if (!session || JSON.stringify(session) == '{}') { return false; }
    const expiresAt = session.token.expires || '{}';
    return new Date().getTime() < new Date(expiresAt).getTime();
  }

  public isLoggedIn(): boolean {
    let session = this.sessionService.getCurrentSession();
    return session && session.id ? true : false;
  }

  //public parseJwt(access_token) {
  //  var base64Url = access_token.split('.')[1];
  //  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //  return JSON.parse(window.atob(base64));
  //};

  //================================= External Navigation =============================================

  gotoExternalLogin(parameters = <Record<string, string>>{}) {
    this.storageService.reset();

    if (!environment.production) parameters['client_id'] = environment.CLIENT_ID; // needed for localhost / debugging only

    const params = parameters ? '&' + Object.keys(parameters)
                                            .map(x => x)
                                            .map(key => `${key}=${parameters[key]}`)
                                            .join('&') : '';
    
    const origin = this.utilitiesService.getOrigin();
    const redirectUrl = `${origin}/login${params}`;
    window.location.href = `${environment.AUTH_WEB_URL}/login?redirect=${redirectUrl}`;


    //window.open(environment.authWebURL + '/login', '_blank').focus();

    //let loginBtn = document.createElement("a");
    //loginBtn.target = "_blank";
    //loginBtn.href = environment.authWebURL + "/login";
    //loginBtn.click();
  }
}
