/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Requisition } from './requisition';


export interface SavedEntityOfRequisition { 
    id: string;
    entityId: string;
    entityName: string;
    createdOn: Date;
    createdBy: string;
    createdByNavigation: User;
    savedItem?: Requisition;
}

