import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FeatureFlagService } from '@seech/core-ng';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard {
  static guard(router: Router, authService: AuthService, featureFlagService: FeatureFlagService): CanActivateFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
      const flag = route.data?.['flag'];

      if (flag && !featureFlagService.isActive(flag)) {
        router.navigate(['/home']);
        return false;
      }

      if (route.data?.['anonymous'] === true) {
        return true;
      }

      if (authService.isLoggedIn()) {
        return true;
      }

      router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    };
  }
}

