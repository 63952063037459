/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { CONSTANT } from '../constants';
import { Session, User } from '@profindar/shared-ng';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private storageService: StorageService) {}

  initSession(user: User) {
    this.updateSession(user.sessions[0]);
    user.sessions = []; // delete user.sessions;
    this.storageService.set(CONSTANT.STORAGE_KEYS.CURRENT_USER, user); // set in local storage
  }

  getCurrentSession() {
    //  use this to get current session id
    // return JSON.parse(this.storageService.get<Session>(CONSTANT.STORAGE_KEYS.CURRENT_SESSION)) || {};
    return this.storageService.getItem<Session>(
      CONSTANT.STORAGE_KEYS.CURRENT_SESSION
    );
  }

  updateSession(session: Session) {
    session.token.expires = new Date(
      session.token.expires_in * 1000 + new Date().getTime()
    );
    this.storageService.set(CONSTANT.STORAGE_KEYS.CURRENT_SESSION, session); //session key set here -- session Id is sitting here!
    
  }

  endSession() {
    // this.delete(this.key.currentSession);
    // this.delete(this.key.currentUser);
    this.storageService.reset();
  }

  setCurrentUser(user: User) {
    this.storageService.set(CONSTANT.STORAGE_KEYS.CURRENT_USER, user);
  }

  getCurrentUser() {
    return this.storageService.getItem<User>(
      CONSTANT.STORAGE_KEYS.CURRENT_USER
    );
  }

  getCurrentUserAsync() {
    return this.storageService.get<User>(
      CONSTANT.STORAGE_KEYS.CURRENT_USER
    );
  }

  getCurrentUserId() {
    return this.getCurrentUser()?.id;
  }

  getCurrentUsername() {
    return this.getCurrentUser()?.username;
  }

  getCurrentUserFullName() {
    return this.getCurrentUser()?.name;
  }

  getCurrentUserImageURL() {
    return this.getCurrentUser()?.imageURL;
  }
}
