/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface ProfessionalSummary { 
    isSaved: boolean;
    projectStatusCounts: { [key: string]: number; };
    id: string;
    userId: string;
    servicesSummary: string;
    badgesSummary?: string;
    reviewScore?: number;
    reviewCount?: number;
    createdOn: Date;
    user: User;
}

