import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  ProjectItemConfig } from '../../../../models';
import {  PROJECT_STATUS, ProjectResponse } from '@profindar/shared-ng';
@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
})


export class ProjectItemComponent {
  @Input() data: ProjectResponse  = {

  } as ProjectResponse;
  @Input() config: ProjectItemConfig = {
    buttonLabel: 'View',
  };

  @Output() viewProject = new EventEmitter<void>();
  @Output() bookMarkProject = new EventEmitter<boolean>();
  @Output() moreActions = new EventEmitter<void>();

  bookMarked: boolean = false;
  projectViewed: boolean = false;
  displayString: string = '';
  statusColors = PROJECT_STATUS;
  // services: string[] = [];
  // pfColors = stylesVariables;

  constructor() { }

  onProjectView(): void {
    this.viewProject.emit();
    this.projectViewed = true;
  }

  onBookMarkChange(): void {
    this.bookMarked = !this.bookMarked;
    this.bookMarkProject.emit(this.bookMarked);
  }

  onMoreActions(): void {
    this.moreActions.emit();
  }
}
