/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Analytic } from '../model/models';
import { BlobMetaData } from '../model/models';
import { Diagnostic } from '../model/models';
import { FeatureFlag } from '../model/models';
import { FeatureFlagDto } from '../model/models';
import { FileUploadContext } from '../model/models';
import { InternetSpeed } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface GlobalServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param analytic 
     */
    globalAddAnalytic(analytic: Analytic, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param diagnostic 
     */
    globalAddDiagnostic(diagnostic: Diagnostic, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param model 
     */
    globalCreateFeatureFlag(model: Array<FeatureFlag>, extraHttpRequestParams?: any): Observable<Array<FeatureFlag>>;

    /**
     * 
     * 
     * @param parentID 
     * @param blobID 
     */
    globalDelete(parentID: string, blobID: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param parentID 
     */
    globalDeleteParent(parentID: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     */
    globalGetAllAnalytic(extraHttpRequestParams?: any): Observable<Array<Analytic>>;

    /**
     * 
     * 
     */
    globalGetAllDiagnostics(extraHttpRequestParams?: any): Observable<Array<Diagnostic>>;

    /**
     * 
     * 
     */
    globalGetAllFeatureFlag(extraHttpRequestParams?: any): Observable<Array<FeatureFlagDto>>;

    /**
     * 
     * 
     */
    globalGetAllFeatureFlagData(extraHttpRequestParams?: any): Observable<Array<FeatureFlag>>;

    /**
     * 
     * 
     * @param id 
     */
    globalGetAnalytic(id: string, extraHttpRequestParams?: any): Observable<Analytic>;

    /**
     * 
     * 
     * @param id 
     */
    globalGetDiagnostic(id: string, extraHttpRequestParams?: any): Observable<Diagnostic>;

    /**
     * 
     * 
     * @param featureName 
     */
    globalGetFeatureFlagByName(featureName: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     */
    globalGetIPAddress(extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     */
    globalGetSpeedByUrl(extraHttpRequestParams?: any): Observable<InternetSpeed>;

    /**
     * 
     * 
     * @param blobMeta 
     */
    globalPatch(blobMeta: BlobMetaData, extraHttpRequestParams?: any): Observable<BlobMetaData>;

    /**
     * 
     * 
     * @param model 
     */
    globalPatchFeatureFlag(model: FeatureFlag, extraHttpRequestParams?: any): Observable<FeatureFlag>;

    /**
     * 
     * 
     * @param blobMeta 
     */
    globalPost(blobMeta: BlobMetaData, extraHttpRequestParams?: any): Observable<BlobMetaData>;

    /**
     * 
     * 
     * @param parentID 
     * @param model 
     */
    globalUpload(parentID: string, model?: Array<FileUploadContext>, extraHttpRequestParams?: any): Observable<BlobMetaData>;

    /**
     * 
     * 
     * @param files 
     */
    globalUploadMessageFile(files?: Blob, extraHttpRequestParams?: any): Observable<Array<string>>;

}
