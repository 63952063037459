interface DropdownOption {
  value: string;
  label: string;
}

export enum Statuses {
  Received = 1,
  Pending = 2,
  Withdraw = 3,
}

export enum TRANSACTION_TYPE {
  Credit = 1,
  Pending = 2,
  Debit = 3,
}


export const DATE_RANGE: { [key: string]: DropdownOption } = {
  DAY: { value: 'day', label: 'Last Day (24 hours)' },
  WEEK: { value: 'week', label: 'Last Week (7 days)' },
  MONTH: { value: 'month', label: 'Last Month (30 days)' },
  YEAR: { value: 'year', label: 'Last Year (12 months)' },
  CUSTOM: { value: 'custom', label: 'Custom Range' },
};

 export const DATE_RANGE_LIST: DropdownOption[] = Object.values(DATE_RANGE);
