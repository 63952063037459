/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ProfessionalService } from '../model/models';
import { ProfessionalServiceRequest } from '../model/models';
import { UpdateProfessionalServiceRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProfessionalServiceServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param professionalServiceRequest 
     */
    professionalServiceAddProfessionalService(professionalServiceRequest: ProfessionalServiceRequest, extraHttpRequestParams?: any): Observable<ProfessionalService>;

    /**
     * 
     * 
     * @param id 
     * @param isDeactivated 
     */
    professionalServiceDeactivateProfessionalServiceById(id: string, isDeactivated: boolean, extraHttpRequestParams?: any): Observable<ProfessionalService>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    professionalServiceGetProfessionalService(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProfessionalService>>;

    /**
     * 
     * 
     * @param id 
     */
    professionalServiceGetProfessionalServiceById(id: string, extraHttpRequestParams?: any): Observable<ProfessionalService>;

    /**
     * 
     * 
     * @param professionalServiceRequest 
     */
    professionalServiceUpdateProfessionalService(professionalServiceRequest: UpdateProfessionalServiceRequest, extraHttpRequestParams?: any): Observable<ProfessionalService>;

}
