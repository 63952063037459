/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BidNotification } from './bidNotification';
import { PaymentRequestNotification } from './paymentRequestNotification';
import { ProjectCalenderActivityNotification } from './projectCalenderActivityNotification';
import { GeneralNotification } from './generalNotification';
import { BidInvitationNotification } from './bidInvitationNotification';
import { TaskUpdateRequestNotification } from './taskUpdateRequestNotification';
import { PaymentScheduleChangeRequestNotification } from './paymentScheduleChangeRequestNotification';
import { ReviewRequestNotification } from './reviewRequestNotification';
import { TaskUpdateNotification } from './taskUpdateNotification';


export interface Notification { 
    id: string;
    userId: string;
    notificationScenario: string;
    createdOn: Date;
    viewedOn?: Date;
    respondedOn?: Date;
    createdBy?: string;
    bidInvitationNotifications: Array<BidInvitationNotification>;
    bidNotifications: Array<BidNotification>;
    generalNotifications: Array<GeneralNotification>;
    paymentRequestNotifications: Array<PaymentRequestNotification>;
    paymentScheduleChangeRequestNotifications: Array<PaymentScheduleChangeRequestNotification>;
    projectCalenderActivityNotifications: Array<ProjectCalenderActivityNotification>;
    reviewRequestNotifications: Array<ReviewRequestNotification>;
    taskUpdateNotifications: Array<TaskUpdateNotification>;
    taskUpdateRequestNotifications: Array<TaskUpdateRequestNotification>;
}

