<div class="bottom-sheet-container w-100 bg-white">
  <div class="create-bottom-sheet w-100 d-flex flex-column">
    <button class="become-pro" (click)="becomeAProClick.emit()">
      {{user?.isProfessional ? "Add New Service":"Become a Pro" }}
    </button>
    <button class="new-project" (click)="newProjectClick.emit()">
      <img src="{{cdnBaseUrl}}images/add-circle.svg" width="20px" height="20px">
      <span>New Job</span>
    </button>

  </div>
</div>