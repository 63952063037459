<nav class="side-nav-items w-100">
  <ul class="d-flex flex-column gap-2 w-100">

    <a *ngFor="let item of navigationItems" (click)="selectedChanged.emit()" class="link-item d-flex w-100"
      [routerLink]="item.route" [routerLinkActive]="'link-item-active'">
      <div class="icon-border">
        <img *ngIf="item.imageUrl; else icon" [src]="item.imageUrl" width="20px" height="20px">
        <ng-template #icon>
          <i [sch-icon]="item.icon!" class="icon"></i>
        </ng-template>
      </div>
      <p class="label">{{item.label}}</p>
    </a>

  </ul>
</nav>
