<div
  (click)="onCardClicked()"
  [ngStyle]="{ width: width, height: height }"
  class="card-body cursor-pointer"
>
  <div class="card-image w-[46%]" [routerLink]="profileRoute">
    <img
      *ngIf="cardInfo.user.imageURL !== ''"
      class="!w-full !h-full"
      height="100"
      width="100"
      ngSrc="{{ cdnBaseUrl }}{{ cardInfo.user.imageURL }}"
    />
    <div
      *ngIf="cardInfo.user.imageURL === ''"
      class="w-full rounded-tl-lg rounded-bl-lg bg-secondary text-white-100 h-full font-medium flex items-center text-[3.438rem] justify-center"
    >
      <p class="">
        {{ cardInfo.user.name | initials }}
      </p>
    </div>
  </div>

  <div class="card-texts flex w-[54%] p-2 lg:!p-1 py-1 pt-1">
    <div class="gap-[2px] w-[92%] flex flex-col justify-evenly">
      <div class="card-text-header flex justify-between">
        <div class="name centered-items justify-between">
          <div>
            <p
              class="uppercase font-medium text-[0.75rem]"
              (click)="$event.stopPropagation()"
              [routerLink]="profileRoute"
            >
              {{ cardInfo.user.name }}
            </p>
          </div>
          <div *ngIf="cardInfo.isSaved" class="icn !text-lg ml-1">
            <i
              class="text-md text-[#009429]"
              [sch-icon]="'star-badge-fill'"
            ></i>
          </div>
        </div>
      </div>

      <div class="ml-[-2.5px] location flex items-center">
        <div class="icn">
          <i sch-icon="location-outline" class="text-sm lg:text-lg"></i>
        </div>
        <div
          class="loc-text text-[8px] text-container lg:text-xs font-[400] mx-1"
        >
          {{ cardInfo.user.location }}
        </div>
      </div>

      <div class="specs centered-items">
        <div
          class="specs-text centered-items flex !text-grey-200 font-[300] text-[8px] lg:text-[0.74rem]"
        >
          <div class="w-full">
            <!-- <p
              class=""
              *ngFor="
                let service of cardInfo.servicesSummary
                  | splitString
                  | slice : 0 : 2;
                let i = index;
                let last = last;
              "
            >
              <span class="mx-1">
                {{ service }}<span *ngIf="!last"> ,</span>
              </span>
            </p> -->
            <span
              [sch-tooltip]="cardInfo.servicesSummary"
              class="block text-container"
              *ngIf="cardInfo.servicesSummary.length > 0"
              >{{ cardInfo.servicesSummary }}</span
            >
          </div>
          <!-- <div 
          class="flex items-end">
            <i
              [sch-tooltip]="cardInfo.servicesSummary"
              class="font-extrabold !text-[25px] h-[20px] horizontal-fill"
              [sch-icon]="'more-horizontal-fill'"
            ></i>
          </div> -->
        </div>
      </div>
      <div class="card-text-body">
        <div class="button-groups">
          <div
            *ngIf="cardInfo.badgesSummary as badgesSummary"
            class="tags flex items-center h-[15px] overflow-x-scroll w-[100%]"
          >
            <ng-container
              *ngFor="let badge of badgesArray(cardInfo.badgesSummary)"
            >
              <app-badge [label]="badge"></app-badge>
            </ng-container>
          </div>
        </div>

        <div class="star-ratings mt-[6px] flex justify-between items-center">
          <div class="star-ratings flex items-center">
            <app-profindar-star-rating
              [rate]="cardInfo.reviewScore ?? 0"
              [disabled]="true"
            ></app-profindar-star-rating>

            <div>
              <p class="text-grey-200 font-[200] opacity-80 mx-1 text-[9px]">
                ({{ cardInfo.reviewCount || 0 }} Reviews)
              </p>
            </div>
          </div>

          <div class="actions-ratings flex justify-between items-center">
            <div class="actions flex justify-end items-end w-full">
              <div *ngIf="showCheckbox" class="check-box">
                <ng-content select="[checkbox-icon]"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <span>
        <i
          (click)="onMenuClickedEvent(); $event.stopPropagation()"
          class="!text-xl lg:hidden mt-[-20px] !font-extrabold"
          [sch-icon]="'more-vertical-fill'"
        ></i>
      </span>
    </div>

    <!-- <div class="card-text-footer my-1">

    </div> -->
  </div>
</div>
