import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Navigation } from '@seech/layout-ng';
import { RevieweeInfo, User } from '@profindar/shared-ng';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  @Input({ required: true }) menuItems: Navigation[] = [];
  @Output() readonly menuItemClick: EventEmitter<Navigation> =
    new EventEmitter<Navigation>();
  @Input({ required: true }) user: User | null = null;
  services: string[] | null = null;
  @Input({ required: true }) nameLabel = '';
  @Input({ required: true }) reviewInfo: RevieweeInfo | null = null;
  @Output() readonly logOut: EventEmitter<void> = new EventEmitter<void>();
  @Input({ required: true }) accountWebURL = '';

  constructor() {}

  ngOnInit(): void {
    if (this.user?.professionalSummary?.servicesSummary) {
      this.services = this.user.professionalSummary.servicesSummary.split(',');
    }
  }
}
