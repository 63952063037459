/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable no-debugger */
import { Injectable } from '@angular/core';
import { StorageService as StorageClient } from '../../../../generated';
import { Observable } from 'rxjs';
import { BlobResponse } from 'libs/shared-ng/src/lib/generated/model/blobResponse';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  constructor(private storageClient: StorageClient) {}

  storageStageUpload(file: Array<File>): Observable<Array<BlobResponse>> {
    return this.storageClient.storageStageUpload(file);
  }

  storageGetBlobs(entityId: string): Observable<Array<BlobResponse>> {
    return this.storageClient.storageGetBlobsByEntityId(entityId);
  }

}
