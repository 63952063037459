/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityRequest { 
    id?: string;
    projectId: string;
    description?: string;
    startDate: Date;
    endDate: Date;
    projectStartDate?: Date;
    projectProposedEndDate?: Date;
}

