export interface Service{
    icon: string;
    iconLabel: string;
    job: string;
    jobCount: number;
    price: string;
    desc: string;
    rating: number;
    raterCount: number;
}

export interface ServiceItem {
    title: string;
    count: number; // Assuming 'count' is a numeric value representing a quantity or similar
    price: number; // Assuming 'price' is a numeric value. If it includes currency symbols, consider using string instead
    address: string;
    skills: string[];
    description: string;
  }
  
   export interface ServiceItemConfig {
    currency?: string;
    active?: boolean;
  }
  