<section id="dashboard-services">
  <ng-container *ngIf="servicesData.length < 1">
    <div class="d-flex justify-content-center">
      <app-empty-state
        [description]="'No registered service found on your account.'">
      </app-empty-state>
    </div>
  </ng-container>

  <ng-container *ngIf="servicesData.length >= 1">
    <div class="service">
      <div class="summary">
        <ng-container *ngFor="let service of servicesData; let i = index">
          <div class="mb-3">
            <app-service-item
              [data]="service"
              width="100%"
              height="100%"
              [active]="i === activeItemIndex"
              (menuClicked)="selectService(i)"
            ></app-service-item>
          </div>
        </ng-container>
      </div>

      <div class="d-none d-md-inline-block">
        <app-service-detail [viewer]="viewer"></app-service-detail>
      </div>
    </div>
  </ng-container>
</section>
