import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private activePortal = new Subject<TemplatePortal>();
  portalTemplate = this.activePortal.asObservable();

  setPortalTemplate(template: TemplatePortal) {
    this.activePortal.next(template);
  }
}
