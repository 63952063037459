import { CONSTANT as BASE_CONSTANT } from '@profindar/shared-ng';

export const CONSTANT = {
  ...BASE_CONSTANT,

  PLATFORM: 'web',
  // other web specific constants can be added here

  TASK_STATES: {
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    DONE: 'Done',
  },
};
