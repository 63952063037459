import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UxNgModule } from '@seech/ux-ng';
import { ComponentsModule } from '../../modules/general/components/components.module';
import { ControlsNgModule } from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ComponentsModule,
    CommonModule,
    UxNgModule,
    ControlsNgModule,
    IconsNgModule
  ],
})
export class EmptyStateComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() showTextIcon = false;
  @Input() actionText = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() topIcon: any = '';
  @Output() actionClick = new EventEmitter<void>();
}
