<app-general-modal [hasHeader]="false" padding="0">
    <seech-carousel
        #carousel
        animation="slide"
        [mediaItems]="mediaItems"
        [showFullVisibleMedia]="true"
        [controls]="controls"
        [indicators]="indicators"
        [activeMedia]="activeMedia"
        id="gallery-carousel"
  ></seech-carousel>
</app-general-modal>
