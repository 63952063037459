/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @nx/enforce-module-boundaries */
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Badge, LookupService, Review, ReviewService, User, UserProfileResponse } from '@profindar/shared-ng';
import { ModalService } from '@seech/ux-ng';
import { ReviewModel } from 'apps/profindar-web/src/app/models/review.model';
import { Guid } from 'typescript-guid';

export interface SortOption {
  label: string;
  value: string;
  id?: string;
}
@Component({
  selector: 'app-overall-ratings-view',
  templateUrl: './overall-ratings-view.component.html',
  styleUrls: ['./overall-ratings-view.component.scss'],
})

export class OverallRatingsViewComponent implements OnInit, AfterViewInit {

  @ViewChildren('textReview') textReviews!: QueryList<ElementRef>;
  modalRef: any;
  userProfile: UserProfileResponse = <UserProfileResponse>{}
  name: string = "";
  reviewRating: number = 0;
  numberOfReviews: number = 0;
  revieweeId: string = "";
  reviews: ReviewModel[] = [];
  badges: any[] = [];
  sortOptions: any[] = [];
  page = 0;
  size = 50;
  badgeId: string | undefined = "";
  roleId: string = "";
  isempty = false;
  selectedBadge: any;
  selectedSortOption: any;
  orderBy: string = "";
  
  reviewSortOption: any[] = [
    { label: 'Date (Newest)', value: 'newest-date', id: "1" },
    { label: 'Date (Oldest)', value: 'oldest-date', id: "2" },
    { label: 'Rating (Lowest)', value: 'lowest-rating', id: "3" },
    { label: 'Rating (Highest)', value: 'highest-rating', id: "4" }
  ];

  constructor(private modalService: ModalService, private reviewService: ReviewService, private lookupService: LookupService,){}

    ngOnInit(): void {
      this.getSortOptions()
      this.getBadges()
    }

    ngAfterViewInit() {
      this.checkAllOverflows();
    }

    closeModal() { this.modalService.close(this.modalRef)  }

    toggleText(index: number) {
      this.reviews[index].showFullText = !this.reviews[index].showFullText;
    }
  
    checkAllOverflows() {
      this.textReviews.changes.subscribe((list: QueryList<ElementRef>) => {
        list.toArray().map((elementRef, index) => {
          const el = elementRef.nativeElement;
          this.reviews[index].isOverflowing = el.scrollHeight > el.clientHeight;
        });
      });
    }

    getSortOptions(){
      this.sortOptions = this.reviewSortOption.map(option => this.transformSortOptions(option));
      this.selectedSortOption = this.sortOptions[0] //initialise selection option
      this.orderBy = this.reviewSortOption[0].value.toLowerCase()
    }

    getBadges() {
      this.lookupService.getBadges(this.roleId).subscribe({
        next: (response: Badge[]) => {
          response.unshift(this.addDefaultBadge()) //add default badge to top of the array
          this.badgeId = undefined
          this.badges = response.map(badge => this.transformBadges(badge));
          this.selectedBadge = this.badges[0]
          this.getReviews()
        },
        error: (error) => {
        }
      });
    }

    transformBadges(badge: Badge): any {
      return {
        id: badge.id,
        value: badge.description,
        label: badge.description
      }
    }

    transformSortOptions(option: any): any {
      return {
        id: option.id,
        value: option.label,
        label: option.label
      }
    }

    onFilterSelect(targetElement: any){
      const selectedBadge = this.badges.find(badge => badge.value.toLowerCase() === targetElement.toLowerCase()); // or badge.value if you are comparing by value
      this.badgeId = selectedBadge.id == Guid.EMPTY ? undefined : selectedBadge.id
      this.getReviews()
    }

    onSortSelect(targetElement: any){
     const value =  this.reviewSortOption.find(e => e.label == targetElement).value
      this.orderBy = value
      this.getReviews()
    }
    
    getReviews(){
      this.isempty = false
      this.reviewService.getReviews(this.revieweeId, this.badgeId, this.page, this.size, this.orderBy).subscribe({
        next: (response: Review[]) => {
          this.reviews = response.map(review => this.transformToReviewModel(review));
        },
        error: (error) => {
        },
        complete: () => {
          if(this.reviews.length < 1){
            this.isempty = true
          }
        },
      })
    }

    convertUserProfileToUser(review: Review): User {
      const user: User = <User>{};
      user.name = review.reviewer.name;
      user.username = review.reviewer.username;
      user.imageURL = review.reviewer.imageURL;
      return user;
    }

    transformToReviewModel(review: Review): ReviewModel {
      return {
        ...review,
        showFullText: false,
        isOverflowing: false
      };
    }

    addDefaultBadge() : Badge{
      const defaultBadge: Badge = 
        {
            reviews: [],
            id: Guid.EMPTY.toString(),
            name: '',
            projectRoleId: '',
            description: 'Review Summary',
            isDeactivated: false,
            hint: '',
            explanation: '',
        }
        return defaultBadge;
    }

}
