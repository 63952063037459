import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import {
  Activity,
  CONSTANT,
  Calendar,
  CalendarService,
  CalendarServiceService,
  DateRange,
  JobCard,
  ProfileService,
} from '@profindar/shared-ng';
import { Subscription } from 'rxjs';
import { CreateEditEventComponent } from '../create-edit-event/create-edit-event.component';
import { ActivitySummaryComponent } from '../activity-summary/activity-summary.component';

@Component({
  selector: 'app-calendar-week-view',
  templateUrl: './calendar-week-view.component.html',
  styleUrls: ['./calendar-week-view.component.scss'],
})
export class CalendarWeekViewComponent implements OnInit, OnDestroy {
  @Output() switchView = new EventEmitter<'day' | 'week'>();
  sub: Subscription = new Subscription();
  currentWeekDateRange: DateRange = {} as DateRange;
  currentWeekDates!: Date[];
  daysOfTheWeek = CONSTANT.DAYS_OF_THE_WEEK;
  months = CONSTANT.MONTHS;
  calendarProjects!: Calendar[];
  @Input() userId!: string;
  @Input() isGuest!: boolean;

  constructor(
    private modalService: ModalService,
    private calendarService: CalendarServiceService,
    private calendarApiService: CalendarService,
    private profileService: ProfileService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.getDaysOfTheWeek();
    this.getCalendarProjects();
  }

  get displayIsMobile(){
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  getCalendarProjects(){
    const startDate = this.currentWeekDates[0];
    const endDate = this.currentWeekDates[this.currentWeekDates.length - 1];
    const endpoint = this.isGuest ? this.profileService.getCalendarProjects(this.userId, startDate, endDate)
    : this.calendarApiService.getCurrentUserCalendarProjects(startDate, endDate);
    this.sub.add(
      endpoint.subscribe((res: Calendar[]) => {
        this.calendarProjects = res;
      })
    );
  }

  get calendarLabel(): string {
    return this.calendarService.calendarLabel(
      this.currentWeekDateRange,
      this.displayIsMobile
    );
  }

  isToday(date: Date): boolean {
    return this.calendarService.isToday(date);
  }

  getDaysOfTheWeek() {
    this.currentWeekDates = this.calendarService.getDaysOfTheWeek(
      this.currentWeekDateRange
    );
  }
  goToNextMonth() {
    this.currentWeekDates = this.calendarService.goToNextMonth(
      this.currentWeekDateRange
    );
  }
  goToPreviousMonth() {
    this.currentWeekDates = this.calendarService.goToPreviousMonth(
      this.currentWeekDateRange
    );
  }
  goToPreviousWeek() {
    this.currentWeekDates = this.calendarService.goToPreviousWeek(
      this.currentWeekDateRange
    );
  }
  goToNextWeek() {
    this.currentWeekDates = this.calendarService.goToNextWeek(
      this.currentWeekDateRange
    );
  }

  get gridTemplateRows(): string {
    const rowHeaderSize = this.displayIsMobile ? '50px' : '70px';
    const rowSize = this.displayIsMobile ? '65px' : '140px';
    return `${rowHeaderSize} repeat(${this.calendarProjects?.length ?? 3}, ${rowSize})`;
  }

  plotJobOnCalendar(date: Date, project: Calendar): JobCard | null {
    return this.calendarService.plotJobOnCalendar(
      date,
      project,
      'week',
      this.currentWeekDateRange
    );
  }

  openCreateEventModal(action: 'create' | 'view', projectId?: string, data?: Activity) {
    const modalRef = this.modalService.open(CreateEditEventComponent, {
      data: '',
      modalClass: 'modal-dialog-centered',
    });
    modalRef.component.eventView = action;
    modalRef.component.data = data;
    modalRef.component.projectId = projectId;
  }

  openTimelineModal(id: string) {
    const modalRef = this.modalService.open(ActivitySummaryComponent, {
      modalClass: 'modal-dialog-centered',
    });
    
    modalRef.component.projectId = id;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
