import { Injectable } from '@angular/core';
import {
  add,
  sub,
  eachDayOfInterval,
  isEqual,
  isAfter,
  isWithinInterval,
  format,
  differenceInMinutes,
  isBefore,
  isSameHour,
  differenceInHours,
  endOfDay,
  startOfDay,
  differenceInCalendarDays
} from 'date-fns';
import { CONSTANT } from '../constants';
import { Calendar, DateRange, JobCard, JobCardActivity } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CalendarServiceService {
  months = CONSTANT.MONTHS;
  workInfoThemes = ['green', 'purple', 'red'];

  constructor() { }

  get workInfoTheme() {
    return this.workInfoThemes[
      Math.floor(Math.random() * this.workInfoThemes.length)
    ];
  }

  calendarLabel(
    currentWeekDateRange: DateRange,
    displayIsMobile = true
  ): string {
    const startYear = currentWeekDateRange.start.getFullYear();
    const endYear = currentWeekDateRange.end.getFullYear();
    const startMonth = currentWeekDateRange.start.getMonth();
    const endMonth = currentWeekDateRange.end.getMonth();
    if (startYear === endYear && startMonth === endMonth) {
      return displayIsMobile
        ? `${this.months[endMonth].name}`
        : `${this.months[endMonth].name} ${endYear}`;
    } else if (startYear === endYear && startMonth !== endMonth) {
      return displayIsMobile
        ? `${this.months[startMonth].abbr} - ${this.months[endMonth].abbr}`
        : `${this.months[startMonth].abbr} - ${this.months[endMonth].abbr}, ${endYear}`;
    } else {
      return displayIsMobile
        ? `${this.months[startMonth].abbr} - ${this.months[endMonth].abbr}`
        : `${this.months[startMonth].abbr}, ${startYear} - ${this.months[endMonth].abbr}, ${endYear}`;
    }
  }

  isToday(date: Date): boolean {
    const d = new Date().setHours(0, 0, 0, 0);
    return isEqual(date.setHours(0, 0, 0, 0), d);
  }

  getDaysOfTheWeek(currentWeekDateRange: DateRange): Date[] {
    if(!currentWeekDateRange.start || !currentWeekDateRange.end){
      const d = new Date();
      const today = d.getDay();
      currentWeekDateRange.start = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() - today
      );
      currentWeekDateRange.end = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() + (6 - today)
      );
    }
    
    const currentWeekDates = eachDayOfInterval({
      start: currentWeekDateRange.start,
      end: currentWeekDateRange.end,
    });
    return currentWeekDates;
  }

  goToNextDay(currentDate: Date): Date {
    return add(currentDate, { days: 1 });
  }
  goToPreviousDay(currentDate: Date): Date {
    return sub(currentDate, { days: 1 });
  }
  goToNextMonth(currentWeekDateRange: DateRange): Date[] {
    const d = currentWeekDateRange.end;
    const nextMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);
    currentWeekDateRange.start = sub(nextMonth, { days: nextMonth.getDay() });
    currentWeekDateRange.end = add(currentWeekDateRange.start, { days: 6 });
    const currentWeekDates = eachDayOfInterval({
      start: currentWeekDateRange.start,
      end: currentWeekDateRange.end,
    });
    return currentWeekDates;
  }
  goToPreviousMonth(currentWeekDateRange: DateRange): Date[] {
    const d = currentWeekDateRange.end;
    const lastMonth = new Date(d.getFullYear(), d.getMonth() - 1, 1);
    currentWeekDateRange.start = sub(lastMonth, { days: lastMonth.getDay() });
    currentWeekDateRange.end = add(currentWeekDateRange.start, { days: 6 });
    const currentWeekDates = eachDayOfInterval({
      start: currentWeekDateRange.start,
      end: currentWeekDateRange.end,
    });
    return currentWeekDates;
  }
  goToPreviousWeek(currentWeekDateRange: DateRange): Date[] {
    currentWeekDateRange.end = sub(currentWeekDateRange.start, { days: 1 });
    currentWeekDateRange.start = sub(currentWeekDateRange.start, { days: 7 });
    const currentWeekDates = eachDayOfInterval({
      start: currentWeekDateRange.start,
      end: currentWeekDateRange.end,
    });
    return currentWeekDates;
  }
  goToNextWeek(currentWeekDateRange: DateRange): Date[] {
    currentWeekDateRange.start = add(currentWeekDateRange.end, { days: 1 });
    currentWeekDateRange.end = add(currentWeekDateRange.end, { days: 7 });
    const currentWeekDates = eachDayOfInterval({
      start: currentWeekDateRange.start,
      end: currentWeekDateRange.end,
    });
    return currentWeekDates;
  }

  plotJobOnCalendar(
    date: Date,
    project: Calendar,
    type: 'day' | 'week',
    currentViewDateRange: DateRange
  ): JobCard | null {
    let jobCard: JobCard;
    const jobStartDate =
      type === 'day'
        ? new Date(project.startedOn)
        : startOfDay(project.startedOn);
    const jobEndDate =
      type === 'day'
        ? new Date(project.proposedEndDate || endOfDay(project.startedOn))
        : new Date(project.proposedEndDate || project.startedOn);

    const jobStartsOnDayORHour =
      type === 'day'
        ? isSameHour(date, jobStartDate)
        : isEqual(date, jobStartDate);
    const dateFallsbetweenJobDateRange = isWithinInterval(date, {
      start: jobStartDate,
      end: jobEndDate,
    });
    const dateOrTimeIsFirstOnTheHeader = isEqual(
      date,
      currentViewDateRange!.start
    );

    //sort job activities (if any) so that they are arranged by their start dates (from oldest to newest)
    project.activities?.sort(
      (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );

    if (
      jobStartsOnDayORHour ||
      (dateFallsbetweenJobDateRange && dateOrTimeIsFirstOnTheHeader)
    ) {
      jobCard = {
        theme: this.workInfoTheme,
        startDate: `Start - ${format(project.startedOn, 'MM/dd/yyyy')} (${format(
          project.startedOn,
          'p'
        )})`,
        endDate: `End - ${format(project.proposedEndDate || project.startedOn, 'MM/dd/yyyy')} (${project.proposedEndDate ? format(
          project.proposedEndDate, 'p') : '11:59 PM'})`,
        jobCardActivities: [],
        webWidth: '',
        offset: '0',
        noOfDaysOrHours: 1,
        mobileOffset: 0,
      };
      if (type === 'day') {
        const newJobStartDate = isBefore(
          jobStartDate,
          currentViewDateRange!.start
        )
          ? currentViewDateRange!.start
          : jobStartDate;
        const newJobEndDate = isAfter(jobEndDate, currentViewDateRange!.end)
          ? currentViewDateRange!.end
          : jobEndDate;
        jobCard.webWidth = this.getWidthForDay(
          new Date(newJobStartDate),
          new Date(newJobEndDate)
        ).toString();
        jobCard.noOfDaysOrHours += differenceInHours(
          newJobEndDate,
          newJobStartDate
        );
        //if job starts on this day and is some minutes from the start of the hour
        if (jobStartsOnDayORHour) {
          jobCard.offset = (
            new Date(jobStartDate).getMinutes() * 1.5
          ).toString();
          jobCard.mobileOffset = new Date(newJobStartDate).getMinutes();
        }

        if (project.activities && project.activities.length > 0) {
          let i = 0;
          while (i < project.activities.length) {
            const jobCardActivity: JobCardActivity = {} as JobCardActivity;
            const activity = project.activities[i];
            const offset = this.getWidthForDay(
              new Date(newJobStartDate),
              activity.startDate
            );
            const width = this.getWidthForDay(
              activity.startDate,
              activity.endDate
            );
            jobCardActivity.activity = activity;
            jobCardActivity.width = width.toString();
            jobCardActivity.offset = offset.toString();
            jobCardActivity.mobileWidth = (width / 1.5).toString();
            jobCardActivity.mobileOffset = (offset / 1.5).toString();
            jobCard.jobCardActivities?.push(jobCardActivity);
            i++;
          }
        }
      } else {
        //if data spans more than one day
        if (isAfter(jobEndDate, date)) {
          //if its the same month or in 2 different months
          jobCard.noOfDaysOrHours += differenceInCalendarDays(jobEndDate, date);
          jobCard.webWidth = this.getWidthForWeek(jobCard.noOfDaysOrHours);

          if (project.activities && project.activities.length > 0) {
            const newJobStartDate = isBefore(
              jobStartDate,
              currentViewDateRange!.start
            )
              ? currentViewDateRange!.start
              : jobStartDate;
            let i = 0;
            while (i < project.activities.length) {
              const jobCardActivity: JobCardActivity = {} as JobCardActivity;
              const activity = project.activities[i];

              jobCardActivity.activity = activity;
              jobCardActivity.offset = this.getActivityWidthForWeek(
                new Date(newJobStartDate),
                activity.startDate,
                jobCard.noOfDaysOrHours
              );
              jobCardActivity.width = this.getActivityWidthForWeek(
                activity.startDate,
                activity.endDate,
                jobCard.noOfDaysOrHours
              );
              jobCard.jobCardActivities?.push(jobCardActivity);
              i++;
            }
          }
        }
        //otherwise
        else {
          jobCard.webWidth = '100';
        }
      }
    } else {
      return null;
    }

    return jobCard;
  }

  private getWidthForDay(startDate: Date, endDate: Date): number {
    const diffInMinutes = differenceInMinutes(endDate, startDate);
    //1 minute = 1.5 pixels
    return diffInMinutes * 1.5;
  }
  private getMonthLastDate(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }
  private getWidthForWeek(noOfDays: number): string {
    //1 day = 100 percent
    return (noOfDays * 100).toString();
  }
  private getActivityWidthForWeek(startDate: Date, endDate: Date, noOfDays: number): string {
    const diffInHours = differenceInHours(endDate, startDate);
    //1 hour = 100%/24 * 1/noOfDays;
    return Math.floor((diffInHours * (100 / (24 * noOfDays)))).toString();
  }
}
