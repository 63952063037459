import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeService as HomeServiceClient } from '../../../../generated';
import { ProfessionalSummary, Requisition } from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private homeServiceClient: HomeServiceClient) {}

  /**
   * Retrieves professional recommendations based on specified parameters.
   * @param {string | undefined} query - The query string to filter recommendations.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @returns {Observable<ProfessionalSummary[]>} An observable that resolves to the list of professional recommendations.
   */
  professionalRecommendations(query?: string, page?: number, size?: number) {
    return this.homeServiceClient.homeProfessionalRecommendations(
      query,
      page,
      size
    );
  }

  /**
   * Retrieves requisition recommendations based on specified parameters.
   * @param {string | undefined} query - The query string to filter recommendations.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @returns {Observable<Requisition[]>} An observable that resolves to the list of requisition recommendations.
   */
  requisitionRecommendations(query?: string, page?: number, size?: number) {
    return this.homeServiceClient.homeRequisitionRecommendations(
      query,
      page,
      size
    );
  }

  /**
   * Retrieves professional summary details based on the specified ID.
   * @param {string} id - The ID of the professional summary to retrieve.
   * @returns {Observable<Requisition>} An observable that resolves to the professional summary details.
   */
  getProfessionalSummaryDetails(id: string): Observable<ProfessionalSummary> {
    return this.homeServiceClient.homeGetProfessionalSummaryDetails(id);
  }

  /**
   * Retrieves requisition details based on the specified requisition ID.
   * @param {string} requisitionId - The ID of the requisition to retrieve details for.
   * @returns {Observable<Requisition>} An observable that resolves to the requisition details.
   */
  getRequisitionDetails(requisitionId: string): Observable<Requisition> {
    return this.homeServiceClient.homeGetRequisitionDetails(requisitionId);
  }
}
