/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BidRequest { 
    requisitionId: string;
    amount: number;
    rateTypeId: string;
    currency?: string;
    professionalId?: string;
    startDate: Date;
    endDate?: Date;
    expiresOn?: Date;
    message?: string;
}

