import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CONSTANT,
  RequisitionResponse,
  RequisitionService,
} from '@profindar/shared-ng';
import { ModalService } from '@seech/ux-ng';
import { Subscription } from 'rxjs';
import { AddEditRequisitionComponent } from '../../../../../components/add-edit-requisition/add-edit-requisition.component';

@Component({
  selector: 'app-requisition-modal',
  templateUrl: './requisition-modal.component.html',
  styleUrls: ['./requisition-modal.component.scss'],
})
export class RequisitionModalComponent implements OnInit, OnDestroy {
  requisitions: RequisitionResponse[] = [];

  noOfLoadingItems = Array(5);
  isFetchingRequisitions!: boolean;
  isPaginating = false;
  pageNumber = 0;
  paginationSize = CONSTANT.DEFAULT_PAGINATION_SIZE;
  searchedValue!: string;

  private subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private requisitionService: RequisitionService
  ) {}

  ngOnInit(): void {
    this.getUserCreatedRequisitions();
  }

  getUserCreatedRequisitions() {
    this.isFetchingRequisitions = true;

    this.subscription.add(
      this.requisitionService
        .getRequisitionsCreatedByUser(
          this.searchedValue,
          this.pageNumber,
          this.paginationSize
        )
        .subscribe({
          next: (resData) => {
            this.requisitions = resData;
            this.isFetchingRequisitions = false;
            this.cdr.detectChanges();
          },
          error: () => {},
        })
    );
  }

  onScrollEnd() {
    //TODO: function not firing
    this.cdr.detectChanges();
  }

  closeModal() {
    this.modalService.close();
  }

  onclone(req: RequisitionResponse) {
    this.closeModal();

    setTimeout(() => {
      this.modalService.open(AddEditRequisitionComponent, {
        data: {
          clonableReq: req,
        },
        ignoreBackdropClick: false,
        modalClass: 'modal-dialog-centered modal-lg',
      });
    }, 250);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
