/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import {
  Statuses,
  TRANSACTION_TYPE,
  PROJECT_STATUS,
  ProjectHighlight,
  DATE_RANGE_LIST,
} from '@profindar/shared-ng';
import { CONSTANT, DashboardService, Associate } from '@profindar/shared-ng';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent implements OnInit {
  noOfLoadingItems = Array(4);
  acceptedCompletedPercentage = 100;

  dateRanges = DATE_RANGE_LIST;
  startDate!: Date;
  endDate!: Date;

  cdnBaseUrl = environment.cdnBaseUrl;
  statuses = Statuses;
  txnStatuses = TRANSACTION_TYPE;

  associates: Associate[] = [];
  ongoingProjects: ProjectHighlight[] = [];
  transactionBody: any[] = [];

  projectChartDatasets!: any[];
  projectChartLabels!: string[];
  projectChartOptions!: any;

  projectsCount: { [key: string]: number } = {};
  isFetchingProjectCount = true;

  dashboardCards = [
    {
      id: PROJECT_STATUS.TOTAL,
      label: 'Total Projects',
      icon: `${this.cdnBaseUrl}images/dashboard/copy.svg`,
    },
    {
      id: PROJECT_STATUS.ACTIVE,
      label: 'Active',
      icon: `${this.cdnBaseUrl}images/dashboard/copy.svg`,
    },
    {
      id: PROJECT_STATUS.COMPLETED,
      label: 'Completed',
      icon: `${this.cdnBaseUrl}images/dashboard/task-done.svg`,
    },
    {
      id: PROJECT_STATUS.ABANDONED,
      label: 'Abandoned',
      icon: `${this.cdnBaseUrl}images/dashboard/task-remove.svg`,
    },
  ];

  transactionHeaders: string[] = [
    'Description',
    'Date',
    'Transaction ID',
    'Amount',
    'Payment Status',
  ];

  constructor(
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef
  ) {
    this.initLineChart();
  }

  ngOnInit(): void {
    this.getAssociates();

    this.getCurrentProjects();

    this.getProjectStatusCount();
  }

  getAssociates() {
    this.dashboardService
      .getAssociates(0, CONSTANT.DEFAULT_PAGINATION_SIZE)
      .subscribe({
        next: (resData) => {
          this.associates = resData;
          this.cdr.detectChanges();
        },
        error: () => {},
      });
  }

  getCurrentProjects() {
    this.dashboardService
      .getCurrentUserProjects(0, CONSTANT.DEFAULT_PAGINATION_SIZE)
      .subscribe({
        next: (resData) => {
          this.ongoingProjects = resData;
          this.cdr.detectChanges();
        },
        error: () => {},
      });
  }

  initLineChart() {
    this.projectChartDatasets = [
      {
        label: 'Revenue',
        data: [200, 230, 210, 300, 250, 400, 350, 405, 390, 580, 120, 820],
        borderColor: ' #077D0B',
        pointBackgroundColor: ' #077D0B',
        tension: 0.45,
        pointRadius: 0,
      },
      {
        label: 'Expense',
        data: [210, 375, 210, 208, 200, 180, 425, 405, 395, 580, 200, 795],
        borderColor: ' #EF360D',
        pointBackgroundColor: ' #EF360D',
        tension: 0.45,
        pointRadius: 0,
      },
    ];

    this.projectChartLabels = CONSTANT.MONTHS.map((month) => month.abbr);

    this.projectChartOptions = {
      plugins: {
        responsive: true,
        legend: {
          display: false,
        },
      },
    };
  }

  getProjectStatusCount() {
    this.dashboardService.getDashboardProjectStatusCount().subscribe({
      next: (resData) => {
        this.projectsCount = resData;
        this.isFetchingProjectCount = false;
        this.cdr.detectChanges();
      },
      error: () => {},
    });
  }

  onDateRangeSelect(range: string): void {
    const { startDate, endDate } = this.dashboardService.getDateRange(range);
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
