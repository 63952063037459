import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MobileNavigation } from '../mobile-nav/mobile-nav.component';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['../menu/menu.component.scss', './mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent {
  @Input({ required: true }) menuItems: MobileNavigation[] = [];
  @Output() readonly menuItemClick: EventEmitter<MobileNavigation> =
    new EventEmitter<MobileNavigation>();
  @Output() readonly logOut: EventEmitter<MobileNavigation> =
    new EventEmitter<MobileNavigation>();
  @Input({ required: true }) accountWebURL = '';
}
