import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UxNgModule } from '@seech/ux-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { BadgeComponent } from './badge/badge.component';
// import { BioSummaryComponent } from './bio-summary/bio-summary.component';
import { ReviewModule } from '../review/review.module';
import { RouterModule } from '@angular/router';
import { TextFieldComponent } from './text-field/text-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectBoxComponent } from './select-box/select-box.component';
import { GeneralModalComponent } from './general-modal/general-modal.component';
import { GalleryCarouselComponent } from './gallery-carousel/gallery-carousel.component';
import { MediaNgModule } from '@seech/media-ng';
import { GeneralMessageComponent } from './general-message/general-message.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  ClickOutsideNotifierDirective,
  DirectivesModule,
} from '../../../directives';
import {
  ConcatenateUserNamesPipe,
  ConvertUserSummaryToUserPipe,
  CountUnreadMessagesPipe,
} from '@profindar/shared-ng';
import { ParticipantListComponent } from './participant-list/participant-list.component';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  ChatImageCollageDirective,
  MessageCheckStringOverflowDirective,
  UserImageCollageDirective,
} from '../../features/message/directives';
import { CreateEditEventComponent } from './general-calendar/create-edit-event/create-edit-event.component';
import { CalendarDayViewComponent } from './general-calendar/calendar-day-view/calendar-day-view.component';
import { CalendarWeekViewComponent } from './general-calendar/calendar-week-view/calendar-week-view.component';
import { ActivitySummaryComponent } from './general-calendar/activity-summary/activity-summary.component';
import { MediaUploadPreviewComponent } from './general-message/media-upload-preview/media-upload-preview.component';

const EXPORTED_COMPONENTS = [
  BadgeComponent,
  TextFieldComponent,
  // BioSummaryComponent,
  GeneralModalComponent,
  GalleryCarouselComponent,
  GeneralMessageComponent,
  ParticipantListComponent,
];
@NgModule({
  declarations: [
    ...EXPORTED_COMPONENTS,
    SelectBoxComponent,
    CreateEditEventComponent,
    CalendarDayViewComponent,
    CalendarWeekViewComponent,
    ActivitySummaryComponent,
    MediaUploadPreviewComponent,
  ],
  imports: [
    CommonModule,
    UxNgModule,
    IconsNgModule,
    ControlsNgModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ReviewModule,
    MediaNgModule,
    TextFieldModule,
    DirectivesModule,
    CountUnreadMessagesPipe,
    ConvertUserSummaryToUserPipe,
    ConcatenateUserNamesPipe,
    UserImageCollageDirective,
    ChatImageCollageDirective,
    MessageCheckStringOverflowDirective,
    ClickOutsideNotifierDirective,
    OverlayModule,
  ],
  exports: [...EXPORTED_COMPONENTS],
})
export class ComponentsModule {}
