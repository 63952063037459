/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type InputType = 'text' | 'number' | 'email' | 'date' | 'text-area' | 'time';
@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextFieldComponent)
    }
  ]
})
export class TextFieldComponent implements ControlValueAccessor {
  @Input() type: InputType = 'text';
  @Input() label!: string;
  @Input() id!: string;
  @Input() readonly = false;
  @Input() minDate?: Date;
  @Input() rows = 4;
  value!: any;
  disabled = false;
  touched = false;
  selectedTime = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (event: any) => {
    //intentionally not implemented; method added to initialize variable
  };

  onTouched = () => {
    //intentionally not implemented; method added to initialize variable
  };

  writeValue(obj:  any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(){
    this.onChange(this.value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.onValueChange();
    }
  }
}
