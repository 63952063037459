/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinate } from './coordinate';
import { Ordinates } from './ordinates';


export interface CoordinateSequence { 
    dimension: number;
    measures: number;
    spatial: number;
    ordinates: Ordinates;
    hasZ: boolean;
    hasM: boolean;
    zOrdinateIndex: number;
    mOrdinateIndex: number;
    first?: Coordinate;
    last?: Coordinate;
    count: number;
}
export namespace CoordinateSequence {
}


