<div class="calendar">
    <div class="calendar-top">
        <div class="info">
            <span class="info-month">{{ calendarLabel }}</span>
            <div class="info-nav">
                <div class="info-nav-back" (click)="goToPreviousMonth()">
                    <span [sch-icon]="'arrow-back'"></span>
                </div>
                <div class="info-nav-forward" (click)="goToNextMonth()">
                    <span [sch-icon]="'arrow-forward'"></span>
                </div>
            </div>
        </div>
        <div class="actions">
            <seech-dropdown id="dropdown" alignment="down" direction="down" label="Week" color="light" size="sm">
                <li><a sch-dropdown-item (click)="switchView.emit('week')">Week</a></li>
                <li><a sch-dropdown-item (click)="switchView.emit('day')">Day</a></li>
            </seech-dropdown>

            <div class="nav-group">
                <button (click)="goToPreviousWeek()"> <span [sch-icon]="'arrow-back'"></span></button>
                <button (click)="getDaysOfTheWeek()">Today</button>
                <button (click)="goToNextWeek()"><span [sch-icon]="'arrow-forward'"></span></button>
            </div>

            <button sch-button class="!bg-secondary !shadow-none !capitalize create-btn" 
                (click)="openCreateEventModal('create')">
                <span [sch-icon]="'plus'" class="me-1"></span>
                Create
            </button>
        </div>
    </div>

    <!-- Calendar -->
    <div class="calendar-wrapper">
        <div class="projects" [ngStyle]="{'grid-template-rows': gridTemplateRows}">
            <div class="cell">Projects</div>

            <ng-container *ngIf="calendarProjects && calendarProjects.length > 0; else emptyProjectState">
                <div class="cell" *ngFor="let project of calendarProjects">
                    <img [src]="project.imageUrl" alt="project banner">
                    <p class="job">
                        {{ project.services[0] }}
                        <span *ngIf="project.services.length > 1">{{'(+' + (project.services.length - 1) + ')'}}</span>
                    </p>
                    <p class="time">
                        {{(project.startedOn| date:'shortTime') + ' - ' + (project.proposedEndDate| date:'shortTime')}}
                    </p>
                </div>
            </ng-container>

            <ng-template #emptyProjectState>
                <div class="cell" *ngFor="let item of [0, 1, 2]"></div>
            </ng-template>
        </div>

        <div class="body" [ngStyle]="{'grid-template-rows': gridTemplateRows}">
            <div *ngFor="let date of currentWeekDates; let i = index" class="cell header"> 
                <p class="day">{{daysOfTheWeek[date.getDay()].abbr | uppercase}}</p>
                <p class="date">{{date.getDate()}}</p>
            </div>

            <ng-container *ngIf="calendarProjects && calendarProjects.length > 0; else emptyState">
                <ng-container *ngFor="let project of calendarProjects; let projectIndex = index">
                    <div *ngFor="let date of currentWeekDates; let i = index" class="cell" 
                    (click)="openCreateEventModal('create', project.projectId)"
                    [ngClass]="{'cell-first': i === 0, 'cell-last': (i + 1) === currentWeekDates.length}">
                        <ng-container *ngIf="plotJobOnCalendar(date, project) as workItem">
                            <div class="project" [ngStyle]="{'width': workItem.webWidth + '%'}">
                                <ng-container *ngIf="workItem.jobCardActivities && workItem.jobCardActivities.length > 0">
                                    <div class="project-activities">
                                        <ng-container *ngFor="let activity of workItem.jobCardActivities">
                                            <div [class]="'activity ' + workItem.theme" 
                                            (click)="$event.stopPropagation(); openCreateEventModal('view', project.projectId, activity.activity)"
                                                [ngStyle]="{'width': activity.width + '%', 'margin-left': activity.offset + '%'}">
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
    
                                <div [class]="'project-info ' + workItem.theme" (click)="$event.stopPropagation(); openTimelineModal(project.projectId)"
                                [ngStyle]="{'height': workItem.jobCardActivities && workItem.jobCardActivities.length > 0 ? '70%' : '100%'}">
                                    <div class="date-range">
                                        <p> {{ workItem.startDate }} </p>
                                        <p> {{ workItem.endDate }} </p>
                                    </div>
    
                                    <ul>
                                        <li *ngFor="let item of project.services | slice:0:2"> 
                                            <div class="round-point"></div>
                                            {{ item }} 
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </ng-container>
            </ng-container>

            <ng-template #emptyState>
                <ng-container *ngFor="let item of [0, 1, 2]">
                    <div *ngFor="let date of currentWeekDates; let i = index" class="cell"
                    [ngClass]="{'cell-first': i === 0, 'cell-last': (i + 1) === currentWeekDates.length}"></div>
                </ng-container>
            </ng-template>
        </div>
    </div>
    <!-- \Calendar -->

</div>