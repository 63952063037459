import { Component, Input } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { CONSTANT } from '@profindar/shared-ng';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss'],
})
export class ServiceDetailComponent {
  @Input({required: true}) viewer!: string;
  @Input() isModal = false;
  cdnBaseUrl = environment.cdnBaseUrl;
  pageViewer = CONSTANT.PAGE_VIEWER;
  skills = ['Installation', 'Lighting Fixtures', 'Residential Wiring', 'High Voltage', 'Installation', 'Lighting Fixtures', 'Residential Wiring'];
  
  
  constructor() {}

  get galleryCount(): number{
    return this.isModal ? 3 : 4;
  }

}
