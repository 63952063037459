/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Message } from '../model/models';
import { MessageActionProperties } from '../model/models';
import { MessageRequest } from '../model/models';
import { ThreadDetails } from '../model/models';
import { ThreadPreview } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface MessageServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param projectId 
     * @param participants 
     */
    messageCreateStandaloneThread(projectId: string, participants: Array<string>, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param userIds 
     */
    messageCreateThread(userIds: Array<string>, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param threadId 
     * @param page 
     * @param size 
     */
    messageGetMessagesByThreadId(threadId: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Message>>;

    /**
     * 
     * 
     * @param searchWord 
     * @param page 
     * @param size 
     */
    messageGetRecepientListSearch(searchWord?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ThreadPreview>>;

    /**
     * 
     * 
     * @param threadId 
     */
    messageGetThreadDetails(threadId: string, extraHttpRequestParams?: any): Observable<ThreadDetails>;

    /**
     * 
     * 
     * @param username 
     */
    messageGetUserThreadPreview(username?: string, extraHttpRequestParams?: any): Observable<ThreadPreview>;

    /**
     * 
     * 
     * @param threadId 
     */
    messageGetUserThreadPreviewByThreadId(threadId: string, extraHttpRequestParams?: any): Observable<ThreadPreview>;

    /**
     * 
     * 
     */
    messageGetUserUnReadMessages(extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param threadId 
     */
    messageLeaveGroup(threadId: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * Message actions Api
     * This API handles actions being carried out on messages, like blocking and notification actions. It takes an object that has two properties \&#39;Option\&#39; of type string and \&#39;Properties\&#39; of type dictionary.          Option represent the options available to the user, like \&#39;block\&#39; or \&#39;notification\&#39;.                  Properties is a key, value pair object that takes three keys: thread, user and action.          \&#39;block\&#39; action : \&#39;blocking\&#39; and \&#39;unblocking\&#39; -- \&#39;notification\&#39; action : \&#39;on\&#39; and \&#39;off\&#39;
     * @param actionProperties 
     */
    messageMessageActionsHandler(actionProperties: MessageActionProperties, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param message 
     */
    messageSendMessage(message: MessageRequest, extraHttpRequestParams?: any): Observable<Message>;

    /**
     * 
     * 
     * @param threadId 
     */
    messageUpdateLastSeen(threadId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param threadId 
     */
    messageUpdateMessageUnreadCount(threadId: string, extraHttpRequestParams?: any): Observable<number>;

}
