<div class="dialog" [class.p-3]="!padding" [style.padding]="padding">
  <div class="dialog-title" *ngIf="hasHeader && ($isLoading|async) === false">
    <div *ngIf="alignment === 'center' && label" class="divider d-none d-md-inline-block"></div>
    <p *ngIf="label" class="label d-none d-md-inline-block">{{ label }}</p>
    <div class="divider"></div>
    <img class="close" src="{{cdnBaseUrl}}images/cancel-circle.svg" width="28" height="auto" (click)="closeModal()">
  </div>

  <div *ngIf="($isLoading|async)" class="progress-header mb-3">
    <div class="d-flex justify-center align-items-center h-100 position-absolute left-0 right-0 z-1">
      <seech-progress-bar class="w-100" [height]="2" [isIndeterminate]="true" [color]="'secondary'"></seech-progress-bar>
    </div>
    <div class="d-flex justify-center align-items-center h-100 position-absolute left-0 right-0 z-10">
      <span *ngIf="label" class="label d-none d-md-inline-block ps-2 pe-2 bg-white">{{ label }}</span>
    </div>
  </div>

  <div class="dialog-body">
    <div class="dialog-title mobile d-md-none mt-2 mx-2" *ngIf="hasHeader && label">
      <div class="divider"></div>
      <p class="label">{{ label }}</p>
      <div class="divider"></div>
    </div>

    <ng-content></ng-content>
  </div>
</div>
