/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ControlsNgModule } from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { LayoutModule } from './layout';
import { UxNgModule } from '@seech/ux-ng';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { ProtectedRoutingModule } from './protected/protected-routing.module';
import { ProtectedModule } from './protected/protected.module';
import { TrafficInterceptor } from './interceptors/traffic.interceptor';
import { SharedNgModule } from '@profindar/shared-ng';
import { GlobalProgressBarDirective } from './directives';
import { CoreNgModule } from '@seech/core-ng';
import { environment } from '../environments/environment';
import { HttpProgressInterceptorService } from './interceptors/http-progress-interceptor.service';
import { OnboardingModule } from './modules/features/onboarding/onboarding.module';

export interface SessionTimeoutConfig {
  interval: number;
  countdown: number;
}

const sessionTimeoutConfig: SessionTimeoutConfig = {
  interval: 300000, // example value in milliseconds (5 minutes)
  countdown: 60000, // example value in milliseconds (1 minute)
};

const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TrafficInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, // local interceptor
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedNgModule,
    ControlsNgModule,
    LayoutModule,
    IconsNgModule,
    BrowserAnimationsModule,
    UxNgModule,
    DragDropModule,
    GlobalProgressBarDirective,
    AuthRoutingModule,
    AuthModule,
    ProtectedRoutingModule,
    ProtectedModule,
    OnboardingModule,
    AppRoutingModule,
    CoreNgModule.forRoot({
      environment: environment,
      sessionTimeout: sessionTimeoutConfig,
      realTimeHubUrl: 'centralsocket'
    }),
    ControlsNgModule.forRoot({
      googleAPIKey: environment.GOOGLE_API_KEY,
    }),
  ],
  providers: [...HttpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
