<app-general-modal label="Select Project Type">
  <div class="my-4">
    <div class="cards flex justify-center gap-3 items-center">
      <div (click)="hireProfessional()" class="create-new cursor-pointer">
        <div
          class="filled-star flex items-center justify-center bg-secondary w-[164px] h-[154px]"
        >
          <svg
            width="60"
            height="72"
            viewBox="0 0 60 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.2294 -0.00439453L0.582031 13.0724V32.6876C0.582031 50.8317 13.2316 67.7988 30.2294 71.918C47.2273 67.7988 59.8768 50.8317 59.8768 32.6876V13.0724L30.2294 -0.00439453ZM40.3754 49.0336L30.2294 42.9856L20.1164 49.0336L22.7846 37.5914L13.8575 29.9415L25.6506 28.928L30.2294 18.1397L34.8083 28.8953L46.6014 29.9088L37.6742 37.5914L40.3754 49.0336Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="title text-sm mt-3 text-center font-medium">
          <p>New</p>
        </div>
      </div>

      <div (click)="callMyRequisitionsModal()" class="exisiting cursor-pointer">
        <div
          class="outline-star flex items-center justify-center border rounded-[2.99px] border-secondary w-[164px] h-[154px]"
        >
          <svg
            width="61"
            height="72"
            viewBox="0 0 61 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.5888 -0.00439453L0.941406 13.0724V32.6876C0.941406 50.8317 13.591 67.7988 30.5888 71.918C47.5867 67.7988 60.2362 50.8317 60.2362 32.6876V13.0724L30.5888 -0.00439453ZM40.7348 49.0336L30.5888 42.9856L20.4757 49.0336L23.144 37.5914L14.2169 29.9415L26.0099 28.928L30.5888 18.1397L35.1677 28.8953L46.9608 29.9088L38.0336 37.5914L40.7348 49.0336Z"
              fill="#077D0B"
            />
          </svg>
        </div>

        <div class="title text-sm mt-3 text-center font-medium">
          <p>Clone</p>
        </div>
      </div>
    </div>
  </div>
</app-general-modal>
