/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { ProjectStatus } from './projectStatus';
import { User } from './user';
import { ProjectPaymentSchedule } from './projectPaymentSchedule';
import { ProjectStatusHistory } from './projectStatusHistory';
import { PayRateType } from './payRateType';
import { Activity } from './activity';
import { UserSummary } from './userSummary';
import { ActiveProjectData } from './activeProjectData';
import { RequestedService2 } from './requestedService2';
import { Review } from './review';
import { RequestedTask2 } from './requestedTask2';


export interface ProjectOfActiveProjectData { 
    progressInPercentage?: number;
    threadId?: string;
    clientUserSummary?: UserSummary;
    professionalUserSummary?: UserSummary;
    projectStatus: ProjectStatus;
    payRateType: PayRateType;
    professional: User;
    client: User;
    id: string;
    referenceID: string;
    description?: string;
    statusId: string;
    statusInitiatedOn: Date;
    startedOn: Date;
    proposedEndDate?: Date;
    completedOn?: Date;
    progress: number;
    requisitionId: string;
    professionalId: string;
    isRemote?: boolean;
    isOnSite?: boolean;
    isMobile?: boolean;
    currency: string;
    budget: number;
    rateTypeId: string;
    createdOn: Date;
    createdBy: string;
    geolocation?: Geometry;
    location?: string;
    isPaymentVerified?: boolean;
    activities: Array<Activity>;
    projectPaymentSchedule?: ProjectPaymentSchedule;
    projectStatusHistories: Array<ProjectStatusHistory>;
    requestedServices: Array<RequestedService2>;
    requestedTasks: Array<RequestedTask2>;
    reviews: Array<Review>;
    statusData?: ActiveProjectData;
}

