import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true
})
export class InitialsPipe implements PipeTransform {

  transform(fullName: string): string {
    if (!fullName) {
      return '';
    }
    // Split the name into parts and return the initials
    return fullName
      .split(' ')
      .map(part => part[0] ? part[0].toUpperCase() : '') // Take first letter of each part and capitalize it
      .join('');
  }

}
