
export const CALENDAR = {
    
  DAYS_OF_THE_WEEK: [
    { name: 'Sunday', abbr: 'SUN' },
    { name: 'Monday', abbr: 'Mon' },
    { name: 'Tuesday', abbr: 'Tue' },
    { name: 'Wednessday', abbr: 'Wed' },
    { name: 'Thursday', abbr: 'Thu' },
    { name: 'Friday', abbr: 'Fri' },
    { name: 'Saturday', abbr: 'Sat' },
  ],

  MONTHS: [
    { name: 'January', abbr: 'Jan' },
    { name: 'February', abbr: 'Feb' },
    { name: 'March', abbr: 'Mar' },
    { name: 'April', abbr: 'Apr' },
    { name: 'May', abbr: 'May' },
    { name: 'June', abbr: 'Jun' },
    { name: 'July', abbr: 'Jul' },
    { name: 'August', abbr: 'Aug' },
    { name: 'September', abbr: 'Sep' },
    { name: 'October', abbr: 'Oct' },
    { name: 'November', abbr: 'Nov' },
    { name: 'December', abbr: 'Dec' },
  ],

  HOURS_IN_A_DAY: Array.from({ length: 24 }, (_, i) => {
    const period = i < 12 ? 'AM' : 'PM';
    const hour = i % 12 === 0 ? 12 : i % 12;
    return `${hour} ${period}`;
  }),

  CALENDAR_VIEW: {
    WEEK: 'Week',
    DAY: 'Day',
  },
}