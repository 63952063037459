import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import { Subscription } from 'rxjs';
import { ServiceDetailComponent } from '../../../../general/detail/components/service-detail/service-detail.component';

@Component({
  selector: 'app-dashboard-services',
  templateUrl: './dashboard-services.component.html',
  styleUrls: ['./dashboard-services.component.scss'],
})
export class DashboardServicesComponent implements OnInit, OnDestroy {
  @Input({ required: true }) viewer!: string;
  servicesData: any[] = [];
  activeItemIndex = 0;
  displayIsMobile!: boolean;
  sub: Subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.sub.add(
      this.breakpointObserver
        .observe([Breakpoints.XSmall])
        .subscribe((state) => {
          this.displayIsMobile = state.matches;
        })
    );
  }

  selectService(index: number) {
    this.activeItemIndex = index;
    if (this.displayIsMobile) this.openDetailsModal();
  }

  openDetailsModal() {
    const data = this.servicesData[this.activeItemIndex];
    const modalRef = this.modalService.open(ServiceDetailComponent, {
      data,
      modalClass: 'modal-dialog-centered',
    });

    modalRef.component.viewer = this.viewer;
    modalRef.component.isModal = true;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
