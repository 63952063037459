import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StandardLayoutComponent } from '../layout/standard-layout/standard-layout.component';
import { protectedRoutes } from '../app.routes';
import { CanActivateGuard } from '../auth/guards/can-activate.guard';

export const routes: Routes = [
  {
    path: '',
    children: [...protectedRoutes],
    component: StandardLayoutComponent,
    canActivate: [CanActivateGuard.guard],
    canActivateChild: [CanActivateGuard.guard],
    // canLoad: [CanLoadGuard],
    data: { title: "Protected Views" },
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProtectedRoutingModule { }
