<app-general-modal [label]="eventView === 'create' ? 'Create Event' : 'Event Details'" #modalContainer>

    <form [formGroup]="activityForm">
        <div class="row event-form">
            <div class="col-12">
                <seech-autocomplete
                    [results]="projects"
                    nameKey="description"
                    valueKey="id"
                    (selected)="onSelectProject($event)"
                    filterKey="description"
                    (searchTextChanged)="getProjects($event)"
                    formControlName="projectId"
                    id="projects">
                </seech-autocomplete>
                <seech-validation
                    *ngIf="formControlValidityCheck('projectId')"
                    type="error"
                    [message]="'Please select a project'"
                ></seech-validation>
            </div>
            <div class="col-12">
                <app-text-field label="Client Name" formControlName="clientName" [readonly]="true"></app-text-field>
            </div>
            <div class="col-12">
                <app-text-field label="Project Title" formControlName="projectTitle" [readonly]="true"></app-text-field>
            </div>
            <div class="col-12">
                <app-text-field label="Address" formControlName="location" [readonly]="true"></app-text-field>
            </div>

            <div class="col-6">
                <app-text-field label="Start" formControlName="startDate" type="date" [readonly]="eventView === 'view'">
                    <seech-validation
                        *ngIf="formControlValidityCheck('startDate')"
                        type="error"
                        [message]="startDateControlErrorMsg"
                    ></seech-validation>
                </app-text-field>
            </div>
            <div class="col-6">
                <app-text-field label="End" formControlName="endDate" type="date" [readonly]="eventView === 'view'">
                    <seech-validation
                        *ngIf="formControlValidityCheck('endDate')"
                        type="error"
                        [message]="activityForm.get('endDate')?.hasError('outOfProjectRange') ? 
                        'Date must be between project start and end date' :'Please enter an end date'"
                ></seech-validation>
                </app-text-field>
            </div>
            <div class="col-12">
                <app-text-field label="Description (Optional)" type="text-area"
                formControlName="description" [readonly]="eventView === 'view'"></app-text-field>
            </div>
    
            <ng-container *ngIf="eventView === 'create'; else viewModal">
                <div class="col-12 mt-2">
                    <button sch-button class="!bg-secondary !shadow-none !capitalize w-100" (click)="addUpdateProjectActivity()">
                        Create Event
                    </button>
                </div>
            </ng-container>
    
            <ng-template #viewModal>
                <ng-container *ngIf="eventView === 'view'; else editModal">
                    <div class="col-6 mt-2">
                        <button sch-button class="edit-btn !shadow-none !capitalize w-100"
                        (click)="eventView = 'edit'">Edit Event</button>
                        <!-- <seech-combo-button (click)="onDelete()" [id]="'someId'" [label]="'Edit Event'"
                            [color]="'primary'" [disabled]="false" [size]="'md'" [fill]="'solid'" 
                            [dropdownItems]="[{ label: 'Delete', value: 'delete' }]">
                        </seech-combo-button> -->
                    </div>
                    <div class="col-6 mt-2">
                        <button sch-button class="!bg-secondary !shadow-none !capitalize w-100">View Project</button>
                    </div>
                </ng-container>
    
                <ng-template #editModal>
                    <div class="col-6 mt-2">
                        <button sch-button class="cancel-btn !shadow-none !capitalize w-100"
                            (click)="eventView = 'view'">Cancel</button>
                    </div>
                    <div class="col-6 mt-2">
                        <button sch-button class="!bg-secondary !shadow-none !capitalize w-100" (click)="addUpdateProjectActivity()">
                            Save
                        </button>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </form>

</app-general-modal>