import { Injectable } from '@angular/core';
import { ProfessionalServiceService as ProfessionalServiceClient } from '../../../../generated';
import {
  ProfessionalService,
  ProfessionalServiceRequest,
} from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalServiceService {
  constructor(private professionalServiceClient: ProfessionalServiceClient) {}

  /**
   * Adds a professional service.
   * @param professionService The professional service to be added.
   * @returns An Observable emitting a response indicating the success of the operation.
   */
  addProfessionalService(professionService: ProfessionalServiceRequest) {
    return this.professionalServiceClient.professionalServiceAddProfessionalService(
      professionService
    );
  }

  /**
   * Patches a professional service.
   * @param professionService The professional service to be patched.
   * @returns An Observable emitting a response indicating the success of the operation.
   */
  patchProfessionalService(professionService: ProfessionalServiceRequest) {
    return this.professionalServiceClient.professionalServiceUpdateProfessionalService(
      professionService
    );
  }

  /**
   * Deactivates a professional service by ID.
   * @param id The ID of the professional service to be deactivated.
   * @param isDeactivated A boolean indicating whether the service should be deactivated.
   * @returns An Observable emitting a response indicating the success of the operation.
   */
  deactivateProfessionalServiceById(id: string, isDeactivated: boolean) {
    return this.professionalServiceClient.professionalServiceDeactivateProfessionalServiceById(
      id,
      isDeactivated
    );
  }

  /**
   * Retrieves professional services.
   * @param page The page number of the professional services to retrieve.
   * @param size The size of the page.
   * @returns An Observable emitting a response indicating the success of the operation.
   */
  getProfessionalService(page?: number, size?: number) {
    return this.professionalServiceClient.professionalServiceGetProfessionalService(
      page,
      size
    );
  }

  /**
   * Retrieves a professional service by ID.
   * @param id The ID of the professional service to retrieve.
   * @returns An Observable emitting a response indicating the success of the operation.
   */
  professionalServiceGetProfessionalServiceById(id: string) {
    return this.professionalServiceClient.professionalServiceGetProfessionalServiceById(
      id
    );
  }
}
