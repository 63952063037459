<section id="empty-state" class="w-100">
  <div>
    <i *ngIf="topIcon" [sch-icon]="topIcon" class="top-icon"></i>

    <div class="header">
      <p class="header-title">
        {{ title }}
      </p>

      <i *ngIf="showTextIcon" [sch-icon]="'priority'" class="header-icon"></i>
    </div>

    <p class="description">{{ description }}</p>
  </div>

  <button
    *ngIf="actionText"
    sch-button
    color="secondary"
    class="action"
    (click)="actionClick.emit()"
  >
    {{ actionText }}
  </button>
</section>
