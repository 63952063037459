import { Injectable } from '@angular/core';
import { NotificationService as NotificationClient } from '../../../../generated';
import { NotificationFilter } from '../../../../constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private notificationClient: NotificationClient) {}

  /**
   * Retrieves notifications based on provided parameters.
   * @param {number | undefined} page - The page number of notifications to retrieve.
   * @param {number | undefined} size - The size of the page.
   * @param {string | undefined} filterBy - The filter criteria for notifications.
   * @param {string | undefined} searchQuery - The search query to filter notifications.
   * @returns {Observable<LocalNotificationMessage[]>} An Observable that emits a response indicating the success of the operation.
   */
  getNotification(
    page?: number,
    size?: number,
    filterBy?: string,
    searchQuery?: string
  ) {
    return this.notificationClient.notificationGetNotification(
      page,
      size,
      filterBy,
      searchQuery
    );
  }

  /**
   * Retrieves the count of notifications.
   * @returns {Observable<number>} An Observable that emits a response indicating the success of the operation.
   */
  getNotificationCount(filterBy: NotificationFilter) {
    return this.notificationClient.notificationGetNotificationCount(filterBy);
  }

  /**
   * Updates the last viewed notification.
   * @returns {Observable<boolean>} An Observable that emits a response indicating the success of the operation.
   */
  updateLastViewedNotification() {
    return this.notificationClient.notificationUpdateLastViewedNotification();
  }

  /**
   * Views a specific notification.
   * @param {string} notificationId - The ID of the notification to view.
   * @returns {Observable<Notification>} An Observable that emits a response indicating the success of the operation.
   */
  viewNotification(notificationId: string) {
    return this.notificationClient.notificationViewNotification(notificationId);
  }
}
