import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlsNgModule } from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { Subject, Subscription } from 'rxjs';
import { ItemsModule } from '../../../../general/items/items.module';
import { CommonModule } from '@angular/common';
import { InviteProfessionalsRequest, ProfessionalSummary, Requisition, RequisitionService } from '@profindar/shared-ng';
import { EmptyStateComponent } from '../../../../../components/empty-state/empty-state.component';
import { ModalService, ScrollEndNotifierDirective, ToastService, UxNgModule } from '@seech/ux-ng';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../../../general/components/components.module';

@Component({
  standalone: true,
  selector: 'app-requisition-invitation',
  templateUrl: './requisition-invitation.component.html',
  styleUrls: ['./requisition-invitation.component.scss'],
  imports: [

    FormsModule,
    IconsNgModule,
    ControlsNgModule,
    ItemsModule,
    CommonModule,
    EmptyStateComponent,
    ScrollEndNotifierDirective,
    UxNgModule,
    ComponentsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequisitionInvitationComponent implements OnInit, OnDestroy {
  isFetchingProfessionals = true;
  isPaginating = false;
  isSearching = false;
  pageNumber = 0;
  paginationSize = 10;
  professionals: ProfessionalSummary[] = [];
  selectedProfessionals: ProfessionalSummary[] = [];
  isSubmitted =  false;
  btnText ='Send Invitations'

  searchTerm = '';
  searchedValue!: string;
  searchTextSubject$ = new Subject<string>();
  private subscription = new Subscription();

  @Input() independent =false;
  @Input() reqId = '';

  @Output() selectedProfessionalsChange = new EventEmitter<
    ProfessionalSummary[]
  >();

  constructor(
    private requisitionService: RequisitionService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getProfessionals();
  }

  getProfessionals() {
    this.subscription.add(
      this.requisitionService
        .getRequisitionProfessionalRecommendations(
          this.searchedValue,
          this.pageNumber,
          this.paginationSize
        )
        .subscribe({
          next: (resData) => {
            const filteredProfessionals = resData.filter(
              (pro) =>
                !this.selectedProfessionals.some(
                  (invPro) => invPro.id === pro.id
                )
            );

            this.professionals =
              this.pageNumber === 0
                ? filteredProfessionals
                : [...this.professionals, ...filteredProfessionals];
          },
          error: () => { },
          complete: () => {
            this.isFetchingProfessionals = false;
            this.isPaginating = false;
            this.isSearching = false;
            this.cdr.detectChanges();
          },
        })
    );
  }

  onProfessionalCheckClicked(checked: boolean, item: ProfessionalSummary) {
    if (checked) {
      this.moveToSelectedProfessionals(item);
    } else {
      this.moveToProfessionals(item);
    }

    this.cdr.detectChanges();
  }

  moveToSelectedProfessionals(item: ProfessionalSummary) {
    const index = this.professionals.findIndex((pro) => pro.id === item.id);

    if (index > -1) {
      const removedItem = this.professionals.splice(index, 1)[0];
      this.selectedProfessionals = [removedItem, ...this.selectedProfessionals];
      this.selectedProfessionalsChange.emit(this.selectedProfessionals);
      this.cdr.detectChanges();
    }
  }

  moveToProfessionals(item: ProfessionalSummary) {
    const index = this.selectedProfessionals.findIndex(
      (invPro) => invPro.id === item.id
    );

    if (index > -1) {
      const removedItem = this.selectedProfessionals.splice(index, 1)[0];
      this.professionals = [removedItem, ...this.professionals];
      this.selectedProfessionalsChange.emit(this.selectedProfessionals);
      this.cdr.detectChanges();
    }
  }

  isProfessionalChecked(item: ProfessionalSummary): boolean {
    return this.selectedProfessionals.some((pro) => pro.id === item.id);
  }

  onScrollEnd() {
    if (this.isPaginating || this.isFetchingProfessionals) return;
    this.isPaginating = true;
    this.pageNumber += 1;
    this.getProfessionals();
  }

  searchProfessionals() {
    if (this.isSearching) return;
    this.isSearching = true;

    this.pageNumber = 0; // Reset page number when searching
    this.searchedValue = this.searchTerm || '';
    this.getProfessionals();
  }

  submit(){
    if(this.selectedProfessionals.length > 0){
      this.btnText = "Sending Invitations...";
      this.isSubmitted = true;
      const payload = {
        requisitionId: this.reqId,
        professionalIds: this.selectedProfessionals.map((val:any)=> {
          return {professionalId:val.userId}
        })
      }
      // console.log(payload)

      this.requisitionService.inviteProfessionals(payload.requisitionId, payload.professionalIds).subscribe({
        next: (res) => {
          this.toastService.success(`Invitation${payload.professionalIds.length > 1 ? 's' : ''} Sent Successfully`)
          this.modalService.close()
        }
      })
    }else{
      this.toastService.error("Please select  professional(s) to invite")
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
