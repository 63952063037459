import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { SideNavigation } from '../navigation';

@Component({
  selector: 'app-side-nav-items',
  templateUrl: './side-nav-items.component.html',
  styleUrls: ['./side-nav-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavItemsComponent {
  @HostBinding('style.width')
  height = '100%';
  @Input({ required: true }) navigationItems: SideNavigation[] = [];
  @Output() selectedChanged = new EventEmitter<SideNavigation>();
}
