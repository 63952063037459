import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { CheckStringOverflowDirective } from '../../../../directives';
import { ThreadPreview } from '@profindar/shared-ng';


@Directive({
  selector: '[appMessageCheckStringOverflow]',
  standalone: true
})
export class MessageCheckStringOverflowDirective extends CheckStringOverflowDirective implements AfterViewInit{
  moreButton!: HTMLElement;
  threadPreview!: ThreadPreview | null;

  @Input({required: true}) set conversation(conversation: ThreadPreview | null) {
    if(conversation) {
      this.cdr.detectChanges();
      this.threadPreview = conversation;
      this.initAction();
    }
  }

  @HostListener('resized')
  onResize() {
    this.initAction();
  }

  ngAfterViewInit(): void {
    this.moreButton = this.element.querySelector('.more') as HTMLElement;
    this.onResize();
  }

  initAction(){
    if(this.moreButton){
      this.moreButton.style.display = 'none';
      this.textOverflow ? this.moreButton.style.display = 'flex' : null;
    }
  }
}
