<div class="textbox">
    <label [for]="id"> {{ label }} </label>

    <ng-container *ngIf="type === 'date'">
         <seech-date
            autocomplete="off"
            [id]="id" (blur)="markAsTouched()" 
            [(ngModel)]="value" 
            [disabled]="readonly" 
            (ngModelChange)="onValueChange()"
            [minDate]="minDate">
        </seech-date>
    </ng-container>

    <ng-container *ngIf="type === 'time'">
        <seech-time
            autocomplete="off"
            [(ngModel)]="value" 
            [(value)]="selectedTime"
            (blur)="markAsTouched()" 
            [id]="id"
            [useInline]="false"
            [disabled]="readonly" 
            (ngModelChange)="onValueChange()"
            placeholder="Pick a time"
        ></seech-time>
    </ng-container>

    <ng-container *ngIf="type === 'text-area'">
        <textarea [id]="id" 
            [readonly]="readonly" 
            (blur)="markAsTouched()" 
            [(ngModel)]="value" 
            (ngModelChange)="onValueChange()" 
            [rows]="rows">
        </textarea>
    </ng-container>

    <ng-container *ngIf="type !== 'text-area' && type !== 'date' && type !== 'time'">
        <input [type]="type" 
        [id]="id" (blur)="markAsTouched()" 
        [(ngModel)]="value" 
        [readonly]="readonly" 
        (ngModelChange)="onValueChange()">
    </ng-container>

    <ng-content></ng-content>
</div>
