<form class="d-flex flex-column gap-3" [formGroup]="form">
  <seech-money [label]="payRateType?'Budget (Per '+payRateType+')':'Budget'" [readonly]="readonly"
    formControlName="amount" [required]="true" [validateControl]="validateFormControls" [currency]="currency"></seech-money>

  <div class="w-100 date-container">
    <label for="start-date" class="form-label">Start Date<span
        *ngIf="type === 'professional' && !readonly">*</span></label>
    <seech-date [disabled]="readonly" [id]="'start-date'" [placeholder]="'Select a Date'" formControlName="startDate">
    </seech-date>
    <ng-container *ngIf="startDate.errors && validateFormControls">
      <p *ngIf="startDate.errors['required']" class="error-feedback">
        Proposed start date is required
      </p>
      <p *ngIf="startDate.errors['pastDate']" class="error-feedback">
        Start date can not be in the past
      </p>
    </ng-container>
  </div>

  <ng-container *ngIf="showMore">
    <div class="w-100 date-container">
      <label for="end-date" class="form-label">End Date</label>
      <seech-date [disabled]="readonly" [id]="'end-date'" [placeholder]="'Select a Date'" formControlName="endDate">
      </seech-date>
      <p *ngIf="form.errors?.['endDateInvalid'] && validateFormControls" class="error-feedback">
        End Date cannot be before start date.
      </p>
    </div>

    <div class="w-100">
      <label for="expiry-date" class="form-label">Bid Expiration Date</label>
      <seech-date [disabled]="readonly" [id]="'expiry-date'" [placeholder]="'Select a Date'"
        formControlName="expiresOn">
      </seech-date>
      <p *ngIf="form.errors?.['expiryDateInvalid'] && validateFormControls" class="error-feedback">
        Expiry Date cannot be before start date.
      </p>
    </div>

    <fieldset [disabled]="readonly">
      <label for="message" class="form-label">Message</label>
      <textarea #messageField [style.height]="messageFieldHeightInPx+'px'" class="message" cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="message"
        [readonly]="readonly" class="form-control bid-input p-2" id="message"
        placeholder="Additional message"></textarea>
    </fieldset>
  </ng-container>

</form>
