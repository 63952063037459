/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { LocalServiceToken } from './localServiceToken';
import { Connection } from './connection';


export interface Session { 
    token: LocalServiceToken;
    id: string;
    userId: string;
    userAgent?: string;
    loginOn: Date;
    lastSeen: Date;
    lastDetectedIP?: string;
    loginStatus?: string;
    connections: Array<Connection>;
    user: User;
}

