import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ProgressBarComponent } from '@seech/ux-ng';
import { GlobalProgressBarService } from '@profindar/shared-ng';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appGlobalProgressBar]',
  standalone: true
})
export class GlobalProgressBarDirective implements OnInit, OnDestroy {
  @Input() progress: number = 50;
  @Input() isIndeterminate: boolean = true;
  @Input() color: any = 'success';

  private subscription!: Subscription;
  private componentRef!: ComponentRef<ProgressBarComponent>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private globalProgressBarService: GlobalProgressBarService
  ) {}

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ProgressBarComponent);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    const instance = this.componentRef.instance;
    instance.progress = this.progress;
    instance.isIndeterminate = this.isIndeterminate;
    instance.color = this.color;

    this.subscription = this.globalProgressBarService.loaderState.subscribe((state: boolean) => {
      if (state) {
        this.viewContainerRef.insert(this.componentRef.hostView);
      } else {
        this.viewContainerRef.detach(this.viewContainerRef.indexOf(this.componentRef.hostView));
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
