/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skill } from './skill';
import { ProfessionalService } from './professionalService';
import { RequestedService } from './requestedService';
import { RequestedService2 } from './requestedService2';


export interface Service { 
    requisitionRequestedServices: Array<RequestedService>;
    professionalServices: Array<ProfessionalService>;
    projectRequestedServices: Array<RequestedService2>;
    id: string;
    name: string;
    jobTitle?: string;
    description: string;
    isVerified?: boolean;
    skills: Array<Skill>;
}

