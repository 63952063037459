/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ordinates } from './ordinates';


export interface CoordinateSequenceFactory { 
    ordinates: Ordinates;
}
export namespace CoordinateSequenceFactory {
}


