/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentSchedule } from './paymentSchedule';
import { PaymentInstallmentRelationship } from './paymentInstallmentRelationship';


export interface PaymentInstallment { 
    id: string;
    paymentScheduleId: string;
    description: string;
    amount: number;
    percentageDue: number;
    status?: string;
    acceptedOn?: Date;
    createdOn: Date;
    createdBy: string;
    paymentInstallmentRelationshipNewPaymentInstallments: Array<PaymentInstallmentRelationship>;
    paymentInstallmentRelationshipOldPaymentInstallments: Array<PaymentInstallmentRelationship>;
    paymentSchedule: PaymentSchedule;
}

