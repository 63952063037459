import { CALENDAR } from './calendar';
import { ROUTE } from './route';

export const CONSTANT = {
  ...CALENDAR,

  ROUTE,

  APP: {
    NAME: 'Profindar',
    VERSION: '1.0.0',
  },

  STORAGE_KEYS: {
    AUTH_TOKEN: 'auth-token',
    ACCESS_TOKEN: 'access-token',
    REFRESH_TOKEN: 'refresh-token',
    CURRENT_USER: 'current-user',
    CURRENT_USERNAME: 'current-username',
    CURRENT_USERID: 'current-user-id',
    CURRENT_SESSION: 'current-session',
  },

  DEFAULT_PAGINATION_SIZE: 50,
  MAX_FILE_UPLOAD_SIZE_IN_MB: 50,

  PAYMENT_REQUEST_STATUS: {
    OPEN: 'open', //edit allowed
    PENDING: 'payment-pending', //on request payment
    PAID: 'paid', //not editable
    DECLINED: 'payment-declined', //edit allowed
    APPROVED: 'payment-approved', //not editable
    PROCESSING: 'processing-payment', //not editable
    DRAFT: 'draft',
  },

  INSTALLMENT_CHANGE_REQ_TYPE: {
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    CREATE: 'CREATE',
    NONE: 'NONE',
  },

  PROJECT_TASK_STATUS: {
    IN_PROGRESS: 'in-progress',
    NOT_STARTED: 'not-started',
    DONE: 'done',
  },

  REALTIME: {
    METHOD: {
      PUSH: 'push',
      SHOWMESSAGE: 'showMessage',
    },

    CHANNEL: {
      INFO: 'info',
      MESSAGE: 'message',
      NOTIFICATIONS: 'notifications',
      USERINPUT: 'userInputAction',
    },
  },

  PAGE_VIEWER: {
    GUEST: 'guest',
    OWNER: 'owner',
  },

  API: {
    NOTIFICATION_FILTER: {
      READ: 'read' as NotificationFilter,
      UNREAD: 'unread' as NotificationFilter,
    },
  },

  COLOR: {
    PRIMARY: '#052406',
    SECONDARY: '#077D0B',
  },
};

//I am not sure if this is right oh, but I needed to strong type the parameters for get notifications count
export type NotificationFilter = 'read' | 'unread';

export const BADGE_COLORS: { [key in string]: string } = {
  competent: '#4CAF50 ', // Green
  affordable: '#FF9800', // Orange
  reliable: '#2196F3', // Blue
  consistent: '#9C27B0', // Purple
  honest: '#F7B901', // Amber
  accurate: '#3F51B5', // Indigo
  fast: '#E91E63', // Pink
  timely: '#00BCD4', // Cyan
  productive: '#8BC34A', // Light Green
  qualityoriented: '#673AB7', // Deep Purple
  recommendable: '#FF5722', // Deep Orange
  unknown: '#6c757d', // Gray
};

export const FILE_TYPES = ['image/jpeg', 'video/mp4', 'image/png'];
