import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { LayoutService } from '../layout.service';

export const layoutGuard: CanMatchFn = (route, segments) => {
  const layoutService = inject(LayoutService);
  if (route.data && route.data['layout']) {
    layoutService.layoutData = {
      ...layoutService.defaultLayoutConfig,
      ...route.data['layout'],
    };
  } else {
    layoutService.useDefaultConfig();
  }
  return true;
};
