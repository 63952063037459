import { Injectable } from '@angular/core';
import { DashboardService as DashboardClient } from '../../../../generated';
import { DATE_RANGE } from '@profindar/shared-ng';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private dashboardClient: DashboardClient) {}

  /**
   * Retrieves the total number of projects matching the specified criteria.
   *
   * @param startDate The start date for the project count query.
   * @param endDate The optional end date for the query (can be null or undefined).
   * @param status The optional project status filter (can be null or undefined).
   * @returns An Observable that emits the total number of projects.
   */
  getDashboardProjectStatusCount(statusId?: string) {
    return this.dashboardClient.dashboardGetProjectCountByStatus(statusId);
  }

  /**
   * Fetches a paginated list of associates.
   *
   * @param page The optional page number for pagination (can be undefined).
   * @param size The optional page size for pagination (can be undefined).
   * @returns An Observable that emits an array of associate objects.
   */
  getAssociates(page?: number, size?: number) {
    return this.dashboardClient.dashboardGetAssociates(page, size);
  }

  /**
   * Retrieves a paginated list of projects associated with the current user,
   * optionally filtered by status.
   *
   * @param query The optional project query filter (can be null or undefined).
   * @param page The optional page number for pagination (can be undefined).
   * @param size The optional page size for pagination (can be undefined).
   * @returns An Observable that emits an array of project objects.
   */
  getCurrentUserProjects(page?: number, size?: number) {
    return this.dashboardClient.dashboardGetCurrentUserOngoingProjects(
      page,
      size
    );
  }

  getDateRange(range: string): { startDate: Date; endDate: Date } {
    const now = new Date();
    let startDate: Date;
    const endDate: Date = now;

    switch (range) {
      case DATE_RANGE['DAY'].value:
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 1);
        break;
      case DATE_RANGE['WEEK'].value:
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 7);
        break;
      case DATE_RANGE['MONTH'].value:
        startDate = new Date(now);
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case DATE_RANGE['YEAR'].value:
        startDate = new Date(now);
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      default:
        throw new Error('Invalid date range');
    }

    return { startDate, endDate };
  }
}
