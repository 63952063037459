/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { PayRateType } from './payRateType';
import { BidStatus } from './bidStatus';
import { Requisition } from './requisition';


export interface Bid { 
    bidStatus: BidStatus;
    professional: User;
    payRateType: PayRateType;
    id: string;
    requisitionId: string;
    professionalId: string;
    amount: number;
    startDate?: Date;
    endDate?: Date;
    expiresOn?: Date;
    message?: string;
    bidStatusId: string;
    createdOn: Date;
    createdBy: string;
    updatedOn?: Date;
    rateTypeId: string;
    currency: string;
    requisition: Requisition;
}

