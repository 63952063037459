import { AfterViewInit, Component, Input } from '@angular/core';
import { ProfileService } from '@profindar/shared-ng';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements AfterViewInit  {
  @Input({required: true}) label!: string;
  @Input() backgroundColor!: string;
  @Input() icon: 'info-outline' | 'energy' = 'info-outline';

  constructor(
    private profileService: ProfileService
  ) {}

  ngAfterViewInit(): void {
    this.getColors();
  }

  getColors(){
    if (!this.backgroundColor) {
      this.backgroundColor =  this.profileService.getColorFromStatus(this.label)
    }
  }
  
}
