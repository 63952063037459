import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, catchError, defaultIfEmpty, map, mergeMap, of, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { SKIP_OPTION, SkipSettings } from '@profindar/shared-ng';
import { ToastService } from '@seech/ux-ng';

@Injectable()
export class TrafficInterceptor implements HttpInterceptor {
  public get token() {
    return this.authService.getCurrentToken();
  }
  public get accessToken() {
    return this.token
      ? `${this.token.token_type} ${this.token.access_token}`
      : '';
  }
  public get refreshToken() {
    return this.token ? this.token.refresh_token : '';
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skip = this.getSkipSettings(request);

    if (this.authService.isAuthenticated() || skip.authentication) {
      return this.completeRequest(request, next, skip);
    } else {
      return this.handle401(request, next, skip);
    }
  }

  getSkipSettings(req: HttpRequest<any>): SkipSettings {
    const skip: SkipSettings = {};
    const shouldSkipAll =
      req.url.startsWith('http') || req.context.get(SKIP_OPTION.ALL);

    Object.keys(SKIP_OPTION).forEach((key) => {
      const prop = key.toLowerCase() as keyof SkipSettings;
      const option = key.toUpperCase() as keyof typeof SKIP_OPTION;
      // Set all to true if skipping all, otherwise set based on each specific condition in the context
      skip[prop] = shouldSkipAll
        ? true
        : Boolean(req.context.get(SKIP_OPTION[option]));
    });

    return skip;
  }

  private completeRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    skip: SkipSettings
  ) {
    // const started = Date.now();
    // let outcome: string;
    if (!skip.request) {
      request = request.clone({
        headers: skip.headers ? request.headers : this.appendHeaders(request),
        url: skip.url ? request.url : environment.BASE_API_URL + request.url,
      });
    }

    return next.handle(request).pipe(
      // map((event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse) {
      //     // Check if the response contains the "data" object
      //     if (event.body && event.body.data && event.body.header && event.body.statusCode) {
      //       // Replace the entire response body with the "data" object
      //       return event.clone({ body: event.body.data });
      //     }
      //     return event.clone({ body: event.body });
      //   }
      //   return event;
      // }),
      
      // map((event: HttpEvent<any>) =>
      //    outcome = event instanceof HttpResponse ? 'succeeded' : '',
      // ),
      // tap((event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse) {
      //     const message = event.headers.get('Usermessage') as string;
      //     if (message) this.toast.success(message);
      //   }
      //   // this.sessionService.updateSessionStates(); // TODO : Implement keep alive
      // }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const message = error.headers.get('userMessage') as string;
          this.toast.error(message || `${error.statusText}, Please try again or contact support`);
        }

        if (!skip.response && error instanceof HttpErrorResponse) {
          return this.handleError(error, request, next, skip);
        } else {
          return throwError(() => error);
        }
      }),
      defaultIfEmpty(null)
      // finalize(() => {
      //     const elapsed = Date.now() - started;
      //     const msg = `${request.method} "${request.urlWithParams}" ${outcome} in ${elapsed} ms.`;
      // })
    );
  }

  private handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
    skip: SkipSettings
  ): Observable<any> {
    switch (error.status) {
      case 401:
        return this.handle401(request, next, skip);
      default:
        if (error.status >= 300 && error.status < 400)
          return throwError(() => error);
    }

    return throwError(() => 'Unknown server error has occurred'); // Unknown error / Do not retry automatically
  }

  private handle401(
    request: HttpRequest<any>,
    next: HttpHandler,
    skip: SkipSettings
  ) {
    const refreshToken = this.refreshToken;

    if (refreshToken) {
      return of(request).pipe(
        mergeMap(() => this.authService.new_access_token(refreshToken)),
        mergeMap((token) => this.completeRequest(request, next, skip)),
        catchError((err) => {
          //  this.goToLogin();
          return of(request); // throwError(err);
        })
      );
    } else {
      return of(request);
    }
  }

  private goToLogin() {
    // this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    this.authService.gotoExternalLogin({ returnUrl: this.router.url });
  }

  public appendHeaders(request: HttpRequest<any>) {
    const additionalHeaderValues = { Authorization: this.accessToken };

    for (const [key, value] of Object.entries(additionalHeaderValues)) {
      if (!request.headers.has(key)) {
        request = request.clone({
          headers: request.headers.set(key, String(value)),
        });
      }
    }

    return request.headers;
  }
}
