import {
  animate,
  state,
  style,
  transition,
  trigger,
  group,
  query,
  animateChild,
} from '@angular/animations';

export class AnimationDurations {
  static FAST = '195ms';
}

export const bottomSheetAnimation = trigger('bottomSheetState', [
  state('void, hidden', style({ transform: 'translateY(100%)' })),
  state('visible', style({ transform: 'translateY(0%)' })),
  transition(
    'visible => void, visible => hidden',
    group([
      animate(`${AnimationDurations.FAST} cubic-bezier(0.4,0.0,1,1)`),
      query('@*', animateChild(), { optional: true }),
    ])
  ),
  transition(
    'void => visible',
    group([
      animate(`${AnimationDurations.FAST} cubic-bezier(0.0,0.0,0.2,1)`),
      query('@*', animateChild(), { optional: true }),
    ])
  ),
]);
