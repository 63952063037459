import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import { environment } from '../../../../../../environments/environment';
import { ProfileService, Project } from '@profindar/shared-ng';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-summary',
  templateUrl: './activity-summary.component.html',
  styleUrls: ['./activity-summary.component.scss'],
})
export class ActivitySummaryComponent implements OnInit, OnDestroy {
  cdnBaseUrl = environment.cdnBaseUrl;
  projectId!: string;
  data!: Project;
  sub: Subscription = new Subscription();

  constructor(private modalService: ModalService, 
              private profileService: ProfileService) {}

  ngOnInit(): void {
    this.sub.add(
      this.profileService.getProjectDetails(this.projectId).subscribe(res => {
        this.data = res;
      })
    );
  }

  closeModal(){
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
