/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Bid } from '../model/models';
import { BidFeedbackResponse } from '../model/models';
import { BidRequest } from '../model/models';
import { BidResponse } from '../model/models';
import { BidResponseRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BidServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Bid Creation and Update Api
     * This API handles the creation and update of bids. It receives a BidRequest object as a parameter.          The following properties are required: Amount, StartDate, and RequisitionId. The rest are optional.          Validation rules:          1. Start date must be equal to or greater than the current date.          2. End date, if provided, must be greater than the current date and start date.          3. Expiry date, if provided, must be greater than the current date and start date.
     * @param bidRequest 
     */
    bidBid(bidRequest: BidRequest, extraHttpRequestParams?: any): Observable<Bid>;

    /**
     * 
     * 
     * @param bidResponseRequest 
     */
    bidBidResponse(bidResponseRequest: BidResponseRequest, extraHttpRequestParams?: any): Observable<BidFeedbackResponse>;

    /**
     * 
     * 
     * @param requisitionId 
     * @param professionalId 
     * @param serviceCount 
     */
    bidGetBidSessionForRequisition(requisitionId: string, professionalId: string, serviceCount?: number, extraHttpRequestParams?: any): Observable<BidResponse>;

    /**
     * 
     * 
     * @param requisitionId 
     * @param page 
     * @param size 
     */
    bidGetBidsByRequisitionId(requisitionId: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Bid>>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     * @param query 
     * @param serviceCount 
     */
    bidGetBidsWithRequisition(page?: number, size?: number, query?: string, serviceCount?: number, extraHttpRequestParams?: any): Observable<Array<BidResponse>>;

    /**
     * 
     * 
     * @param bidId 
     */
    bidWithdrawBid(bidId: string, extraHttpRequestParams?: any): Observable<Bid>;

}
