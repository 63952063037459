/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { AddRequirementResponse } from '../model/models';
import { InviteProfessionalsRequest } from '../model/models';
import { InvitedProfessional } from '../model/models';
import { InvitedProfessionalRequest } from '../model/models';
import { ProfessionalSummary } from '../model/models';
import { RequirementAnswerRequest } from '../model/models';
import { Requisition } from '../model/models';
import { RequisitionOfRequisitionInterface } from '../model/models';
import { RequisitionRequest } from '../model/models';
import { RequisitionResponse } from '../model/models';
import { StatusSummaryResponse } from '../model/models';
import { UpdateRequisitionRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RequisitionServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param requirementAnswerRequest 
     */
    requisitionAddRequirementAnswers(requirementAnswerRequest: RequirementAnswerRequest, extraHttpRequestParams?: any): Observable<AddRequirementResponse>;

    /**
     * 
     * 
     * @param requisitionRequest 
     */
    requisitionCreate(requisitionRequest: RequisitionRequest, extraHttpRequestParams?: any): Observable<Requisition>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     * @param requisitionStatus 
     * @param serviceCount 
     */
    requisitionGetCurrentUserRequisitions(query?: string, page?: number, size?: number, requisitionStatus?: string, serviceCount?: number, extraHttpRequestParams?: any): Observable<Array<RequisitionResponse>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     * @param serviceCount 
     */
    requisitionGetOpenRequisitions(query?: string, page?: number, size?: number, serviceCount?: number, extraHttpRequestParams?: any): Observable<Array<RequisitionResponse>>;

    /**
     * 
     * 
     * @param requisitionId 
     * @param page 
     * @param size 
     * @param query 
     */
    requisitionGetProfessionalRecommendationsForBidInvitation(requisitionId: string, page?: number, size?: number, query?: string, extraHttpRequestParams?: any): Observable<Array<ProfessionalSummary>>;

    /**
     * 
     * 
     */
    requisitionGetRequisitionCountByStatus(extraHttpRequestParams?: any): Observable<Array<StatusSummaryResponse>>;

    /**
     * 
     * 
     * @param id 
     */
    requisitionGetRequisitionDetailsById(id: string, extraHttpRequestParams?: any): Observable<RequisitionOfRequisitionInterface>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     * @param serviceCount 
     */
    requisitionGetRequisitionsCreatedByUser(query?: string, page?: number, size?: number, serviceCount?: number, extraHttpRequestParams?: any): Observable<Array<RequisitionResponse>>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     * @param query 
     */
    requisitionGetRequisitionsWithInvitation(page?: number, size?: number, query?: string, extraHttpRequestParams?: any): Observable<Array<InvitedProfessional>>;

    /**
     * 
     * 
     * @param invitedProfessionals 
     */
    requisitionInviteProfessionals(invitedProfessionals: InviteProfessionalsRequest, extraHttpRequestParams?: any): Observable<Requisition>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    requisitionProfessionalRecommendations(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProfessionalSummary>>;

    /**
     * 
     * 
     * @param requisitionId 
     */
    requisitionUnInviteProfessional(requisitionId: string, extraHttpRequestParams?: any): Observable<InvitedProfessional>;

    /**
     * 
     * 
     * @param requisitionId 
     * @param updatedInvitations 
     */
    requisitionUpdateBidInvitation(requisitionId: string, updatedInvitations: Array<InvitedProfessionalRequest>, extraHttpRequestParams?: any): Observable<AddRequirementResponse>;

    /**
     * 
     * 
     * @param requisitionRequest 
     */
    requisitionUpdateRequisition(requisitionRequest: UpdateRequisitionRequest, extraHttpRequestParams?: any): Observable<Requisition>;

}
