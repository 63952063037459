/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { InvitationResponse } from '../model/models';
import { User } from '../model/models';
import { UserProfileResponse } from '../model/models';
import { UserRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    userGetJobInvitations(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<InvitationResponse>>;

    /**
     * Get user profile by user identity
     * This API retrieves user profile, the user id is passed as a required parameter. User details such email, phone number          user review ratings, user review score, user badges, user services are also returned
     * @param id 
     */
    userGetUserProfileById(id: string, extraHttpRequestParams?: any): Observable<UserProfileResponse>;

    /**
     * Get user profile by user name
     * This API retrieves user profile, the user name is passed as a required parameter. User details such email, phone number          user review ratings, user review score, user badges, user services are also returned
     * @param username 
     */
    userGetUserProfileByUsername(username: string, extraHttpRequestParams?: any): Observable<UserProfileResponse>;

    /**
     * 
     * 
     * @param requisitionId 
     */
    userJobInvitationResponse(requisitionId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param userRequest 
     */
    userUpdateProfile(userRequest: UserRequest, extraHttpRequestParams?: any): Observable<User>;

}
