/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { UserSummary } from './userSummary';
import { Badge } from './badge';


export interface Review { 
    reviewer: UserSummary;
    reviewee: UserSummary;
    badgeName: string;
    badge?: Badge;
    id: string;
    projectId: string;
    createdBy: string;
    revieweeId: string;
    badgeId?: string;
    rating: number;
    comment?: string;
    createdOn: Date;
    project: Project;
}

