import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CONSTANT, ProfessionalSummary } from '@profindar/shared-ng';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-professional-summary-item',
  templateUrl: './professional-summary-item.component.html',
  styleUrls: ['./professional-summary-item.component.scss'],
})
export class ProfessionalSummaryItemComponent {
  cdnBaseUrl: string = environment.cdnBaseUrl;
  @Input() width: string = '100%';
  @Input() height: string = "5.375rem";
  @Input() cardInfo: ProfessionalSummary = {
    user: {
      imageURL: '',
    }
  } as ProfessionalSummary;

  @Input() isLoaded: boolean = false;
  
  @Input() showCheckbox: boolean = false;

  @Input() checked: boolean = false;
  bookMarked: boolean = false;

  @Output() bookMarkChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cardClick: EventEmitter<void> = new EventEmitter<void>(); // Renamed from onCardClick
  @Output() checkClicked: EventEmitter<boolean> = new EventEmitter<boolean>(); // Renamed from onCheckClicked
  @Output() menuClicked: EventEmitter<void> = new EventEmitter<void>(); // Renamed from onMenuClicked

  get profileRoute(): string{
    return `/${CONSTANT.ROUTE.PROFILE}/${this.cardInfo.user.username}`
  }

  onBookmarkChange(): void {
    this.bookMarked = !this.bookMarked;
    this.bookMarkChange.emit(this.bookMarked);
  }

  onCardClicked(): void {
    this.cardClick.emit(); // Use the renamed output property
  }

  onCheckClickedEvent(): void {
    this.checked = !this.checked;
    this.checkClicked.emit(this.checked); // Use the renamed output property
  }

  onMenuClickedEvent(): void {
    this.menuClicked.emit(); // Use the renamed output property
  }

   badgesArray (inputString:string) {
    const arr = inputString.split(',')
    .map(item => item.trim()) // Remove any surrounding whitespace
    .filter(item => item.length > 0); // Remove empty strings
    return arr
  }

//  generateColor(tag: string | any): string {
//     let hash = 0;
//     for (let i = 0; i < tag.length; i++) {
//       hash = tag.charCodeAt(i) + ((hash << 5) - hash); // Create hash from tag
//     }
  
//     // Apply a mask to get a darker color by masking the upper bits of each color component
//     const color = (hash & 0x007F7F7F) // Masking with 0x7F (127) to ensure the color components are not too bright
//         .toString(16)
//         .toUpperCase()
//         .padStart(6, '0'); // Ensure the color string is 6 characters long
  
//     return `#${color}`;
//   }

}
