import { Injectable } from '@angular/core';
import { UserService as UserClient, UserProfileResponse, UserRequest } from '../../../../generated';
import { User } from '../../../../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private userClient: UserClient) {}

  /**
   * Updates the profile of the user.
   * @param {User} user - The user object containing updated profile information.
   * @returns {Observable<User>} An observable emitting the response from the server,
   * or null if the update failed.
   */
  updateProfile(user: UserRequest): Observable<User> {
    return this.userClient.userUpdateProfile(user);
  }

  /**
   * Retrieves the profile of the user by user ID.
   * @param {string} id - The ID of the user whose profile to retrieve.
   * @returns {Observable<User>} An observable emitting the response from the server,
   * or null if the user profile does not exist.
   */
  getUserProfileById(id: string): Observable<UserProfileResponse> {
    return this.userClient.userGetUserProfileById(id);
  }

  /**
   * Retrieves the profile of the user by user ID.
   * @param {string} username - The ID of the user whose profile to retrieve.
   * @returns {Observable<User>} An observable emitting the response from the server,
   * or null if the user profile does not exist.
   */
  getUserProfileByUsername(username: string): Observable<UserProfileResponse> {
    return this.userClient.userGetUserProfileByUsername(username);
  }

   /**
    * Fetches a list of job invitations for a professional based on the provided page, and size.
    * 
    * @param page - The page number for pagination. Undefined to ignore pagination.
    * @param size - The number of items per page for pagination. Undefined to ignore pagination.
    * @returns  {Observable<InvitationResponse>}
    */
   getUserJobInvitations(page?: number, size?: number) {
    return this.userClient.userGetJobInvitations(page, size);
   }
}
