/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skill } from './skill';
import { ProfessionalService } from './professionalService';


export interface ProfessionalSkill { 
    skill: Skill;
    id: string;
    professionalServiceId: string;
    skillId: string;
    professionalService: ProfessionalService;
}

