<app-items-card-layout
  [textsWidth]="'65%'"
  [actionsWidth]="'20%'"
  class="hidden md:block lg:block"
>
  <div card-image-section class="!h-full">
    <div class="req-img !h-[7.688rem]">
      <div class="h-full rounded-md bg-[#EBF7EE] flex items-center justify-center">
        <i class="text-5xl text-secondary" [sch-icon]="'add-photo'"></i>
      </div>
      <!-- <div class="img !h-full w-full">
        <img
          height="150"
          width="150"
          [ngSrc]="'src//'"
          alt="icon"
          class="icon rounded-2xl !h-full !w-full object-cover"
        />
      </div> -->
    </div>
  </div>

  <div
    card-texts-section
    class="!h-[7.688rem] w-full flex flex-col justify-center"
  >
    <div class="texts-header">
      <div class="flex justify-between">
        <div class="left-sec">
          <div class="sih-texts custom-overflow w-full flex">
            <div class="w-full">
              <span
                [sch-tooltip]="data.services.join(', ')"
                class="font-[500] capitalize text-base block text-container lg:text-lg"
                *ngIf="data.services.length > 0"
                >{{ data.services.slice(0, 2).join(', ') }}</span
              >
            </div>
            <!-- <div class="flex items-end mx-1">
              <i
                *ngIf="data.services.length > 2"
                [sch-tooltip]="data.services.join(', ')"
                class="font-extrabold !text-[30px] h-[24px] horizontal-fill" [sch-icon]="'more-horizontal-fill'"></i>
            </div> -->
          </div>
        </div>
        <div class="right-sec flex gap-4">
          <div class="mx-2">
            <p
              [appSetColorByStatus]="data.status" [statusColors]="statusColors"
              class="py-[2px] font-[500] rounded-[2.43px] text-[14px] capitalize"
            >
              {{ data.status}}
            </p>
          </div>
          <div>
            <p class="font-semibold">
              {{ data.budget | currency :data.currency : 'symbol' : '1.0-0' }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="textx-sub-header flex items-center text-[12px] ml-[-5px] my-1"
      >
        <i class="!text-[1.4rem]" [sch-icon]="'location-outline'"> </i>
        <p class="mx-2 text-[12px] text-text-200">
          {{ data.location }}
        </p>
      </div>
    </div>

    <!-- <div class="texts-body"> -->
      <app-task-summary [tasks]="data.tasks || []"></app-task-summary>
    <!-- </div> -->
  </div>

  <div
    class="w-full h-[7.688rem] flex flex-col justify-around"
    card-actions-section
  >
    <div class="cas-header h-[35%] w-full">
      <div class="flex items-center justify-end">
        <div class="cas-header-texts text-[14px]">
          <span class="text-[#A3A3A3]">Created: </span
          ><span>{{ data.createdOn | date : 'MMM dd, yyyy' }}</span>
        </div>
        <div class="cas-header-icns flex cursor-pointer">
          <span>
            <i
              (click)="onMoreActions()"
              class="!text-[1.5625rem] hidden lg:block"
              [sch-icon]="'more-vertical-fill'"
            ></i>
          </span>
        </div>
      </div>
    </div>

    <div class="buttons w-full h-[50%] flex justify-end items-center">
      <button
        sch-button
        shape="rounded"
        (click)="onProjectView()"
        class="!bg-secondary !shadow-none justify-center text-nowrap !font-light capitalize text-white text-[14px] w-[6.756rem] !px-0"
      >
        {{ config.buttonLabel }}
      </button>
    </div>
  </div>
</app-items-card-layout>

<div class="block lg:!hidden md:!hidden lg:rounded-xl lg:border  lg:p-4 mobile-project-item h-[8.063rem] ">
    <div class="display-contents flex justify-between items-start w-full">
      <div class="image-status flex flex-col justify-center items-center w-[20%]">
        <div class="item-img rounded-lg flex items-center justify-center">
          <div class="h-full rounded-md bg-[#EBF7EE] flex items-center justify-center">
            <i class="text-5xl text-secondary" [sch-icon]="'add-photo'"></i>
          </div>
          <!-- <div class="img !h-full w-full">
            <img
              height="150"
              width="150"
              [ngSrc]="'src//'"
              alt="icon"
              class="icon rounded-2xl !h-full !w-full object-cover"
            />
          </div> -->
        </div>
        <div class="status flex justify-center items-center my-2 w-full">
          <p [appSetColorByStatus]="data.status" [statusColors]="statusColors"
            class="py-[2px] px-1 font-[400] text-center w-fit rounded-[0.75rem] text-[0.7rem] mx-2 capitalize"
          >
            {{ data.status }}
          </p>
        </div>
      </div>
  
      <div class="contents-texts w-[75%]">
        <div class="title-price flex items-center justify-between">
          <div class="title">
            <h1 class="!text-[0.875rem]">
              <div class="w-full text-container">
                <span
                  [sch-tooltip]="data.services.join(', ')"
                  class="font-[500] capitalize block "
                  *ngIf="data.services.length > 0"
                  >{{ data.services.slice(0, 2).join(', ') }}</span
                >
              </div>
            </h1>
          </div>
          <div class="price flex items-center justify-center">
            <p class="font-medium text-[0.875rem]">
              {{ data.budget | currency :data.currency : 'symbol' : '1.0-0' }}
            </p>
  
            <i
              class="!text-[1.4rem] !font-[600]"
              [sch-icon]="'more-vertical-fill'"
            >
            </i>
          </div>
        </div>
        <div
          class="project-item-sub-header flex items-baseline text-[0.75rem] my-1"
        >
          <i [sch-icon]="'location-outline'"> </i>
          <p class="mx-2 location text-text200 w-[65%] text-container">
            {{ data.location }}.
          </p>
        </div>
       
        <div class="texts-body">
          <app-task-summary  [tasks]="data.tasks || []"></app-task-summary>
        </div>

        <div class="action-buttons my-2">
          <div class="buttons w-full flex justify-between items-center text-[0.75rem]">
            <div class="cas-header-texts text-[0.75rem] my-1">
              <span class="text-[#A3A3A3]">Created: </span
              ><span>{{ data.createdOn | date : 'MMM dd, yyyy' }}</span>
            </div>
            <button
              sch-button
              shape="rounded"
              (click)="onProjectView()"
              class="!bg-secondary !shadow-none flex items-center justify-center h-[1.563rem] w-[4.438rem] text-xs !capitalize text-white"
            >
                View
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
