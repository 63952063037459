import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { AccountService, HttpRequestOptions, Token } from '@profindar/shared-ng';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../../services/session.service';
import { ModalService } from '@seech/ux-ng';
import { OnboardingComponent } from '../../../modules/features/onboarding/onboarding.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

    cdnBaseUrl: string = environment.cdnBaseUrl;
    loading = false;
    returnUrl = '';
    accessToken = '';
    clientId = '';
    action = '';

    constructor(
        private route: ActivatedRoute,
        private modalService: ModalService,
        private router: Router,
        private sessionService: SessionService,
        private accountService: AccountService,
        private authService: AuthService) {

        this.initializeLoginFlow();
       
    }

    initializeLoginFlow() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home';
        this.accessToken = this.route.snapshot.queryParams['token'];
        this.action = (this.route.snapshot.queryParams['action'] || 'login').toLowerCase();

        if (this.action === 'logout') {
            this.handleLogout();
        }
        else {
            this.handleLogin();
        }
    }

    handleLogout() {
        // this.accountService.logout(); TODO : Implement logout
        this.gotoExternalLogin({ action: 'logout' });
    }

    handleLogin() {
        this.loading = true;

        if (this.accessToken) {
            this.attemptNewLogin({ token_type: 'bearer', access_token: this.accessToken } as Token);
        }

        else if (this.authService.isAuthenticated()) {
            this.navigateAfterLogin();
        }

        else {
            this.gotoExternalLogin();
        }
    }

    attemptNewLogin(token: Token) {
        const headers = { 'Authorization': `${token.token_type} ${token.access_token}` };
        const options = { headers } as HttpRequestOptions;

        this.accountService.login(options).subscribe({
            next: (res) => {
                if (res.status === 204) {
                    this.router.navigate(['/signup'], { queryParams: { 'token': this.accessToken, 'returnUrl': this.returnUrl } });
                    return;
                }

                else {
                    this.sessionService.initSession(res.body!);
                }

                this.navigateAfterLogin();
            },
            error: (error) => {
                this.loading = false;

                if (error.status === 401) {
                    this.gotoExternalLogin();
                }

                else {
                    alert('Unable to complete login.'); // TODO : Use toast for better user experience.
                }
            },
            // complete: () => console.log('Login attempt completed')
        });
    }

    navigateAfterLogin() {
        if (this.action == 'account-setup') {
            this.router.navigate([this.returnUrl]).then(() => {
                this.openOnboarding()
            });
        }

        else {
            this.router.navigate([this.returnUrl]);
        }
    }

    gotoExternalLogin(parameters: {} = { returnUrl: this.returnUrl }) {
        this.authService.gotoExternalLogin(parameters);
    }

    openOnboarding(){
        this.modalService.open(OnboardingComponent, {
          data: {
          
          },
          ignoreBackdropClick: true,
          modalClass: 'modal-dialog-centered',
        });
      }
}
