<div class="dialog" [class.p-3]="!padding" [style.padding]="padding">
  <div class="dialog-title" *ngIf="hasHeader && ($isLoading | async) === false">
    <div
      *ngIf="alignment === 'center' && label"
      class="divider d-none d-md-inline-block"
    ></div>
    <p *ngIf="label" class="label">{{ label }}</p>
    <div class="divider"></div>
    <img
      class="close"
      width="28"
      height="auto"
      src="{{ cdnBaseUrl }}images/cancel-circle.svg"
      (click)="closeModal()"
    />
  </div>

  <div *ngIf="$isLoading | async" class="progress-header mb-3">
    <div
      class="d-flex justify-center align-items-center h-100 position-absolute left-0 right-0 z-1"
    >
      <seech-progress-bar
        class="w-100"
        [height]="'2px'"
        [isIndeterminate]="true"
        [color]="'secondary'"
      ></seech-progress-bar>
    </div>
    <div
      class="d-flex justify-center align-items-center h-100 position-absolute left-0 right-0 z-10"
    >
      <span
        *ngIf="label"
        class="label d-none d-md-inline-block ps-2 pe-2 bg-white"
        >{{ label }}</span
      >
    </div>
  </div>

  <div class="dialog-body">
    <ng-content></ng-content>
  </div>
</div>
