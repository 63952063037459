/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinates } from './coordinates';


export interface UserProfileResponse { 
    id: string;
    roleId: string;
    username: string;
    bio: string;
    name: string;
    imageUrl: string;
    phoneNumber: string;
    isProfessional: boolean;
    createdOn: Date;
    coordinates: Coordinates;
    totalCompletedJobs: number;
    servicesSummary: Array<string>;
    badgesSummary: Array<string>;
    reviewScore: number;
    reviewCount: number;
}

