import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppResolver {
  static resolve: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const injector = Injector.create({
      providers: [
        { provide: AuthService, deps: [] },
        { provide: Router, deps: [] }
      ]
    });
    const authService = injector.get(AuthService);
    const router = injector.get(Router);
    
    debugger;

    if (authService.isAuthenticated()) {
      router.navigate(['/home']);
      return true; // Assuming you want to return a resolved value
    } else {
      authService.gotoExternalLogin();
      return false; // Assuming you want to return a resolved value
    }
  }
}
