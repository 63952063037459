import { Injectable } from '@angular/core';
import { SavedService as SavedClient } from '../../../../generated';
import { SavedEntityRequest } from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class SavedService {
  constructor(private savedClient: SavedClient) {}

  /**
   * Adds a saved entity.
   * @param savedEntity The entity to be saved.
   * @returns An Observable indicating the result of adding the saved entity.
   */
  addSavedEntity(savedEntity: SavedEntityRequest) {
    return this.savedClient.savedAddSavedEntity(savedEntity);
  }

  /**
   * Deletes a saved entity.
   * @param savedEntityId The ID of the saved entity to be deleted.
   * @param savedRequisitionId The ID of the requisition associated with the saved entity.
   * @returns An Observable indicating the result of deleting the saved entity.
   */
  deleteSaveEntity(savedEntityId: string) {
    return this.savedClient.savedDeleteSaveEntity(savedEntityId);
  }

  /**
   * Retrieves all professional entities saved by the current user.
   * @param page The page number of saved entities to retrieve.
   * @param size The number of saved entities per page.
   * @returns An Observable containing professional entities saved by the current user.
   */
  getAllProfessionalSavedByMe(page?: number, size?: number) {
    return this.savedClient.savedGetAllProfessionalSavedByMe(page, size);
  }

  /**
   * Retrieves all requisition entities saved by the current user.
   * @param page The page number of saved entities to retrieve.
   * @param size The number of saved entities per page.
   * @returns An Observable containing requisition entities saved by the current user.
   */
  getAllRequisitionSavedByMe(page?: number, size?: number) {
    return this.savedClient.savedGetAllRequisitionSavedByMe(page, size);
  }

  /**
   * Retrieves professional entities saved by the current user.
   * @param id The ID of the professional entity to retrieve.
   * @returns An Observable containing professional entities saved by the current user.
   */
  getProfessionalSavedByMe(id: string) {
    return this.savedClient.savedGetProfessionalSavedByMe(id);
  }

  /**
   * Retrieves requisition entities saved by the current user.
   * @param id The ID of the requisition entity to retrieve.
   * @returns An Observable containing requisition entities saved by the current user.
   */
  getRequisitionSavedByMe(id: string) {
    return this.savedClient.savedGetRequisitionSavedByMe(id);
  }
}
