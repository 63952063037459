/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { ModalService, ToastService } from '@seech/ux-ng';
import { CONSTANT, HomeService, ProfessionalSummary, SavedService, User } from '@profindar/shared-ng';
import { HireModalComponent } from './hire-modal/hire-modal.component';
import { SessionService } from '../../../../../services/session.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-professional-summary-detail',
  templateUrl: './professional-summary-detail.component.html',
  styleUrls: ['./professional-summary-detail.component.scss'],
})
export class ProfessionalSummaryDetailComponent implements OnInit, OnChanges {
  @Input() proId = '';
  @Input() modalMode: boolean = false;
  @Input() showHireButton = true;

  @Output() detailsChanged = new EventEmitter<string>();

  professionalItem = {
    user: {
      totalCompletedJobs: 0,
    },
    projectStatusCounts: {
      abandoned: 0,
      completed: 0,
      active: 0,
    }
  } as unknown as ProfessionalSummary;
  cdnBaseUrl = environment.cdnBaseUrl;
  isFetchingProfessionalServices = true;
  isBookMarking: boolean = false;
  user: User = this.sessionService.getCurrentUser()!;
  isMobile = false;

  get profileRoute(): string {
    return `/${CONSTANT.ROUTE.PROFILE}/${this.professionalItem.user.username}`
  }

  constructor(
    private modalService: ModalService,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private savedServices: SavedService,
    private toastService: ToastService,
    private sessionService: SessionService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.modalService.modalRefComponent().subscribe({
      next: (data) => {
        if (data) {
          this.modalMode = data.modalMode || false;
        }
      }
    })
  }

   ngOnInit() : void {
    this.isFetchingProfessionalServices = true;
    this.loadProfessionalDetails();
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
        !state.matches && (this.modalMode = false);
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['proId']) {
      this.isFetchingProfessionalServices = true;
      this.loadProfessionalDetails();
    }
  }

  closeModal() {
    this.modalService.close();
    this.isMobile = false;
    this.modalMode = false;
  }

  hirePro() {
    this.modalService.open(HireModalComponent, {
      data: {
        professionalUserId: this.professionalItem.user.id,
      },
      modalClass: 'modal-dialog-centered'
    });
    this.modalMode = false;
  }

  bookmark() {
    if (this.isBookMarking) return;
    this.isBookMarking = true;

    const isCurrentlySaved = this.professionalItem.isSaved;
    const action = isCurrentlySaved ? this.savedServices.deleteSaveEntity(this.professionalItem.id)
      : this.savedServices.addSavedEntity({
        entityName: 'Professional',
        entityId: this.professionalItem.id,
      });

    const successMessage = isCurrentlySaved ? 'Professional removed successfully from bookmarks'
      : 'Professional has been bookmarked';
    const errorMessage = isCurrentlySaved ? 'Error removing professional from bookmarks'
      : 'Unable to bookmark professional';

    action.subscribe({
      next: (response) => {
        this.toastService.success(successMessage);
        this.professionalItem.isSaved = !this.professionalItem.isSaved;
        this.detailsChanged.emit(this.professionalItem.id);
      },
      error: (error) => {
        this.toastService.error(errorMessage);
      },
      complete: () => {
        this.isBookMarking = false;
        this.cdr.detectChanges();
      }
    });
  }

  loadProfessionalDetails() {
    const id = this.proId;
    this.cdr.detectChanges();
    this.homeService.getProfessionalSummaryDetails(id).subscribe({
      next: (response: any) => {
        this.professionalItem = response;
        this.isFetchingProfessionalServices = false;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.isFetchingProfessionalServices = false;
      }
    })
  }

  badgesArray(inputString: string) {
    const arr = inputString.split(',')
      .map(item => item.trim()) // Remove any surrounding whitespace
      .filter(item => item.length > 0); // Remove empty strings
    return arr
  }
}
