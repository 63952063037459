<section id="dashboard-home">
  <div class="card-wrapper" [ngClass]="{ 'd-flex': !isFetchingProjectCount }">
    <ng-container *ngIf="isFetchingProjectCount; else countReady">
      <div class="grid gap-2 gap-lg-3 grid-cols-4">
        <seech-placeholder
          *ngFor="let i of noOfLoadingItems"
          [width]="'100%'"
          [height]="'6rem'"
          [shape]="'rect'"
        ></seech-placeholder>
      </div>
    </ng-container>

    <ng-template #countReady>
      <div class="card" *ngFor="let item of dashboardCards">
        <div class="card-body">
          <div>
            <p class="card-body-count">{{ projectsCount[item.id.NAME] }}</p>
            <p class="card-body-label">{{ item.label }}</p>
          </div>
          <img [src]="item.icon" class="card-body-icon" />
        </div>
      </div>
    </ng-template>
  </div>

  <div class="double-items first-block">
    <div>
      <div class="stat-header">
        <p class="stat-text">Project Statistics</p>

        <div class="d-flex gap-2">
          <seech-dropdown
          [id]="'dropdown'"
          [alignment]="'down'"
          [direction]="'down'"
          label="Revenue"
          [color]="'light'"
          [disabled]="false"
          [size]="'sm'"
          >
          </seech-dropdown>
  
          <seech-dropdown
            [id]="'dropdown'"
            [alignment]="'down'"
            [direction]="'down'"
            label="Month"
            [color]="'light'"
            [disabled]="false"
            [size]="'sm'"
          >
            <li *ngFor="let range of dateRanges">
              <a
                sch-dropdown-item
                href="javascript:void(0)"
                (click)="onDateRangeSelect(range.value)"
                [id]="range.label.toLowerCase() + '-item'"
              >
                {{ range.label }}
              </a>
            </li>
          </seech-dropdown>
        </div>
      </div>

      <div class="card display-cards chart-wrapper">
        <seech-linechart
          [datasets]="projectChartDatasets"
          [labels]="projectChartLabels"
          [lineChartOptions]="projectChartOptions"
        ></seech-linechart>
      </div>
    </div>

    <div>
      <div class="stat-header">
        <p class="stat-text">Ongoing Projects</p>
      </div>

      <div
        class="card display-cards"
        [ngClass]="{ 'justify-center': ongoingProjects.length === 0 }"
      >
        <ng-container *ngIf="ongoingProjects.length === 0">
          <div class="empty-card">
            <p>Ongoing projects will appear here</p>
          </div>
        </ng-container>

        <ng-container *ngIf="ongoingProjects.length >= 1">
          <div
            class="projects"
            *ngFor="let project of ongoingProjects; let i = index"
          >
            <div class="d-flex align-items-center gap-2">
              <!-- <img [src]="project.image" class="projects-image" /> -->
              <p class="name-initials">
                {{ project.clientUserSummary.name | initials }}
              </p>
              <div>
                <p class="projects-name">{{ project.serviceSummary ? project.serviceSummary : 'N/A' }}</p>
                <p class="projects-alias">{{ project.referenceId }}</p>
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-between mb-2">
                <p class="projects-status">
                  {{
                    project.percentageCompletion === acceptedCompletedPercentage ? 
                    'Task Completed' : 'Ongoing Task'
                  }}
                </p>
                <p class="projects-progress">
                  <span>{{ project.percentageCompletion || 0}}</span> / 100
                </p>
              </div>
              <seech-progress-bar
                [id]="'project-progress-{{i}}'"
                [progress]="project.percentageCompletion || 0"
                [color]="'success'"
              >
              </seech-progress-bar>
              <div class="d-flex justify-content-between mt-2">
                <p class="projects-deadlineText">Deadline</p>
                <p class="projects-deadline">
                  {{
                    project.proposedEndDate
                      ? (project.proposedEndDate | date)
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="double-items">
    <div>
      <div class="stat-header">
        <p class="stat-text">Payments & Revenue</p>

        <div class="d-flex gap-1 align-items-center" role="button">
          <img src="{{ cdnBaseUrl }}images/filter-icon.svg" alt="filter icon" />
          <p class="ordering-text">Filter</p>
        </div>
      </div>

      <div
        class="card display-cards txn-card"
        [ngClass]="{ 'justify-content-center': transactionBody.length === 0 }"
      >
        <ng-container *ngIf="transactionBody.length === 0">
          <div class="empty-card">
            <p>Projects transactions will appear here</p>
          </div>
        </ng-container>

        <ng-container *ngIf="transactionBody.length >= 1">
          <table class="table align-middle mb-0 bg-white">
            <thead class="">
              <tr>
                <th *ngFor="let header of transactionHeaders">
                  <span class="table-header">
                    {{ header }}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of transactionBody">
                <td>
                  <p class="txn-desc">{{ row.desc }}</p>
                </td>

                <td>
                  <p class="txn-date">{{ row.date }}</p>
                </td>

                <td>
                  <p class="txn-id">{{ row.transactionId }}</p>
                </td>

                <td>
                  <p
                    class="txn-amount inward"
                    *ngIf="row.transactionStatus === txnStatuses.Credit"
                  >
                    {{ row.amount }}
                  </p>
                  <p
                    class="txn-amount stalling"
                    *ngIf="row.transactionStatus === txnStatuses.Pending"
                  >
                    {{ row.amount }}
                  </p>
                  <p
                    class="txn-amount outward"
                    *ngIf="row.transactionStatus === txnStatuses.Debit"
                  >
                    {{ row.amount }}
                  </p>
                </td>

                <td>
                  <span
                    class="txn-status success-status"
                    *ngIf="row.status === statuses.Received"
                  >
                    <p class="txn-status-desc inward">{{ row.statusDesc }}</p>
                  </span>
                  <span
                    class="txn-status warning-status"
                    *ngIf="row.status === statuses.Pending"
                  >
                    <p class="txn-status-desc stalling">{{ row.statusDesc }}</p>
                  </span>
                  <span
                    class="txn-status error-status"
                    *ngIf="row.status === statuses.Withdraw"
                  >
                    <p class="txn-status-desc outward">{{ row.statusDesc }}</p>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>

    <div>
      <div class="stat-header">
        <p class="stat-text">Associates</p>

        <div class="d-flex gap-1 align-items-center" role="button">
          <img src="{{ cdnBaseUrl }}images/sort-icon.svg" alt="sort icon" />
          <p class="ordering-text">Sort</p>
        </div>
      </div>

      <div
        class="card display-cards associate-card"
        [ngClass]="{ 'justify-center': associates.length === 0 }"
      >
        <ng-container *ngIf="associates.length === 0">
          <div class="empty-card">
            <p>Associate Clients will appear here.</p>
          </div>
        </ng-container>

        <ng-container *ngIf="associates.length >= 1">
          <div class="associate-card-header">
            <p>Name</p>
            <p>Project Count</p>
          </div>
          <div
            class="associates"
            *ngFor="let associate of associates; let i = index"
          >
            <div class="d-flex align-items-center gap-2">
              <!-- <img [src]="associate.imageURL" class="associates-image" /> -->
              <p class="name-initials">
                {{ associate.name | initials }}
              </p>
              <p class="associates-name">{{ associate.name }}</p>
            </div>

            <p class="associates-count">{{ associate.projectCount }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
