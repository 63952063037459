<div class="light position-relative">
  <seech-gold-layout>

    <div header class="header bg-primary-dark">

      <div #trigger="cdkOverlayOrigin" cdkOverlayOrigin class="w-100 h-100">
        <app-header *ngIf="!displayIsMobile" [showSidenav]="!!layoutData?.showSideNav"
          [unreadMessagesCount]="unreadMessagesCount" [unreadNotificationCount]="unreadNotificationCount" [user]="user"
          (exploreClick)="sideNav.toggle()" (profileClick)="showMenu=!showMenu" [nameLabel]="nameLabel"
          class="app-header"></app-header>

        <app-mobile-header *ngIf="displayIsMobile" (profileClick)="showMenu=!showMenu"
          [unreadMessagesCount]="unreadMessagesCount" [unreadNotificationCount]="unreadNotificationCount" [user]="user"
          [nameLabel]="nameLabel" (menuClick)="sideNav.toggle()"></app-mobile-header>
      </div>

      <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true"
        cdkConnectedOverlayBackdropClass="bg-transparent" (overlayOutsideClick)="showMenu = false"
        [cdkConnectedOverlayPositions]="displayIsMobile ? mobileDropDownMenuPosition : dropDownMenuPosition"
        [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showMenu">
        <app-menu (logOut)="logout()" [accountWebURL]="accountWebURL" *ngIf="!displayIsMobile" [menuItems]="menuItems"
          [reviewInfo]="revieweeInfo" [nameLabel]="nameLabel" [user]="user"
          (menuItemClick)="showMenu = false"></app-menu>
        <app-mobile-menu (logOut)="logout()" [accountWebURL]="accountWebURL" *ngIf="displayIsMobile"
          [menuItems]="menuItems" (menuItemClick)="showMenu = false"></app-mobile-menu>
      </ng-template>

    </div>

    <seech-side-nav class="side-nav" #sideNav [openForDesktop]="!!layoutData?.showSideNav"
      [sideNavBackgroundClass]="displayIsMobile ? 'bg-primary-dark': 'sidenav-background'">

      <div *ngIf="!displayIsMobile" class="d-flex h-100">
        <div class="d-flex flex-column h-100 side-nav-container w-100">

          <div class="child-container w-100">
            <app-side-nav-items [navigationItems]="firstSideNavItems">
            </app-side-nav-items>
          </div>

          <div class="child-container">
            <app-side-nav-items [navigationItems]="secondSideNavItems">
            </app-side-nav-items>
          </div>

        </div>
        <div class="bg-body px-1"> </div>
      </div>


      <div *ngIf="displayIsMobile" class="d-flex flex-column h-100">

        <header class="pb-2 mx-4 d-flex justify-content-between">

          <div class="d-flex gap-2 justify-content-center align-items-center">

            <img *ngIf="user?.imageURL else nameLabelTemplate" [src]="user?.imageURL" width="35" height="auto"
              class="rounded-circle">

            <ng-template #nameLabelTemplate>
              <div class="name-label d-flex justify-content-center align-items-center">
                <span class="text-white">{{nameLabel}}</span>
              </div>
            </ng-template>

            <div>
              <p class="name">{{user?.name}}</p>
              <span class="duration">Member since {{ user?.createdOn | date:'yyyy' }}</span>
            </div>
          </div>
          <i (click)="sideNav.close()" class="icon text-white" [sch-icon]="'android-arrow-back'"></i>
        </header>

        <hr class="divider" />

        <app-mobile-side-nav-items class="px-4 pb-4" [navigationItems]="firstSideNavItems"
          (selectedChanged)="sideNav.close(); haandleSideNavChange($event) ">
        </app-mobile-side-nav-items>

        <hr class="divider" />

        <app-mobile-side-nav-items class="flex-grow-1 px-4 pb-4" [navigationItems]="secondSideNavItems"
          (selectedChanged)="sideNav.close(); haandleSideNavChange($event) ">
        </app-mobile-side-nav-items>

        <div class="flex-grow-1">
        </div>

        <div class="d-flex flex-column px-4 side-nav-buttons align-items-center w-100">
          <button (click)="routeToBecomePro(); sideNav.close()" sch-button color="light" shape="rounded"
            class="become-a-pro btn-white text-capitalize text-secondary">
            {{user?.isProfessional ? "Add New Service" : "Become a Pro" }}
          </button>
          <button (click)="createRequisitionModal(); sideNav.close()"
            class="d-flex justify-content-center align-items-center gap-1 text-capitalize" sch-button shape="rounded" color="secondary">
            <img src="{{cdnBaseUrl}}images/add-circle.svg" class="add-circle">
            <span>New Job</span>
          </button>
        </div>
      </div>

    </seech-side-nav>

    <seech-layout-content>

      <div class="w-100 h-100 d-flex flex-column">
        <seech-progress-bar *ngIf="isLoading" [isIndeterminate]="true"
          [color]="'secondary'"></seech-progress-bar>

        <ng-container [cdkPortalOutlet]="portalTemplate"></ng-container>

        <div [ngClass]="{ 'profindr-page-ctn': !showSideNav || displayIsMobile}">
          <router-outlet></router-outlet>
        </div>

        <div class="flex-grow-1"></div>

        <app-footer *ngIf="!!layoutData?.showFooter">
        </app-footer>
      </div>

    </seech-layout-content>


    <app-mobile-nav footer class="position-fixed bottom-0 left-0 right-0 bg-white" #bottomSheetOrigin="cdkOverlayOrigin"
      cdkOverlayOrigin [navigationItems]="mobileNavigationItems"></app-mobile-nav>

    <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true" [cdkConnectedOverlayPanelClass]="'w-100'"
      (overlayOutsideClick)="showCreateSheet = false" [cdkConnectedOverlayPositions]="createSheetPosition"
      [cdkConnectedOverlayOrigin]="bottomSheetOrigin" [cdkConnectedOverlayOpen]="showCreateSheet">
      <app-create-bottom-sheet [@bottomSheetState]="showCreateSheet ? 'visible' : 'hidden'" [user]="user"
        (becomeAProClick)="routeToBecomePro(); showCreateSheet = false"
        (newProjectClick)="createRequisitionModal(); showCreateSheet = false"></app-create-bottom-sheet>
    </ng-template>

  </seech-gold-layout>
</div>
