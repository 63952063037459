import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { CONSTANT, UserSummary } from '@profindar/shared-ng';

@Directive({
  selector: '[appUserImageCollage]',
  standalone: true
})
export class UserImageCollageDirective {
  private get element(): HTMLElement {
    return this.el.nativeElement as HTMLElement;
  }

  @Input() public styleDeclaration = <CSSStyleDeclaration>{};

  @Input() set appUserImageCollage(users: UserSummary[] | undefined) {
    if(!users) return;
    this.initialize(users);
  }

  constructor(private el: ElementRef, 
              private renderer: Renderer2) {}

  initialize(users: UserSummary[]) {    
    this.element.innerHTML = "";
    const sortedUser = users.sort((a, b) => a.imageURL ? -1 : b.imageURL ? 0 : 1).slice(0, 4);

    this.element.style.position = 'relative';
    this.element.style.overflow = 'hidden';
    this.element.style.display = 'grid';
    this.element.style.gridTemplateColumns = 'repeat(2, 1fr)';
    this.element.style.gridTemplateRows = 'repeat(2, 1fr)';
    this.element.style.gap = '1px';

    for(let i = 0; i < sortedUser.length; i++){
      const newElement = this.createDiv(sortedUser[i]);

      switch(sortedUser.length){
        case 1: {
          newElement.style.gridColumnStart = '1';
          newElement.style.gridColumnEnd = '3';
          newElement.style.gridRowStart = '1';
          newElement.style.gridRowEnd = '3';

          const p = newElement.querySelector('p')  as HTMLElement;
          if(p) p.style.fontSize = '30cqw';
          break;
        }
        case 2: {
          newElement.style.gridRowStart = '1';
          newElement.style.gridRowEnd = '3';
          break;
        }
        case 3: {
          newElement.style.fontSize = 'calc(.5vw + .5vh)';
          if(i === 1){
            newElement.style.gridColumnStart = '2';
            newElement.style.gridColumnEnd = '3';
            newElement.style.gridRowStart = '1';
            newElement.style.gridRowEnd = '3';
          }
          break;
        }
      }

      this.renderer.appendChild(this.element, newElement);
    }

    if(users.length > 4){
      const counter = this.createImageCounterDiv(users.length);
      this.renderer.appendChild(this.element, counter);
    }
  }

  private createDiv(user: UserSummary): HTMLElement{
    const div = this.renderer.createElement('div') as HTMLElement;
    const imageUrl = user?.imageURL;

    div.textContent = ``;
    if (!imageUrl) {
      div.style.backgroundImage = ``;
      div.style.backgroundColor = this.styleDeclaration.backgroundColor || CONSTANT.COLOR.SECONDARY;
      div.style.textAlign = this.styleDeclaration.textAlign || 'center';
      div.style.display = this.styleDeclaration.display || 'flex';
      div.style.justifyContent = this.styleDeclaration.justifyContent || 'center';
      div.style.alignItems = this.styleDeclaration.alignItems || 'center';
      div.style.color = this.styleDeclaration.color || 'white';
      div.style.overflow = 'hidden';
      div.style.containerType = 'inline-size';

      const p = this.renderer.createElement('p') as HTMLElement;
      p.style.fontSize = 'calc(1vw + 1vh)'; //fallback for old browsers
      p.style.fontSize = '50cqw';
      p.textContent = `${this.formattedName(user)}`;
      this.renderer.appendChild(div, p);
    } else {
      div.style.backgroundImage = `url("${imageUrl}")`;
      div.style.backgroundPosition = 'center';
      div.style.backgroundRepeat = 'no-repeat';
      div.style.backgroundSize = 'cover';
    }

    return div;
  }

  private createImageCounterDiv(count: number): HTMLElement{
    const element = this.renderer.createElement('div') as HTMLElement;

    element.style.position = 'absolute';
    element.style.top = '50%';
    element.style.left = '50%';
    element.style.transform = 'translate(-50%, -50%)';
    element.style.backgroundColor = '#ffffff';
    element.style.color = 'black';
    element.style.display = 'flex';
    element.style.alignItems = 'center';
    element.style.justifyContent = 'center';
    element.style.borderRadius = '50%';
    element.style.height = '50%';
    element.style.width = '50%';
    element.style.fontSize = '70%';
    element.style.fontWeight = '600';
    element.textContent = count.toString();

    return element;
  }
  private formattedName(user: UserSummary): string {
    if (!user) {
      return '';
    } else if (user.name) {
      const userNames = user.name.split(' ');
      const names = this.searchAndFormatName(userNames);
      const fn = names[0][0];
      const ln = names[1][0];
      return (fn + ln).toLocaleUpperCase();
    }

    return user?.username ? user.username.split(' ')[0][0].toLocaleUpperCase() : '';
  }

  private searchAndFormatName(names: string[]): string[] {
    const userNames = [];
    for (let i = 0; i < names.length; i++) {
      if (names[i] != '') {
        userNames.push(names[i].trim());
      }
    }
    return userNames;
  }
}

