<section id="add-edit-requisition">
  <app-general-modal label="New Project">
    <div class="header d-none">
      <div class="d-flex gap-2 align-items-center me-4">
        <i [sch-icon]="'prev-arrow'" class="header-icon"></i>
        <p class="header-text">New Project</p>
      </div>

      <span class="header-line"> </span>
    </div>

    <form [formGroup]="addEditRequisitionForm" (ngSubmit)="submit()">
      <seech-stepper [id]="'parent-stepper'" #stepper [stepperBackground]="stepperBackground"
        [showTopSection]="hasMultiSteps">
        <seech-step [name]="'Project Detail'">
          <div class="content-wrapper task">
            <div class="input-with-icon w-100">
              <seech-combo-select nameKey="name" valueKey="value" autocomplete="off" [results]="tasksSuggestions"
                [(value)]="taskValue" (selectedChange)="handleSelectionChange($event, 'task')"
                (searchTextChanged)="onInputValueChange($event, 'task')" filterKey="name" formControlName="selectedTask"
                [id]="'task-input'" [label]="'Task'" (keyup.enter)="
                  $event.preventDefault();
                  handleSelectionChange(taskValue, 'task')
                " (keydown.tab)="
                  $event.preventDefault();
                  handleSelectionChange(taskValue, 'task')
                " [chipsColor]="'success'" [maxRowCount]="2" [maxSelectionCount]="acceptedTaskArrayLength"
                (selectionLimitReached)="listenToSelectionLimit($event, 'task')"
                (valueChange)="onChipsChange($event, 'task')" [iClass]="'combo-icon'">
                <i *ngIf="taskValue !== ''" (click)="addTask(taskValue)" [sch-icon]="'plus'" class="sch-input-icon"
                  action-icon></i>
              </seech-combo-select>
            </div>
            <seech-validation *ngIf="isControlInvalid('selectedTask') || taskArrayError" type="error"
              [message]="taskArrayError || 'Please add a task'"></seech-validation>
          </div>

          <div class="content-wrapper">
            <div class="input-with-icon w-100">
              <seech-combo-select nameKey="name" valueKey="value" autocomplete="off"
                [results]="skillsToolsTechSuggestions" [inputValue]="skillsValue"
                (selectedChange)="handleSelectionChange($event, 'skill')"
                (searchTextChanged)="onInputValueChange($event, 'skill')" filterKey="name"
                formControlName="selectedSkills" [id]="'skills-input'" [label]="'Services / Skills'" (keyup.enter)="
                  $event.preventDefault();
                  handleSelectionChange(skillsValue, 'skill')
                " (keydown.tab)="
                  $event.preventDefault();
                  handleSelectionChange(skillsValue, 'skill')
                " [chipsColor]="'success'" [maxRowCount]="2" [maxSelectionCount]="acceptedSkillsArrayLength"
                (selectionLimitReached)="
                  listenToSelectionLimit($event, 'skill')
                " (valueChange)="onChipsChange($event, 'skill')" [iClass]="'combo-icon'">
                <i *ngIf="
                    skillsToolsTechSuggestions.length < 1 && skillsValue !== ''
                  " (click)="addSkill(skillsValue)" [sch-icon]="'plus'" class="sch-input-icon" action-icon></i>
              </seech-combo-select>
            </div>
            <seech-validation *ngIf="isControlInvalid('selectedSkills') || skillArrayError" type="error"
              [message]="skillArrayError || 'Please add a skill'"></seech-validation>
          </div>

          <div class="content-wrapper">
            <p class="content-header">Location</p>

            <seech-location
              [coordinates]="locationValue"
              (placeSelected)="retrieveSelectedLocation($event)"
              [results]="[]"
              [id]="'location-input'"
              [chipsColor]="'success'"
            >
            </seech-location>

            <seech-validation *ngIf="isControlInvalid('location')" type="error" [message]="'Please add a location'">
            </seech-validation>

            <div class="flex flex-wrap items-start justify-between lg:justify-start gap-1.5 lg:gap-12">
              <seech-switch label="Remote" formControlName="isRemote"></seech-switch>

              <seech-switch label="Professional Location" formControlName="professionalLocation"></seech-switch>

              <seech-switch label="Client Location" formControlName="clientLocation"></seech-switch>

              <seech-switch label="Mobile" formControlName="isMobile"></seech-switch>
            </div>
          </div>

          <div class="justify-content-between md:!gap-[2rem] content-wrapper md:!flex-row">
            <div class="w-100">
              <p class="content-header mb-2">Start Date</p>

              <seech-date autocomplete="off" [disabled]="false" [(value)]="selectedDate" [id]="'date-picker'"
                [placeholder]="'Select a Date'" formControlName="proposedStartDate"
                (valid)="handleDateValidation($event)" [minDate]="minDate">
              </seech-date>

              <seech-validation *ngIf="isControlInvalid('proposedStartDate') || dateError" type="error"
                [message]="dateError || 'Please select a date'"></seech-validation>
            </div>

            <div class="w-100">
              <p class="content-header mb-2">
                Start Time
                <span class="optional"> (Optional) </span>
              </p>

              <seech-time autocomplete="off" [(value)]="selectedTime" [id]="'time-picker'" [useInline]="false"
                placeholder="Pick a time" formControlName="proposedStartTime"></seech-time>
            </div>
          </div>

          <div class="justify-content-between md:!gap-[2rem] content-wrapper md:!flex-row">
            <div class="w-100">
              <p class="content-header mb-2">
                End Date <span class="optional"> (Optional) </span>
              </p>

              <seech-date autocomplete="off" [disabled]="false" [(value)]="selectedEndDate" [id]="'end-date-picker'"
                [placeholder]="'Select a Date'" formControlName="proposedEndDate" (valid)="handleDateValidation($event)"
                [minDate]="minEndDate">
              </seech-date>
              <seech-validation *ngIf="endDateError" type="error" [message]="endDateError"></seech-validation>
            </div>

            <div class="w-100">
              <p class="content-header mb-2">
                End Time
                <span class="optional"> (Optional) </span>
              </p>

              <seech-time autocomplete="off" [(value)]="selectedEndTime" [id]="'end-time-picker'" [useInline]="false"
                placeholder="Pick a time" formControlName="proposedEndTime"></seech-time>
            </div>
          </div>

          <div class="justify-content-between content-wrapper md:!flex-row">
            <div class="w-100">
              <p class="content-header">Currency</p>
              <seech-currency [id]="'create-requisition-currency'" [options]="currencies"
                (selected)="handleSelectedCurrency($event)" formControlName="currency" (scrollEnd)="handlePagination()"
                [threshold]="20" (searchValueChange)="onSelectValueChange($event)" [variant]="'single-line'">
              </seech-currency>
              <seech-validation *ngIf="isControlInvalid('currency')" type="error"
                [message]="'Please select a currency'"></seech-validation>
            </div>

            <div class="w-100">
              <p class="content-header">Pay Rate</p>

              <input class="w-full px-3" type="number" id="rate-input" formControlName="budget" min="0" />
            </div>

            <div class="w-100">
              <p class="content-header">Rate Type</p>

              <seech-select autocomplete="off" [id]="'type-select'" [options]="rateTypes"
                (selected)="handleSelectedType($event)" label="" formControlName="rateTypeId">
              </seech-select>
              <seech-validation *ngIf="isControlInvalid('rateTypeId')" type="error"
                [message]="'Please select a rate type'"></seech-validation>
            </div>
          </div>

          <div class="plug-media-wrapper">
            <div class="plug-media">
              <p class="content-header d-flex flex-wrap">
                Upload Image
                <!-- ({{ fileTypes.join(',  ') }})  -->
                <span class="optional"> (Optional) </span>
              </p>
            </div>

            <div class="image-list">
              <div class="image-list-add">
                <i (click)="fileUpload.triggerUpload()" [sch-icon]="'add-circle'" class=""></i>

                <seech-file-upload id="file-upload" [maxSizeInMb]="5" #fileUpload="seechFileUpload"
                  formControlName="fileUploadControl" [size]="'md'" [disabled]="isUploading" [acceptMultiple]="true"
                  [max]="10" [fileTypes]="fileTypes">
                  Upload a file
                </seech-file-upload>
              </div>

              <ng-container>
                <div class="image-list-inner" *ngFor="let item of medias; let i = index">
                  <img [src]="item.src" class="image-list-inner-image" alt="Uploaded Image" />
                  <i [sch-icon]="'close'" class="image-list-inner-icon" (click)="removeAddedImage(i)"></i>
                </div>
                <div *ngIf="isUploading" class="w-full skills-loading">
                  <seech-progress-bar [id]="'progress-3'" [progress]="30" [isIndeterminate]="true"
                    [color]="'success'"></seech-progress-bar>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="content-wrapper">
            <!-- <p class="content-header">Notes</p>
            <textarea
              class="notes-input p-2"
              formControlName="description"
              id="notes-input"
              cols="10"
              rows="3"
              sch-character-limit
              [maxLength]="noteMaxlength"
              [threshold]="80"
              [embedCounter]="true"
              [allowExceed]="true"
              autocomplete="off"
              [(ngModel)]="noteDescription"
              [value]="noteDescription"
            ></textarea> -->

            <seech-textarea [id]="'notes-input'" [label]="'Notes'" [maxLength]="noteMaxlength" [threshold]="80"
              [allowExceed]="false" formControlName="description" [(value)]="noteDescription">
            </seech-textarea>
            <seech-validation *ngIf="noteDescription?.length! > noteMaxlength" type="error"
              [message]="noteMaxlengthError"></seech-validation>
          </div>

          <div class="d-flex justify-content-between align-items-center gap-2 flex-wrap">
            <ng-container *ngIf="!hiredPro">
              <div>
                <seech-checkbox [(selected)]="checkboxValue" label="Allow Public Bidding" id="'checkbox'"
                  color="success" (selectedChange)="showStepperHeader()">
                </seech-checkbox>
              </div>
            </ng-container>

            <ng-container *ngIf="hiredPro">
              <div class="d-flex gap-1 align-items-center">
                Professional:
                <p class="fw-semibold fst-italic">
                  {{ hiredPro.name }}
                </p>
              </div>
            </ng-container>

            <ng-container *ngIf="hasMultiSteps">
              <button class="content-buttons btn-primary" size="lg" sch-button fill="solid" [id]="'next-page'" (click)="
                  addEditRequisitionForm.invalid ||
                  taskArray.length < 1 ||
                  skillsArray.length < 1
                    ? submit()
                    : stepper.selectStep(1)
                ">
                Continue
                <i [sch-icon]="'next-arrow'" class="icon d-none"></i>
              </button>
            </ng-container>

            <ng-container *ngIf="!hasMultiSteps">
              <button class="content-buttons btn-primary" size="lg" sch-button fill="solid" [id]="'create-project'"
                (click)="submit()">
                <div *ngIf="isSubmitting" class="button-loader mt-1">
                  <seech-spinner [id]="'job-spinner'" [color]="'secondary'">
                  </seech-spinner>
                </div>
                Post Job
              </button>
            </ng-container>
          </div>
        </seech-step>

        <ng-container *ngIf="isMediaAddedInFirstStep">
          <seech-step [name]="'Gallery'">
            <ng-container *ngIf="!isMediaAdded">
              <div class="upload-media">
                <div>
                  <button class="content-buttons upload-media-btn" size="lg" sch-button
                    (click)="fileUpload.triggerUpload()" fill="solid" [id]="'upload-media'">
                    <i [sch-icon]="'login'" class="upload-media-icon"></i>
                    Upload image / videos
                  </button>

                  <seech-file-upload id="file-upload" [maxSizeInMb]="5" #fileUpload="seechFileUpload"
                    formControlName="fileUploadControl" [size]="'md'" [disabled]="false" [acceptMultiple]="true"
                    [max]="10" [fileTypes]="fileTypes">
                    Upload a file
                  </seech-file-upload>
                </div>

                <p class="content-header">
                  Browse for file upload
                  <span class="optional"> ({{ fileTypes.join(', ') }}) </span>
                </p>
              </div>
            </ng-container>

            <ng-container *ngIf="isMediaAdded">
              <div class="media-uploaded">
                <div class="media-header">
                  <p class="content-header">
                    Upload Images / Videos
                    <span class="optional">
                      ({{ fileTypes.join(', ') }})
                    </span>
                  </p>

                  <div>
                    <button class="content-buttons add-media" size="lg" sch-button (click)="fileUpload.triggerUpload()"
                      fill="solid" [id]="'add-media'">
                      Upload
                    </button>

                    <seech-file-upload id="file-upload" [maxSizeInMb]="5" #fileUpload="seechFileUpload"
                      formControlName="fileUploadControl" [size]="'md'" [disabled]="false" [acceptMultiple]="true"
                      [max]="10" [fileTypes]="fileTypes">
                      Upload a file
                    </seech-file-upload>
                  </div>
                </div>

                <div class="uploaded-media">
                  <seech-gallery [mediaItems]="medias" (selectedItemChanged)="onItemClick($event)"
                    [options]="options"></seech-gallery>
                </div>

                <div class="footer-buttons">
                  <button class="content-buttons prev-button" size="lg" sch-button fill="outline" [id]="'prev-step'"
                    (click)="stepper.selectStep(0)">
                    <i [sch-icon]="'prev-arrow'" class="icon d-none"></i>
                    Previous
                  </button>

                  <ng-container *ngIf="!checkboxValue">
                    <button class="content-buttons btn-primary" size="lg" sch-button fill="solid" [id]="'next-step'"
                      (click)="stepper.selectStep(2)">
                      Continue
                      <i [sch-icon]="'next-arrow'" class="icon d-none"></i>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="checkboxValue">
                    <button class="content-buttons btn-primary" size="lg" sch-button fill="solid"
                      [id]="'create-project'" (click)="submit()">
                      <div *ngIf="isSubmitting" class="button-loader mt-1">
                        <seech-spinner [id]="'job-spinner'" [color]="'secondary'">
                        </seech-spinner>
                      </div>
                      Post Job
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </seech-step>
        </ng-container>

        <ng-container *ngIf="!checkboxValue">
          <seech-step [name]="'Professional'">
            <ng-container *ngIf="!isProjectCreated">
              <div class="prof-header">
                <div class="d-flex gap-1 align-items-center">
                  <p class="prof-text">Create Bid Invitations</p>
                  <i [sch-icon]="'priority'" class="content-header-icon"></i>
                </div>

                <div class="d-flex flex-column">
                  <button class="align-self-end content-buttons btn-primary" size="lg" sch-button fill="solid"
                    [id]="'post-job'" (click)="submit()">
                    <div *ngIf="isSubmitting" class="button-loader mt-1">
                      <seech-spinner [id]="'job-spinner'" [color]="'secondary'">
                      </seech-spinner>
                    </div>
                    Post Job
                  </button>

                  <seech-validation *ngIf="noInvitedProError" type="error"
                    [message]="noInvitedProError"></seech-validation>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isProjectCreated">
              <div class="submitted-section">
                <div class="animation">
                  <img class="w-100" src="{{ cdnBaseUrl }}images/success.gif" />
                </div>
                <p class="submitted-section-title">Project Created</p>
                <p class="submitted-section-sub">
                  Please wait for a professional's response
                </p>

                <div class="button-wrapper">
                  <button class="content-buttons prev-button" size="lg" sch-button fill="outline" [id]="'new-project'"
                    (click)="stepper.selectStep(0)">
                    <i [sch-icon]="'add-circle'" class="icon"></i>
                    Create New Project
                  </button>

                  <button class="content-buttons btn-primary" size="lg" sch-button fill="solid" [id]="'view-projects'"
                    (click)="closeProjectModal()">
                    View Created Project
                  </button>
                </div>
              </div>
            </ng-container>

            <div class="mt-3 mb-4">
              <app-requisition-invitation (selectedProfessionalsChange)="
                  retrieveSelectedProfessionals($event)
                ">
              </app-requisition-invitation>
            </div>
          </seech-step>
        </ng-container>
      </seech-stepper>
    </form>
  </app-general-modal>
</section>