/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { ProfileService as ProfileClient } from '../../../../generated';
import { Observable } from 'rxjs';
import { Calendar } from '../../../../models';
import { BADGE_COLORS } from 'libs/shared-ng/src/lib/constants/constant';



@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private profileClient: ProfileClient) {}

  /**
   * Retrieves calendar projects for a user within a specified date range.
   * @param {string} userId - The ID of the user whose calendar projects are to be retrieved.
   * @param {Date| undefined} startDate - The start date of the date range. Can be null or undefined for no start date.
   * @param {Date| undefined} endDate - The end date of the date range. Can be null or undefined for no end date.
   * @returns {Observable<Calendar[]>} An observable that resolves to the calendar projects within the specified date range.
   */
  getCalendarProjects(
    userId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<Calendar[]> {
    return this.profileClient.profileGetUserCalendar(
      userId,
      startDate,
      endDate
    );
  }

  /**
   * Retrieves projects associated with a user based on specified parameters.
   * @param {string} userId - The ID of the user whose projects are to be retrieved.
   * @param {string| undefined} query - The query string to filter projects. Can be null or undefined for no query.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @returns {Observable<ProjectResponse[]>} An observable that resolves to the list of projects associated with the user.
   */
  getUserProjects(
    userId: string,
    query?: string,
    page?: number,
    size?: number
  ) {
    return this.profileClient.profileGetUserProjects(userId, query, page, size);
  }

  /**
   * Retrieves professional summary details associated with a user.
   * @param {string} id - The ID of the user whose professional summary details are to be retrieved.
   * @returns {Observable<ProfessionalServiceService>} An observable that resolves to the professional summary details associated with the user.
   */
  getProfessionalSummaryDetails(id: string) {
    return this.profileClient.profileGetProfessionalSummaryDetails(id);
  }

  /**
   * Retrieves project details based on a project ID.
   * @param {string} id - The ID of the project whose details are to be retrieved.
   * @returns {Observable<Project>} An observable that resolves to the details of the project.
   */
  getProjectDetails(id: string) {
    return this.profileClient.profileGetProjectDetails(id);
  }

  /**
   * Retrieves professional services associated with a user based on specified parameters.
   * @param {string} userId - The ID of the user whose professional services are to be retrieved.
   * @param {string| undefined} query - The query string to filter professional services. Can be null or undefined for no query.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @returns {Observable<ProfessionalServiceResponse[]>} An observable that resolves to the list of professional services associated with the user.
   */
  getUserProfessionalServices(
    userId: string,
    query?: string,
    page?: number,
    size?: number
  ) {
    return this.profileClient.profileGetUserProfessionalServices(
      userId,
      query,
      page,
      size
    );
  }


  /**
   * Retrieves colors assosiated with each badge status.
   * @param {Status} status - The badge status.
   * @returns {string} A color string associated with the inputed badge status.
   */
  public getColorFromStatus(status: string): string {
      return BADGE_COLORS[status.trim().toLowerCase()] ?? BADGE_COLORS['unknown'];
  }

}
