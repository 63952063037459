import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';

@Component({
  selector: 'app-media-upload-preview',
  templateUrl: './media-upload-preview.component.html',
  styleUrls: ['./media-upload-preview.component.scss'],
})
export class MediaUploadPreviewComponent implements OnChanges {
  @ViewChild('modalContainer') modalContainer!: GeneralModalComponent;
  @Output() sendMessage: EventEmitter<string> = new EventEmitter();
  @Output() selectMore: EventEmitter<void> = new EventEmitter();
  @Output() closePreview: EventEmitter<void> = new EventEmitter();
  messageBody: string = '';
  @Input() files!: FileList;
  mediaList: SafeResourceUrl[] = [];
  selectedMedia: number = 0;

  constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['files']?.currentValue){
      // console.log(changes['files']?.currentValue);
      this.getResourceUrl()
    }
  }

  getResourceUrl(files?: FileList){
    const additions = Array.from(files ?? this.files).map(file => {
      const url = URL.createObjectURL(file);
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
    this.mediaList = [...this.mediaList, ...additions];
    // setTimeout(() => this.cdr.detectChanges(), 2000);
    // console.log(this.mediaList);
    this.cdr.detectChanges();
  }

  removeMedia(index: number){
    if(this.mediaList.length === 1) {
      this.modalContainer.closeModal();
    }
    else{
      this.mediaList.splice(index, 1);
      this.selectedMedia = this.selectedMedia === index && index != 0 ? this.selectedMedia - 1 : this.selectedMedia;
    }
  }


  handleKeyUp(event: any) {
    if (!event.shiftKey) {
      this.sendMessage.emit(this.messageBody);
    }
  }
}
