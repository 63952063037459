import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { environment } from '../../../../../../environments/environment';
import { ModalService, ToastService } from '@seech/ux-ng';
import {
  HomeService,
  RequisitionResponse,
  SavedService,
  User,
} from '@profindar/shared-ng';
import { BidComponent } from '../../../bid';
import { toRequisitionResponse } from 'apps/profindar-web/src/app/models';
import { SessionService } from 'apps/profindar-web/src/app/services/session.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-requisition-detail',
  templateUrl: './requisition-detail.component.html',
  styleUrls: ['./requisition-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequisitionDetailComponent implements OnInit, OnChanges, AfterViewChecked, AfterViewInit {
  @Input() hasBid: boolean = true;
  @Input() reqId: string = '';
  @Input() modalMode: boolean = false;
  @Input({ required: true }) user: User | null = null;
  @Output() detailsChanged = new EventEmitter<string>();

  @ViewChild('taskDiv') taskDiv!: ElementRef;

  requisitionItem: RequisitionResponse = {} as RequisitionResponse;
  services: string[] = [''];
  cdnBaseUrl = environment.cdnBaseUrl;
  isFetchingReqServices = true;
  isBookMarking: boolean = false;
  loggedInUser: User | null = this.sessionService.getCurrentUser();
  showMoreButton: boolean = true;
  showAllTasks: boolean = false;
  isViewChecked: boolean = false;
  isMobile = false;

  constructor(
    private modalService: ModalService,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private savedServices: SavedService,
    private toastService: ToastService,
    private sessionService: SessionService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.sessionService
      .getCurrentUserAsync()
      .subscribe((user) => {
        this.loggedInUser = user;
      });
    this.modalService?.modalRefComponent().subscribe({
      next: (data) => {
        if (data) {
          this.modalMode = data.modalMode || false;
          this.reqId = data.reqId || '';
        }
      },
    });
  }

  ngOnInit(): void {
    this.isFetchingReqServices = true;
    this.loadReqDetails();
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state) => {
        // this.displayIsMobile = state.matches;
        // console.log("home", state)
        this.isMobile = state.matches;
        !state.matches && (this.modalMode = false);

        this.cdr.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reqId']) {
      this.showAllTasks = true
      this.isFetchingReqServices = true;
      this.reqId && this.loadReqDetails();
    }
  }

  ngAfterViewInit(): void {
    // Wait until the view is fully initialized
    this.limitTasksTo2line();
  }


  ngAfterViewChecked(): void {
    if (this.isViewChecked) {
      this.isViewChecked = false;
      this.limitTasksTo2line(); // Ensure this runs after every check
    }
  }

  toggleSeeMore() {
    this.showAllTasks = !this.showAllTasks;
    this.cdr.detectChanges();
  }

  openBidDialog(requisition: RequisitionResponse) {
    this.modalService.open(BidComponent, {
      data: {
        requisitionId: requisition.id,
        bidSessionId: this.user?.id,
      },
      modalClass: 'bid-dialog',
      ignoreBackdropClick: false,
    });
  }

  bookmark() {
    if (this.isBookMarking) return;
    this.isBookMarking = true;

    const isCurrentlySaved = this.requisitionItem.isSaved;
    const action = isCurrentlySaved
      ? this.savedServices.deleteSaveEntity(this.requisitionItem.id)
      : this.savedServices.addSavedEntity({
        entityName: 'Requisition',
        entityId: this.requisitionItem.id,
      });

    const successMessage = isCurrentlySaved
      ? 'Job has been removed from bookmark'
      : 'Job has been bookmarked';

    action.subscribe({
      next: () => {
        this.requisitionItem.isSaved = !isCurrentlySaved;
        this.toastService.success(successMessage);
        this.detailsChanged.emit(this.requisitionItem.id);
      },
      error: (error) => {
        this.toastService.error('Failed to update bookmark status');
      },
      complete: () => {
        this.isBookMarking = false;
        this.cdr.detectChanges();
      },
    });
  }

  loadReqDetails() {
    this.homeService.getRequisitionDetails(this.reqId).subscribe({
      next: (response) => {
        this.requisitionItem = toRequisitionResponse(response);
        this.isFetchingReqServices = false;
        this.services = this.requisitionItem.services;
        this.isViewChecked = true;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.isFetchingReqServices = false;
        this.cdr.detectChanges();
      },
    });
  }

  limitTasksTo2line() {
    // this.showMoreButton = false;
    // this.showAllTasks = true;

    // // Defer the DOM check to ensure the view is fully updated
    // this.cdr.detectChanges(); 

    const height = this.taskDiv?.nativeElement.offsetHeight;
    if (height > 50) {
      this.showMoreButton = true;
      this.showAllTasks = false;
    } else {
      this.showAllTasks = true;
      this.showMoreButton = false;
    }
    this.cdr.detectChanges();
  }
}
