import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconsNgModule } from '@seech/icons-ng';
import { SideNavItemsComponent } from './side-nav-items/side-nav-items.component';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent, MobileHeaderComponent } from './headers';
import { StandardLayoutComponent } from './standard-layout/standard-layout.component';
import { LayoutNgModule } from '@seech/layout-ng';
import { ReviewModule } from '../modules/general/review/review.module';
import { UxNgModule } from '@seech/ux-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { OverlayModule } from '@angular/cdk/overlay';
import { MenuComponent } from './menu/menu.component';
import { PortalModule } from '@angular/cdk/portal';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { MobileSideNavItemsComponent } from './mobile-side-nav-items/mobile-side-nav-items.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { CreateBottomSheetComponent } from './create-bottom-sheet/create-bottom-sheet.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const COMPONENTS = [
  SideNavItemsComponent,
  FooterComponent,
  HeaderComponent,
  MobileHeaderComponent,
  StandardLayoutComponent,
  MenuComponent,
  MobileNavComponent,
  MobileSideNavItemsComponent,
  MobileMenuComponent,
  MobileMenuComponent,
  CreateBottomSheetComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    RouterModule,
    IconsNgModule,
    CommonModule,
    ControlsNgModule,
    UxNgModule,
    LayoutNgModule,
    ReviewModule,
    OverlayModule,
    PortalModule,
    BrowserAnimationsModule,
  ],
})
export class LayoutModule {}
