<div
  (click)="onCardClicked(); $event.stopPropagation()"
  class="service-item-card cursor-pointer hover:!border-secondary"
  [ngStyle]="{ width: width, height: height,  backgroundColor: bgColor, }"
  [ngClass]="{
     'card-active': active ,
     'borderless': borderless,
     }"
>
  <div class="w-full">
    <div class="service-item-header mb-1">
      <div class="flex justify-between items-center">
        <div class="sih-texts custom-overflow capitalize w-auto flex">
          <div class="w-full">
            <span
              [sch-tooltip]="data.services.join(', ')"
              class="font-[500] text-base block lg:text-lg text-container"
              *ngIf="data.services.length > 0"
              >{{ data.services.join(', ') }}</span
            >
          </div>
          <!-- <div class="flex items-end mx-1">
            <i
              *ngIf="data.services.length > 2"
              [sch-tooltip]="data.services.join(', ')"
              class="font-extrabold !text-[30px] h-[24px] horizontal-fill" [sch-icon]="'more-horizontal-fill'"></i>
          </div> -->
        </div>

        <div
          class="sih-price font-[500] text-[15px] w-auto text-base flex justify-end items-center"
        >
          <span class="ml-3 text-end">
            {{ data.budget | currency : data.currency : 'symbol' }}
          </span>
        </div>
      </div>
    </div>

    <div class="service-item-texts-body">
      <app-task-summary
        [displayLimit]="9"
        [tasks]="data.tasks"
        [autoHeight]="autoHeight"
      ></app-task-summary>
    </div>

    <div class="buttons w-full flex justify-between items-center mt-2">
      <div class="w-[50%]">
        <div
          class="service-item-sub-header flex items-center md:text-[10px] text-[9px] lg:text-[12px]"
        >
          <div *ngIf="data.location">
            <i
              class="text-lg loction-icn ml-[-5px]"
              [sch-icon]="'location-outline'"
            >
            </i>
          </div>
          <p class="mx-1 location text-text200 w-[65%] text-container">
            {{ data.location }}.
          </p>
        </div>

        <div class="timeLine mt-0">
          <p class="text-[6px] lg:text-[10px] text-black-100">
            Posted {{ data.createdOn | schTimeAgo }}
          </p>
        </div>
      </div>
      <div class="w-[50%] flex justify-end items-end">
        <!-- <button
          (click)="bookmark(data); $event.stopPropagation()"
          fill="outline"
          sch-button
          shape="rounded"
          class="save-button !border-none flex items-center justify-center h-[2.2rem] bg-[#EBF7EE] !text-secondary mx-2 lg:mx-3 w-[1rem]"
        >
          <i
            class="!text-lg"
            [sch-icon]="data.isSaved ? 'bookmark-fill' : 'bookmark'"
          ></i>
        </button> -->
        <button
          (click)="bookmark(data); $event.stopPropagation()"
          class="button-save bg-[#EBF7EE] !text-secondary h-9 !w-9"
        >
          <span
            [sch-icon]="data.isSaved ? 'bookmark-fill' : 'bookmark'"
            class="me-0"
          ></span>
        </button>
        <button
          *ngIf="data.createdBy !== user?.id"
          (click)="onBid(); $event.stopPropagation()"
          sch-button
          fill="solid"
          shape="rounded"
          class="!bg-secondary justify-center capitalize h-9 text-white flex bid-button"
          [ngClass]="[data.hasBid ? 'not-bid' : 'has-bid']"
        >
          <i [sch-icon]="data.hasBid ? 'bid-outline' : 'bid-outline'"></i>

          <p class="text-[8px] lg:text-[14px] mx-1 text-nowrap">
            {{ data.hasBid ? 'View Bid' : 'Bid' }}
          </p>
        </button>
      </div>
    </div>
  </div>
</div>
