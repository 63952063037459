<section id="view-ratings-modal">
    <div></div>
    <app-general-modal label="">
        <div class="header">
            <div class="col-12 text-secondary text-xl header-items">
                {{ name | titlecase}}
             </div>
             <div class="col-12 mt-2 text-xl header-items">
                 <app-profindar-star-rating [(ngModel)]="reviewRating" [starCount]="5" [disabled]="true"></app-profindar-star-rating>
             </div>
             <div class="col-12 mt-2 mb-2 text-sm header-items">
                {{ numberOfReviews }} Review(s)
             </div>
        </div>
        <div class="w-full flex justify-between mb-4 mt-4 text-sm" *ngIf="badges.length > 0">
            <span class="filter-sort">
                <div class="options">Filter</div>
                    <seech-select 
                        autocomplete="off"
                        [options]="badges" 
                        [(ngModel)]="selectedBadge"
                        (selected)="onFilterSelect($event)"
                        label="" >
                    </seech-select>
            </span>
            <span class="filter-sort">
                <div class="options">Sort</div>
                    <seech-select 
                        autocomplete="off" 
                        [options]="sortOptions"
                        [(ngModel)]="selectedSortOption"
                        (selected)="onSortSelect($event)"
                        label="" >
                    </seech-select>
            </span>
        </div>

        <!-- <ng-container *ngIf="isLoading; else showPage">
            <div class="w-100 flex items-center justify-center mt-4">
              <seech-spinner [id]="'associates-spinner'" [color]="'secondary'">
              </seech-spinner>
            </div>
        </ng-container> -->

            <ng-container *ngFor="let review of reviews; let i = index;">
                <div class="mt-2">
                    <hr class="custom-hr" />
                </div>
                <div class="reviewer mb-2">
                    <div>
                        <div class="reviewer-inner-profile mt-3">
                            <span class="profile-pic mr-2" [appUserImage]="convertUserProfileToUser(review)">
                            </span>
                            <div class="info">
                                <div class="name-container">
                                    <p class="name mb-1">{{ review.reviewer.name | titlecase }}</p>
                                    <span *ngIf="review.badgeId" class="badgeName">{{ review.badgeName | titlecase }}</span>
                                </div>                            
                                <div class="rating-time-container">
                                    <app-profindar-star-rating [(ngModel)]="review.rating" [starCount]="5" [disabled]="true"></app-profindar-star-rating>
                                    <span class="review-time">{{review.createdOn | schTimeAgo}}</span>
                                </div>
                                <p class="text-review mt-2" [class.collapsed]="review.showFullText" #textReview>{{ review.comment }}</p>
                                <a *ngIf="review.isOverflowing" class="text-xs text-secondary" href="javascript:void(0)" (click)="toggleText(i)">{{ review.showFullText ? '...less' : '...more' }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div>
                <ng-container *ngIf="isempty">
                    <app-empty-state [title]="'Ratings not Found'" class="empty"
                        description="'No ratings available at this time'">
                    </app-empty-state>
                </ng-container>
            </div>
    </app-general-modal>
</section> 
