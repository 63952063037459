<ng-template #sharedContent id="pro-invitations">
  <div class="invite-header">
    <div class="search-container">
      <i [sch-icon]="'search'"></i>
      <input
        autocomplete="off"
        type="text"
        id="search-professionals-input"
        class="search-input"
        placeholder="Search professionals"
        [(ngModel)]="searchTerm"
        (ngModelChange)="!searchTerm && searchProfessionals()"
      />
    </div>

    <button
      (click)="searchProfessionals()"
      class="search-button"
      size="lg"
      sch-button
      fill="solid"
      [id]="'search-button'"
    >
      <div *ngIf="isSearching" class="button-loader mt-2">
        <seech-spinner [id]="'job-spinner'" [color]="'secondary'">
        </seech-spinner>
      </div>
      Search
    </button>
  </div>

  <div class="d-flex align-items-center flex-wrap mb-4 listings-page-dropdowns">
    <div class="border-r border-grey-100">
      <seech-dropdown
        class="!pl-0"
        [id]="'service'"
        [alignment]="'down'"
        [direction]="'down'"
        [split]="false"
        label="Services"
        [disabled]="false"
        [size]="'lg'"
        [color]="'transparent'"
      >
      </seech-dropdown>
    </div>

    <div class="border-r border-grey-100">
      <seech-dropdown
        [id]="'quality'"
        [alignment]="'down'"
        [direction]="'down'"
        [split]="false"
        label="Quality"
        [disabled]="false"
        [size]="'lg'"
        [color]="'transparent'"
      >
      </seech-dropdown>
    </div>

    <div class="border-r border-grey-100">
      <seech-dropdown
        [id]="'available'"
        [alignment]="'down'"
        [direction]="'down'"
        [split]="false"
        label="Availability"
        [disabled]="false"
        [size]="'lg'"
        [color]="'transparent'"
      >
      </seech-dropdown>
    </div>

    <div class="">
      <seech-dropdown
        [id]="'rate'"
        [alignment]="'down'"
        [direction]="'down'"
        [split]="false"
        label="Rate"
        [disabled]="false"
        [size]="'lg'"
        [color]="'transparent'"
      >
      </seech-dropdown>
    </div>

    <div role="button" class="flex items-center">
      <p class="sorts">Filter</p>
      <i class="!text-[22px] !font-[200]" [sch-icon]="'filter-outline'"></i>
    </div>

    <div role="button" class="flex items-center pl-2.5">
      <p class="sorts">Sort By</p>
      <i class="!text-[22px] !font-[200]" [sch-icon]="'vert-swap-arrow'"></i>
    </div>
  </div>

  <ng-container *ngIf="isFetchingProfessionals; else showProfessionals">
    <div class="h-[17rem] w-100 flex items-center justify-center">
      <seech-spinner [id]="'professional-spinner'" [color]="'secondary'">
      </seech-spinner>
    </div>
  </ng-container>

  <ng-template #showProfessionals>
    <div class="main">
      <div class="inner-main">
        <p class="main-title">
          Recommended Professionals ({{ professionals.length }})
        </p>

        <div
          class="main-body !h-[50rem] overflow-scroll"
          sch-scroll-end-notifier
          [offset]="10"
          (scrollEnd)="onScrollEnd()"
        >
          <ng-container *ngIf="!searchedValue && professionals.length < 1">
            <div class="d-flex justify-content-center">
              <app-empty-state
                [title]="'No Result Found'"
                [description]="'No professional found with a relevant skillset'"
                [showTextIcon]="true"
              >
              </app-empty-state>
            </div>
          </ng-container>

          <ng-container *ngIf="searchedValue && professionals.length < 1">
            <div class="d-flex justify-content-center">
              <app-empty-state
                [title]="'No Result Found'"
                [description]="'No professional matched your search criteria'"
                [showTextIcon]="true"
              >
              </app-empty-state>
            </div>
          </ng-container>

          <ng-container *ngFor="let item of professionals; let i = index">
            <div class="mb-[1.2rem]">
              <app-professional-summary-item
                height="8rem"
                [cardInfo]="item"
                [showCheckbox]="true"
              >
                <app-summary-item-checkbox
                  (checkedChange)="onProfessionalCheckClicked($event, item)"
                  checkbox-icon
                >
                </app-summary-item-checkbox>
              </app-professional-summary-item>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="inner-main">
        <p class="main-title">
          Invited Professionals ({{ selectedProfessionals.length }})
        </p>

        <div class="main-body !h-[50rem] overflow-scroll">
          <ng-container *ngIf="selectedProfessionals.length < 1">
            <div class="d-flex justify-content-center">
              <app-empty-state
                [title]="'No Invitation Found'"
                [description]="
                  'You have not invited anyone to bid on this project'
                "
                [showTextIcon]="true"
              >
              </app-empty-state>
            </div>
          </ng-container>

          <ng-container
            *ngFor="let newItem of selectedProfessionals; let i = index"
          >
            <div class="mb-[1.2rem]">
              <app-professional-summary-item
                height="8rem"
                [cardInfo]="newItem"
                [showCheckbox]="true"
              >
                <app-summary-item-checkbox
                  [checked]="isProfessionalChecked(newItem)"
                  (checkedChange)="onProfessionalCheckClicked($event, newItem)"
                  checkbox-icon
                >
                </app-summary-item-checkbox>
              </app-professional-summary-item>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-container *ngIf="independent; else nonModalContent">
  <app-general-modal padding="2px" label="Invite Professionals">
    <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
    <button
      (click)="submit()"
      class="bg-secondary h-[3.125rem] text-white text-center w-full mt-4 mb-1 rounded-3xl"
    >
      {{ btnText }}
    </button>
  </app-general-modal>
</ng-container>

<!-- Directly display the content when not using a modal -->
<ng-template #nonModalContent>
  <div *ngTemplateOutlet="sharedContent"></div>
</ng-template>
