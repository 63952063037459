import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PROJECT_STATUS, ProfessionalServiceResponse } from '@profindar/shared-ng';


@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss'],
})
export class ServiceItemComponent {
  @Input() data = {
    payrateTypeResponse: {
      name: ''
    },
    professionalService: {
      rate: 0,
      currency: '',
    }
  } as ProfessionalServiceResponse;
  @Input() width: string = '29.5rem';
  @Input() height: string = '10.5rem';
  @Input() active: boolean = false;

  get totalCompleted(): number {
    const totalProjectByStatus = this.data.professionalService.totalProjectByStatus;
    if (totalProjectByStatus)
      return totalProjectByStatus[PROJECT_STATUS.COMPLETED.NAME] ?? 0;
    return 0;
  }

  // pfColors = stylesVariables.colors;

  @Output() menuClicked: EventEmitter<void> = new EventEmitter<void>(); // Renamed from onMenuClicked

  onMenuClickedEvent(): void {
    this.menuClicked.emit(); // Use the renamed output property
  }

  get formattedRate(): { rate: number; currency: string; rateType: string }{
    const rate = this.data?.professionalService?.rate ?? 0;
    const currency = this.data?.professionalService?.currency ?? '';
    const rateType = this.data?.payrateTypeResponse?.name ?? '';

    return {
      rate,
      currency,
      rateType,
    };
  }
}
