/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Requirement } from '../model/models';
import { RequirementAnswerRequest } from '../model/models';
import { RequirementRequest } from '../model/models';
import { RequisitionRequirement } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RequirementServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param requirementRequest 
     */
    requirementAddRequirement(requirementRequest: RequirementRequest, extraHttpRequestParams?: any): Observable<Requirement>;

    /**
     * 
     * 
     * @param requirementAnswerRequest 
     */
    requirementAddRequirementAnswers(requirementAnswerRequest: RequirementAnswerRequest, extraHttpRequestParams?: any): Observable<Requirement>;

    /**
     * 
     * 
     * @param id 
     */
    requirementDeleteRequirement(id: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param id 
     */
    requirementGetRequirementById(id: string, extraHttpRequestParams?: any): Observable<Requirement>;

    /**
     * 
     * 
     * @param requisitionId 
     * @param professionalId 
     * @param clientId 
     */
    requirementGetRequisitionFufilledRequirements(requisitionId: string, professionalId: string, clientId: string, extraHttpRequestParams?: any): Observable<RequisitionRequirement>;

    /**
     * 
     * 
     * @param requisitionId 
     */
    requirementGetRequisitionRequirements(requisitionId: string, extraHttpRequestParams?: any): Observable<Array<Requirement>>;

    /**
     * 
     * 
     * @param requisitionId 
     * @param projectRole 
     */
    requirementGetRequisitionRequirementsByProjectRole(requisitionId: string, projectRole: string, extraHttpRequestParams?: any): Observable<Array<Requirement>>;

}
