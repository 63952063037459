import { Directive, ElementRef, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[appCheckStringOverflow]',
  standalone: true
})
export class CheckStringOverflowDirective {
  get element(): HTMLElement{
    return this.el.nativeElement as HTMLElement;
  }
  get textOverflow(): boolean{
    const nameDiv = this.element.querySelector('.name') as HTMLElement;
    if(nameDiv){
      return nameDiv.offsetWidth < nameDiv.scrollWidth;
    }
    return false;
  }


  constructor(private el: ElementRef, protected cdr: ChangeDetectorRef) {}

}
