/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

@NgModule({
  imports: [
    CommonModule, 
    StarRatingComponent
  ],
  declarations: [],
  exports: [StarRatingComponent],
})
export class ReviewModule {}
