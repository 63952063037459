import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges } from '@angular/core'; 
@Directive({
  selector: '[appSetColorByStatus]'
})

export class ColorbystatusDirective implements OnChanges {
  @Input('appSetColorByStatus') status: string | undefined;
  @Input() statusColors: { [key: string]: { NAME: string; COLOR: string } } = {};
 
  constructor(private el: ElementRef, private renderer: Renderer2) {}
 
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status'] || changes['statusColors']) {
      const color: string = this.getStatusColor(this.status);
      this.renderer.setStyle(this.el.nativeElement, 'color', color);
    }
  }
 
  private getStatusColor(status: string | undefined): string {
    let color = '#000'

    if (!status) {
      return color;
    }
    
    Object.values(this.statusColors).forEach(stat => {
      if (stat.NAME === status.toLowerCase()) {
        color = stat.COLOR;
      }
    });

    return color;
  }
}