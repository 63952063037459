<div class="menu bg-body">
  <ul class="list-group">
    <li class="list-group-item menu-header gap-2 d-flex list-group-item-action">
      <div class="d-flex justify-content-start align-items-center profile-avatar-wrap">
        <img *ngIf="user?.imageURL else nameLabelTemplate" [src]="user?.imageURL" width="50" height="auto"
          class="profile-avatar">
        <ng-template #nameLabelTemplate>
          <div *ngIf="user && !(!!user.imageURL)"
            class="name-label d-flex justify-content-center align-items-center profile-avatar">
            <p class="text-white">{{nameLabel}}</p>
          </div>
        </ng-template>
        <!-- TODO <span *ngIf="verified"><i sch-icon="checkmark"></i></span> -->
      </div>

      <div class="d-flex flex-column profile-heading">
        <p class="name">{{user?.name}}</p>
        <p *ngIf="services && user?.isProfessional" class="text-muted profession"> {{services[0]}}
          ({{services.length-1}} more)</p>
        <div class="mt-1">
          <app-profindar-star-rating [rate]="reviewInfo?.userReviewRating ?? 0"
            reviewCount="({{reviewInfo?.userReviewCount ?? 0}})" [disabled]="true"></app-profindar-star-rating>
        </div>
      </div>
    </li>

    <a [href]="accountWebURL" target="_blank" class="list-group-item list-group-item-action">
      <i [sch-icon]="'card'" class="menu-icon"></i> <span class="ms-2 menu-label">Seech account</span>
    </a>

    <a *ngFor="let item of menuItems" [routerLink]="[item.route]" (click)="menuItemClick.emit(item)"
      class="list-group-item list-group-item-action">
      <i [sch-icon]="item.icon!" class="menu-icon"></i> <span class="ms-2 menu-label">{{item.label}}</span>
    </a>

    <hr>
    <a (click)="logOut.emit()" class="list-group-item list-group-item-action text-danger">
      <i [sch-icon]="'logout'" class="menu-icon text-danger"></i> <span
        class="ms-2 menu-label text-danger">Logout</span>
    </a>
  </ul>
</div>