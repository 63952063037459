import { Pipe, PipeTransform } from '@angular/core';
import { User, UserSummary } from '../../models';

@Pipe({
  name: 'convertUserSummaryToUser',
  standalone: true,
})
export class ConvertUserSummaryToUserPipe implements PipeTransform {
  transform(value: UserSummary): User {
    return value as User;
  }
}
