/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { NotificationSetting } from '../model/models';
import { SettingCategory } from '../model/models';
import { Settings } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SettingsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param settings 
     */
    settingsCreateNotificationSetting(settings: NotificationSetting, extraHttpRequestParams?: any): Observable<NotificationSetting>;

    /**
     * 
     * 
     */
    settingsGetAllUserSettings(extraHttpRequestParams?: any): Observable<Array<SettingCategory>>;

    /**
     * 
     * 
     */
    settingsGetNotificationSettings(extraHttpRequestParams?: any): Observable<Array<NotificationSetting>>;

    /**
     * 
     * 
     * @param settingsName 
     * @param fieldName 
     */
    settingsGetNotificationSettingsByOption(settingsName: string, fieldName: string, extraHttpRequestParams?: any): Observable<Settings>;

    /**
     * 
     * 
     * @param settings 
     */
    settingsPatchNotificationSetting(settings: NotificationSetting, extraHttpRequestParams?: any): Observable<NotificationSetting>;

    /**
     * 
     * 
     * @param settings 
     */
    settingsUpsertNotificationSetting(settings: NotificationSetting, extraHttpRequestParams?: any): Observable<NotificationSetting>;

}
