import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {

  /**
   *
   */
  constructor(private router: Router) {
    this.router.navigate(['/login'], { queryParams: { action: 'logout'}});
  }
}
