import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageSubject = new BehaviorSubject<Record<string, any>>({});

  constructor() {
    this.loadInitialData();
  }

  private loadInitialData(): void {
    const data = {} as any;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) as string;
      if (key) data[key] = JSON.parse(localStorage.getItem(key) as string);
    }

    this.storageSubject.next(data);
  }

  private updateSubject(key: string, value: any): void {
    const currentData = this.storageSubject.value;
    const updatedData = { ...currentData, [key]: value };
    this.storageSubject.next(updatedData);
  }

  set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
    this.updateSubject(key, value);
  }

  /**
   * Async GET (subscription required)
   * @param key 
   * @returns 
   */
  get<T>(key: string): Observable<T> {
    return this.storageSubject.asObservable().pipe(map(data => data[key] as T));
  }

  /**
   * Synchronous GET (no subscription supported)
   * @param key 
   * @returns 
   */
  getItem<T>(key: string): T | null {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) as T : null;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
    this.updateSubject(key, null);
  }

  reset(): void {
    localStorage.clear();
    this.storageSubject.next({});
  }
}
