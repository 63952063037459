/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestedTaskRequest } from './requestedTaskRequest';
import { Coordinates } from './coordinates';
import { InvitedProfessionalRequest } from './invitedProfessionalRequest';
import { RequestedServiceRequest } from './requestedServiceRequest';


export interface RequisitionRequest { 
    referenceID?: string;
    description?: string;
    proposedStartDate?: Date;
    proposedEndDate?: Date;
    expirationDate?: Date;
    isRemote?: boolean;
    professionalLocation?: boolean;
    clientLocation?: boolean;
    isMobile?: boolean;
    privateBidding?: boolean;
    currency?: string;
    budget?: number;
    rateTypeId?: string;
    coordinates: Coordinates;
    invitedProfessionals?: Array<InvitedProfessionalRequest>;
    requestedServices: Array<RequestedServiceRequest>;
    requestedTasks: Array<RequestedTaskRequest>;
}

