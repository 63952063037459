import { HttpHeaders, HttpContext, HttpParams, HttpContextToken } from "@angular/common/http";

export interface HttpRequestOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'response' | 'events';
    context?: HttpContext;
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json' | 'blob';
    withCredentials?: boolean;
}

export interface SkipSettings {
    all?: boolean;
    progress?: boolean;
    headers?: boolean;
    url?: boolean;
    request?: boolean;
    response?: boolean;
    authentication?: boolean;
  }
  
  export const SKIP_OPTION = { 
    ALL: new HttpContextToken(() => false), 
    PROGRESS: new HttpContextToken(() => false), 
    HEADERS: new HttpContextToken(() => false), 
    URL: new HttpContextToken(() => false), 
    REQUEST: new HttpContextToken(() => false), 
    RESPONSE: new HttpContextToken(() => false), 
    AUTHENTICATION: new HttpContextToken(() => false), 
  };