<div
  [ngClass]="
  {
    'text-overflow-auto-height': autoHeight,
    'text-overflow-fixed-height': !autoHeight,
  }
"
  class="pf-task-summary w-full text-overflow-elipsis capitalize"
  [style.overflow-y]="overflowY"
>
  <span
    class="font-[400] w-full my-2 leading-3 text-[11px] lg:text-[12px]"
    *ngFor="let task of tasks; let i = index"
  >
    {{ task }}
    <!-- Only display the separator if this is not the last string -->
    <span
      *ngIf="i < tasks.length - 1 && i < displayLimit - 1"
      class="mx-1 font-light opacity-60"
      >|</span
    >
  </span>
  <!-- Display "See more" only if there are more than 10 strings -->
  <span
    [sch-tooltip]="displayTasks()"
    *ngIf="tasks.length > displayLimit"
    class="text-secondary-light lg:text-[12px] text-[11px] ml-1"
  >
    ... more ({{ tasks.length - displayLimit }})
  </span>
</div>
