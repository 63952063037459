import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-items-card-layout',
  templateUrl: './items-card-layout.component.html',
  styleUrls: ['./items-card-layout.component.scss']
})
export class ItemsCardLayoutComponent {
  @Input() imageWidth: string = '15%'; // default width
  @Input() textsWidth: string = '65%'; // default width
  @Input() actionsWidth: string = '25%'; // default width
}
