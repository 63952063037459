<div class="img-preview-overlay">
    <div class="close" (click)="closePreview.emit()">
        <i [sch-icon]="'cancel'"></i>
    </div>

    <div class="preview-img">
        <img [src]="mediaList[selectedMedia]" alt="">
    </div>

    <div class="caption-row">
        <div class="text-input-wrapper">
            <textarea class="text-input" 
            id="caption"
            placeholder="Add caption" 
            [(ngModel)]="messageBody"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="handleKeyUp($event)"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
        </div>

        <div class="send" (click)="sendMessage.emit(messageBody)">
            <i [sch-icon]="'send'"></i>
        </div>
    </div>

    <div class="img-tray-container">
        <div class="img" (click)="selectMore.emit()">
            <i [sch-icon]="'plus'"></i>
        </div>
        <div class="img-tray">
            <ng-container *ngFor="let img of mediaList; let i = index">
                <div class="img" (click)="selectedMedia = i">
                    <img [src]="img" alt="">
                    <div class="remove-icon" (click)="removeMedia(i)">
                        <i [sch-icon]="'close'"></i>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- <app-general-modal [hasHeader]="false" padding="0" #modalContainer>
    <div class="img-preview-overlay">
        <div class="close" (click)="closePreview.emit()">
            <i [sch-icon]="'cancel'"></i>
        </div>
    
        <div class="preview-img">
            <img [src]="mediaList[selectedMedia]" alt="">
        </div>
    
        <div class="caption-row">
            <div class="text-input-wrapper">
                <textarea class="text-input" 
                id="caption"
                placeholder="Add caption" 
                [(ngModel)]="messageBody"
                (keydown)="handleKeyUp($event)"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </div>
    
            <div class="send" (click)="sendMessage.emit(messageBody)">
                <i [sch-icon]="'send'"></i>
            </div>
        </div>

        <div class="img-tray-container">
            <div class="img" (click)="selectMore.emit()">
                <i [sch-icon]="'plus'"></i>
            </div>
            <div class="img-tray">
                <ng-container *ngFor="let img of mediaList; let i = index">
                    <div class="img" (click)="selectedMedia = i">
                        <img [src]="img" alt="">
                        <div class="remove-icon" (click)="removeMedia(i)">
                            <i [sch-icon]="'close'"></i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</app-general-modal> -->