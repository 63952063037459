import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutsideNotifier]',
  standalone: true
})
export class ClickOutsideNotifierDirective {
  @Output() outsideClick: EventEmitter<void> = new EventEmitter();

  @HostListener('document:click', ['$event']) 
  onDocumentClick(event: PointerEvent) {
    const nativeElement = this.elementRef.nativeElement as HTMLElement;
    const clickedInside = nativeElement.contains(<HTMLElement>event.target);
    if (!clickedInside) {
      this.outsideClick.emit();
    }
  }

  constructor(private elementRef: ElementRef) {}
}
