import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTANT, DashboardService, DATE_RANGE_LIST, Statuses, TRANSACTION_TYPE } from '@profindar/shared-ng';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy {
  dateRanges = DATE_RANGE_LIST;
  startDate!: Date;
  endDate!: Date;

  cdnBaseUrl = environment.cdnBaseUrl;
  statuses = Statuses;
  txnStatuses = TRANSACTION_TYPE;

  paymentDatasets!: any[];
  paymentLabels!: string[];
  paymentOptions!: any;

  invoiceBody: any[] = [];
  transactionBody: any[] = [];

  invoiceHeaders: string[] = [
    'Customer Name',
    'Order ID',
    'Amount',
    'Date',
    'Status',
    'Action',
  ];

  transactionHeaders: string[] = [
    'Description',
    'Date',
    'Transaction ID',
    'Amount',
    'Payment Status',
  ];

  checkboxValue!: boolean;

  private subscription = new Subscription();

  constructor(private dashboardService: DashboardService,
  ) {}

  ngOnInit(): void {
    this.initPaymentChart();
  }

  onDateRangeSelect(range: string): void {
    const { startDate, endDate } = this.dashboardService.getDateRange(range);
    this.startDate = startDate;
    this.endDate = endDate;
  }

  initPaymentChart() {
    this.paymentDatasets = [
      {
        label: '',
        data: [],
        borderColor: '#077D0B',
        pointBackgroundColor: '#077D0B',
      },
      {
        label: '',
        data: [],
        borderColor: ' #EF360D',
        pointBackgroundColor: ' #EF360D',
      },
    ];

    this.paymentLabels = CONSTANT.MONTHS.map((month) => month.abbr);

    this.paymentOptions = {
      plugins: {
        legend: {
          display: false,
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
