import { Injectable } from '@angular/core';
import {
  LookupService as LookupClient,
  ServiceRequest,
} from '../../../../generated';
import { Skill } from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private lookupClient: LookupClient) {}

  /**
   * Adds a service.
   * @param service The service to be added.
   * @returns {Observable<Service>} An Observable that emits a response indicating the success of the operation.
   */
  addService(service: ServiceRequest) {
    return this.lookupClient.lookupAddService(service);
  }

  /**
   * Adds a skill.
   * @param skill The skill to be added.
   * @returns {Observable<Skill>} An Observable that emits a response indicating the success of the operation.
   */
  addSkill(skill: Skill) {
    return this.lookupClient.lookupAddSkill(skill);
  }

  /**
   * Retrieves currencies based on provided parameters.
   * @param keyword The keyword to search for currencies.
   * @param page The page number of the currencies to retrieve.
   * @param size The size of the page.
   * @param requiredItems Additional required items.
   * @returns {Observable<{[key: string]: string;}>} An Observable that emits a response indicating the success of the operation.
   */
  getCurrencies(
    keyword?: string,
    page?: number,
    size?: number,
    requiredItems?: string
  ) {
    return this.lookupClient.lookupGetCurrencies(
      keyword,
      page,
      size,
      requiredItems
    );
  }

  /**
   * Retrieves countries and theri phone codes based on provided parameters.
   * @param keyword The keyword to search for currencies.
   * @param page The page number of the currencies to retrieve.
   * @param size The size of the page.
   * @param requiredItems Additional required items.
   * @returns {Observable<{[key: string]: string;}>} An Observable that emits a response indicating the success of the operation.
   */
  getCountriesWithPhoneCodes(
    keyword?: string,
    page?: number,
    size?: number,
    requiredItems?: string
  ) {
    return this.lookupClient.lookupGetCountriesWithPhoneCodes(
      keyword,
      page,
      size,
      requiredItems
    );
  }

  /**
   * Retrieves skills by service ID.
   * @param serviceId The ID of the service to retrieve skills for.
   * @param query The query string to filter skills.
   * @param page The page number of the skills to retrieve.
   * @param size The size of the page.
   * @returns {Observable<Skill[]>} An Observable that emits a response indicating the success of the operation.
   */
  getSkillsByServiceId(
    serviceId: string,
    query?: string,
    page?: number,
    size?: number
  ) {
    return this.lookupClient.lookupGetSkillsByServiceId(
      serviceId,
      query,
      page,
      size
    );
  }

  /**
   * Retrieves services based on provided parameters.
   * @param query The query string to filter services.
   * @param page The page number of the services to retrieve.
   * @param size The size of the page.
   * @returns {Observable<Service[]>} An Observable that emits a response indicating the success of the operation.
   */
  services(query?: string, page?: number, size?: number) {
    return this.lookupClient.lookupServices(query, page, size);
  }

  /**
   * Retrieves skills based on provided parameters.
   * @param query The query string to filter skills.
   * @param page The page number of the skills to retrieve.
   * @param size The size of the page.
   * @returns { Observable<Skill[]>} An Observable that emits a response indicating the success of the operation.
   */
  skills(query?: string, page?: number, size?: number) {
    return this.lookupClient.lookupSkills(query, page, size);
  }

  /**
   * Retrieves requisition status based on provided keyword.
   * @param keyword The keyword to search for requisition status.
   * @returns {Observable<Lookup[]>} An Observable that emits a response indicating the success of the operation.
   */
  getRequisitionStatus(keyword?: string) {
    return this.lookupClient.lookupGetRequisitionStatus(keyword);
  }

  /**
   * Retrieves badges based on provided keyword.
   * @param keyword The keyword to search for badges.
   * @returns {Observable<Badge[]>} An Observable that emits a response indicating the success of the operation.
   */
  getBadges(roleId: string, keyword?: string) {
    return this.lookupClient.lookupGetBadges(roleId, keyword);
  }

  /**
   * Retrieves pay rate types based on provided keyword.
   * @param keyword The keyword to search for pay rate types.
   * @returns {Observable<Lookup[]>} An Observable that emits a response indicating the success of the operation.
   */
  getPayRateTypes(keyword?: string) {
    return this.lookupClient.lookupGetPayRateTypes(keyword);
  }

  /**
   * Retrieves project status based on provided keyword.
   * @param keyword The keyword to search for project status.
   * @returns {Observable<Lookup[]>} An Observable that emits a response indicating the success of the operation.
   */
  getProjectStatus(keyword?: string) {
    return this.lookupClient.lookupGetProjectStatus(keyword);
  }

  /**
   * Retrieves task status based on provided parameters.
   * @param query The query string to filter task status.
   * @param page The page number of the task status to retrieve.
   * @param size The size of the page.
   * @returns {Observable<ProjectTaskStatus[]>} An Observable that emits a response indicating the success of the operation.
   */
  taskStatus(query?: string, page?: number, size?: number) {
    return this.lookupClient.lookupTaskStatus(query, page, size);
  }

  /**
   * Retrieves ratings based on provided parameters.
   * @param query The query string to filter ratings.
   * @param page The page number of the ratings to retrieve.
   * @param size The size of the page.
   * @returns {Observable<ReviewRating[]>} An Observable that emits a response indicating the success of the operation.
   */
  ratings(query?: string, page?: number, size?: number) {
    return this.lookupClient.lookupRatings(query, page, size);
  }

  /**
   * Retrieves bid status based on provided keyword.
   * @param {string} keyword - The keyword to search for bid status.
   * @returns {Observable<Lookup[]>} An Observable that emits a response indicating the success of the operation.
   */
  getBidStatus(keyword?: string) {
    return this.lookupClient.lookupGetBidStatus(keyword);
  }

  /**
   * Retrieves payment statuses.
   * @returns {Observable<Lookup[]>} An Observable that emits a response indicating the success of the operation.
   */
  getPaymentStatuses() {
    return this.lookupClient.lookupGetPaymentStatuses();
  }

  /**
   * @param currencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCurrencyByCurrencyCode(currencyCode: string) {
    return this.lookupClient.lookupGetCurrencyByCurrencyCode(currencyCode);
  }
}
