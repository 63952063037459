import { Injectable } from '@angular/core';
import { CalendarService as CalendarClient } from '../../../../generated';
import { Activity } from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private calendarClient: CalendarClient) {}

  /**
   * Adds a new project activity to the calendar.
   *
   * @param {Activity} activity The activity object to be added.
   * @returns {Observable<Activity>} An Observable that emits a FileResponse
   *   containing the response from the server, or null if there is an error.
   */
  addProjectActivity(activity: Activity) {
    return this.calendarClient.calendarAddProjectActivity(activity);
  }

  /**
   * Deletes a project activity from the calendar.
   *
   * @param {string} id The ID of the activity to be deleted.
   * @throws {Error}  If there is an error during the request.
   * @returns {Observable<Activity>} An Observable that emits a FileResponse
   *   containing the response from the server, or null if there is an error.
   */
  deleteProjectActivity(id: string) {
    return this.calendarClient.calendarDeleteProjectActivity(id);
  }

  /**
   * Fetches calendar projects for a given user and optional date range.
   *
   * @param {Date | undefined} [startDate=null] The optional start date for the filter.
   * @param {Date | undefined} [endDate=null] The optional end date for the filter.
   * @throws {Error}  If there is an error during the request.
   * @returns {Observable<Calendar[]>} An Observable that emits a FileResponse
   *   containing the response from the server, or null if there is an error.
   */
  getCurrentUserCalendarProjects(startDate?: Date, endDate?: Date) {
    // console.log(startDate);
    // console.log(endDate);
    return this.calendarClient.calendarGetCurrentUserCalendarProjects(
      startDate,
      endDate
    );
  }

  /**
   * Updates an existing project activity in the calendar.
   *
   * @param {Activity} activity The updated activity object.
   * @throws {Error}  If there is an error during the request.
   * @returns {Observable<Activity>} An Observable that emits a FileResponse
   *   containing the response from the server, or null if there is an error.
   */
  updateProjectActivity(activity: Activity) {
    return this.calendarClient.calendarUpdateProjectActivity(activity);
  }
}
