/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { SavedEntity } from '../model/models';
import { SavedEntityOfProfessionalSummary } from '../model/models';
import { SavedEntityOfRequisition } from '../model/models';
import { SavedEntityRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SavedServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param savedEntityRequest 
     */
    savedAddSavedEntity(savedEntityRequest: SavedEntityRequest, extraHttpRequestParams?: any): Observable<SavedEntity>;

    /**
     * 
     * 
     * @param savedEntityId 
     */
    savedDeleteSaveEntity(savedEntityId: string, extraHttpRequestParams?: any): Observable<SavedEntity>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    savedGetAllProfessionalSavedByMe(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<SavedEntityOfProfessionalSummary>>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    savedGetAllRequisitionSavedByMe(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<SavedEntityOfRequisition>>;

    /**
     * 
     * 
     * @param id 
     */
    savedGetProfessionalSavedByMe(id: string, extraHttpRequestParams?: any): Observable<SavedEntityOfProfessionalSummary>;

    /**
     * 
     * 
     * @param id 
     */
    savedGetRequisitionSavedByMe(id: string, extraHttpRequestParams?: any): Observable<SavedEntityOfRequisition>;

}
