import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Navigation } from '@seech/layout-ng';
import { environment } from '../../../environments/environment';

export interface MobileNavigation extends Navigation {
  action?: () => void;
  imageUrl?:string
}

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavComponent {
  @HostBinding('style.position')
  position = 'fixed';
  @HostBinding('style.left')
  left = '0';
  @HostBinding('style.right')
  right = '0';
  @HostBinding('style.bottom')
  bottom = '0';
  @Input({ required: true }) navigationItems!: MobileNavigation[];
  @Output() readonly itemClick: EventEmitter<MobileNavigation> =
    new EventEmitter<MobileNavigation>();
  cdnBaseUrl = environment.cdnBaseUrl;
}
