/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocalServiceToken { 
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    issued: Date;
    expires: Date;
    username: string;
}

