<div
  class="service-item-card capitalize"
  [ngStyle]="{
    width: width,
    height: height,
  }"
  [ngClass]="
    active
      ? 'border !border-secondary active-border'
      : 'boder !border-grey-100 inactive-border'
  "
  (click)="onMenuClickedEvent()"
>
  <div class=" lg:block">
    <div class="w-full lg:w-auto">
      <div class="service-item-header mb-1">
        <div class="flex justify-between items-center">
          <!-- <div class="sih-texts">
            <span class="font-[400] text-[1rem]">{{ data.serviceResponse.name }}</span>
          </div> -->
          <div class="sih-texts custom-overflow w-[45%] flex">
            <div class="w-full text-container">
              <span
                class="font-[500] text-base block lg:text-lg"
                >{{ data.serviceResponse.name}}</span
              >
            </div>
            <!-- <div class="flex items-end mx-1">
              <i
                *ngIf="data.services.length > 2"
                [sch-tooltip]="data.services.join(', ')"
                class="font-extrabold !text-[30px] h-[24px] horizontal-fill" [sch-icon]="'more-horizontal-fill'"></i>
            </div> -->
          </div>
          <div class="sih-price text-[.9rem]  w-[45%] font-medium flex items-center justify-end" >
            <span *ngIf="formattedRate.rate" class="mx-0 block text-container">
              {{formattedRate.rate | currency : formattedRate.currency : 'symbol' : '1.0-0'}}
              / {{ formattedRate.rateType || 'N/A' }}
            </span>

            <!-- <i
              class="!text-[1.6rem] !font-extrabold hidden lg:block"
              [sch-icon]="'more-vertical-fill'"
            >
            </i> -->
          </div>
        </div>
      </div>

      <div class="skills text-overflow-elipsis ">
        <span
          class="font-[400] w-full my-2 italic  text-[#97A2B0] text-[11px] lg:text-sm"
          *ngFor="let skill of data.skillResponse; let i = index"
        >
          {{ skill.name }}
          <span
            *ngIf="i !== data.skillResponse.length - 1"
            class="mx-1 font-light opacity-60"
            >|</span
          >
        </span>
      </div>
      <div class="service-item-texts-body mt-1 lg:mt-0">
        <p class="!font-light text-[12px] lg:text-[14.5px] text-[#48525F]">
          {{data.professionalService.description | slice : 0 : 115}} <span class="!text-secondary-light" *ngIf="data.professionalService?.description!.length > 115">... more</span>
        </p>
      </div>

      <div class="flex justify-between items-center  mt-1">
        <div
          class="service-item-sub-header w-[65%] hidden lg:flex items-center text-[0.75rem] mt-2 lg:my-0"
        >
          <i [sch-icon]="'location-outline'" class="text-lg"></i>
          <p class="mx-2 location-outline text-container text-[#2B3865CC]">
            {{ data.professionalService.location }}
          </p>
        </div>

        <div class="status-timeline text-[8px] lg:text-[11px]">
          <span> {{totalCompleted}} Jobs Completed</span>
        </div>
      </div>
    </div>
  </div>
</div>