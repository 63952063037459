/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-summary-item-checkbox',
  templateUrl: './summary-item-checkbox.component.html',
  styleUrls: ['./summary-item-checkbox.component.scss'],
})
export class SummaryItemCheckboxComponent {
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  toggleChecked(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
