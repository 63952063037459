import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Activity, CalendarService, ProfileService, Project } from '@profindar/shared-ng';
import { ToastService } from '@seech/ux-ng';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { SessionService } from '../../../../../services/session.service';
import { Guid } from 'typescript-guid';

@Component({
  selector: 'app-create-edit-event',
  templateUrl: './create-edit-event.component.html',
  styleUrls: ['./create-edit-event.component.scss'],
})
export class CreateEditEventComponent implements OnInit, OnDestroy{
  @ViewChild('modalContainer') modalContainer!: GeneralModalComponent;
  @Input() eventView: 'create' | 'view' | 'edit' = 'create';
  @Input() data?: Activity;
  @Input() projectId?: string;
  userId!: string;
  projects: Project[] = [];
  project: BehaviorSubject<Project> = new BehaviorSubject<Project>(<Project>{});
  activityForm!: FormGroup;
  sub: Subscription = new Subscription();
  formSubmitted = false;

  constructor(private calendarService: CalendarService,
              private fb: FormBuilder,
              private toastService: ToastService,
              private sessionService: SessionService,
              private profileService: ProfileService,){}

  ngOnInit(): void {
    this.sub.add(
      this.project.subscribe((project) => {
        this.activityForm.patchValue({
          clientName: project.clientUserSummary?.name,
          projectTitle: project.description,
          location: project.location,
        })
      })
    );
    this.userId = this.sessionService.getCurrentUserId()!;

    this.initForm();
    this.getProjects();
  }

  initForm(){
    this.activityForm = this.fb.group({
      id: [this.data?.id ?? Guid.EMPTY.toString(), Validators.required],
      projectId: [this.projectId ?? '', Validators.required],

      clientName: [''],
      projectTitle: [''],
      location: [''],

      description: [this.data?.description ?? ''],
      startDate: [this.data?.startDate ?? '', [Validators.required, this.projectRangeValidator()]],
      endDate: [this.data?.endDate ?? '', [Validators.required, this.projectRangeValidator()]],
    }, [this.startAndEndDateValidator()]);
  }

  projectRangeValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const project = this.project.getValue();
        if(project.id){
          const minDate = new Date(project.startedOn);
          const maxDate = new Date(project.proposedEndDate || project.startedOn);
          return (minDate.getTime() >=  new Date(control.value).getTime()) && 
          (new Date(control.value).getTime() <= maxDate.getTime()) ? null : {outOfProjectRange: true};
        }
        return null;
    }
  }

  startAndEndDateValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control as FormGroup;
      const startDate = group.controls['startDate'].value;
      const endDate = group.controls['endDate'].value;

      if(startDate && endDate){
        return new Date(startDate).getTime() > new Date(endDate).getTime() ? {endDateExceeded: true} : null;
      }
      else{
        return null;
      }
    }
  }

  getProjects(searchWord?: string){
    const query = searchWord;
    const page = searchWord ? 0 : undefined;
    const size = searchWord ? 20 : undefined;
    // this.sub.add(
    //   this.profileService.getUserProjects(this.userId, query, page, size).subscribe((res: Project[]) => {
    //     this.projects = res;
    //     if(this.projectId && this.projects && this.projects.length > 0 && !searchWord){
    //       try{
    //         const project = this.projects.find(x => x.id === this.projectId)!;
    //         this.project.next(project);
    //         this.activityForm.patchValue({
    //           projectId: project.id,
    //         });
    //       }
    //       catch(e){
    //         this.toastService.error(`couldn't find project`);
    //       }
    //     }
    //   })  
    // );
  }

  onSelectProject(project: Project){
    this.project.next(project);
    this.projectId = project.id;
  }

  formControlValidityCheck(controlName: string): boolean {
    const validity = (this.activityForm.get(controlName)?.invalid &&
      (this.activityForm.get(controlName)?.dirty ||
        this.activityForm.get(controlName)?.touched ||
        this.formSubmitted)) as boolean;

    return validity;
  }

  get startDateControlErrorMsg(): string{
    const control = this.activityForm.controls['startDate'];
    if(control.errors) return '';
    if(control.hasError('required')) return 'Please enter a start date';
    else if(control.hasError('outOfProjectRange')) return 'Date must be between project start and end date';
    else if(this.activityForm.hasError('endDateExceeded')) return 'Start date cannot be later than end date';
    return '';
  }


  onDelete(){
    this.calendarService.deleteProjectActivity(this.data!.id).subscribe((res) => {
      this.modalContainer.closeModal(res);
      this.toastService.success(`Activity deleted sucessfully`);
    })
  }

  addUpdateProjectActivity(){
    this.formSubmitted = true;
    if(this.activityForm.valid){
      const payload = this.activityForm.value as Activity;
      const endpoint = this.eventView === 'create' ? this.calendarService.addProjectActivity(payload)
      : this.calendarService.updateProjectActivity(payload);
  
      endpoint.subscribe((res) => {
        this.modalContainer.closeModal(res);
        const action = this.eventView === 'create' ? 'created' : 'updated';
        this.toastService.success(`Activity ${action} sucessfully`);
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
