/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpStatusCode } from './httpStatusCode';
import { Severity } from './severity';


export interface Diagnostic { 
    status: HttpStatusCode;
    userMessage: string;
    additionalRecepient: Array<string>;
    id: string;
    appId: string;
    appName?: string;
    caseId?: string;
    previousURL?: string;
    currentURL?: string;
    level: number;
    message?: string;
    innerException?: string;
    fullMessage?: string;
    diagnosticCode?: string;
    requestOrigin?: string;
    createdOn: Date;
    createdBy?: string;
    levelNavigation?: Severity;
}
export namespace Diagnostic {
}


