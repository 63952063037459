/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { Requirement } from './requirement';
import { InvitedProfessional } from './invitedProfessional';
import { Coordinates } from './coordinates';
import { PayRateType } from './payRateType';
import { UserSummary } from './userSummary';
import { RequestedService } from './requestedService';
import { RequisitionStatusHistory } from './requisitionStatusHistory';
import { Bid } from './bid';
import { RequisitionPaymentSchedule } from './requisitionPaymentSchedule';
import { RequestedTask } from './requestedTask';
import { RequisitionStatus } from './requisitionStatus';


export interface RequisitionOfRequisitionInterface { 
    coordinates: Coordinates;
    clientUserSummary?: UserSummary;
    professionalUserSummary?: UserSummary;
    bidsCount?: number;
    hasBid: boolean;
    isSaved: boolean;
    requisitionStatus: RequisitionStatus;
    payRateType: PayRateType;
    id: string;
    referenceID?: string;
    description?: string;
    statusId: string;
    statusInitiatedOn: Date;
    proposedStartDate?: Date;
    professionalId?: string;
    proposedEndDate?: Date;
    expirationDate?: Date;
    isRemote?: boolean;
    professionalLocation?: boolean;
    clientLocation?: boolean;
    isMobile?: boolean;
    privateBidding?: boolean;
    currency: string;
    budget?: number;
    rateTypeId: string;
    createdOn: Date;
    createdBy: string;
    geolocation?: Geometry;
    location?: string;
    isPaymentVerified?: boolean;
    bids: Array<Bid>;
    invitedProfessionals: Array<InvitedProfessional>;
    requestedServices: Array<RequestedService>;
    requestedTasks: Array<RequestedTask>;
    requirements: Array<Requirement>;
    requisitionPaymentSchedule?: RequisitionPaymentSchedule;
    requisitionStatusHistories: Array<RequisitionStatusHistory>;
    statusData?: object;
}

