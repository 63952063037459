import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  GlobalProgressBarService,
  RequisitionResponse,
  SavedService,
  User,
} from '@profindar/shared-ng';
import { ToastService } from '@seech/ux-ng';
import { SessionService } from '../../../../services/session.service';

@Component({
  selector: 'app-requisition-summary-item',
  templateUrl: './requisition-item.component.html',
  styleUrls: ['./requisition-item.component.scss'],
})
export class RequisitionItemComponent implements OnInit {
  @Input() width: string = '35.875rem';
  @Input() height: string = '100%';
  @Input() bgColors: string[] = ['#EBE1F9'];
  @Input() active = false;
  @Input() borderless = false;
  @Input() autoHeight = false;
  bgColor: string = '';
  services: string[] = [];
  isBookmarking: boolean = false;
  user: User | null = this.sessionService.getCurrentUser();

  @Input({ required: true }) data: RequisitionResponse =
    {} as RequisitionResponse;

  @Output() saveItem = new EventEmitter<string>();
  @Output() bid = new EventEmitter<void>();
  @Output() menuClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() cardClick: EventEmitter<void> = new EventEmitter<void>(); // Renamed from onCardClick

  constructor(
    private cdRef: ChangeDetectorRef,
    private savedServices: SavedService,
    private toastService: ToastService,
    private globalProgressService: GlobalProgressBarService,
    private sessionService: SessionService
  ) {
    this.sessionService
      .getCurrentUserAsync()
      .subscribe((user) => {
        this.user = user;
      });

    this.height = this.autoHeight ? 'auto' : this.height;
  }


  onBid(): void {
    this.bid.emit();
  }

  onMenuClicked(): void {
    this.menuClicked.emit();
  }

  onCardClicked(): void {
    this.cardClick.emit(); // Use the renamed output property
  }

  bookmark(item: RequisitionResponse) {
    if (this.isBookmarking) return;
    this.isBookmarking = true;
    this.globalProgressService.show();
    this.cdRef.detectChanges();

    const isCurrentlySaved = item.isSaved;
    const action = isCurrentlySaved
      ? this.savedServices.deleteSaveEntity(item.id)
      : this.savedServices.addSavedEntity({
        entityName: 'Requisition',
        entityId: item.id,
      });

    const successMessage = isCurrentlySaved
      ? 'Job has been removed from bookmark'
      : 'Job has been bookmarked';

    action.subscribe({
      next: () => {
        item.isSaved = !isCurrentlySaved;
        this.toastService.success(successMessage);
        this.saveItem.emit(item.id);
      },
      error: (error) => {
        this.toastService.error('Failed to update bookmark status');
      },
      complete: () => {
        this.isBookmarking = false;
        this.cdRef.detectChanges();
        this.globalProgressService.hide();
      },
    });
  }

  ngOnInit(): void {

  }
}
