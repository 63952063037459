<section id="requisition-modal">
  <app-general-modal label="Select Existing Job">
    <div class="close" (click)="closeModal()">
      <i [sch-icon]="'prev-arrow'" class="close-icon"> </i>
      <p class="close-text">Back</p>
    </div>

    <ng-container *ngIf="isFetchingRequisitions; else showRequisitions">
      <div class="grid grid-cols-1 gap-2 gap-lg-3">
        <seech-placeholder
          *ngFor="let i of noOfLoadingItems"
          [width]="'100%'"
          [height]="'7.7rem'"
          [shape]="'rect'"
        ></seech-placeholder>
      </div>
    </ng-container>

    <ng-template #showRequisitions>
      <section
        class="items-wrapper"
        sch-scroll-end-notifier
        [offset]="10"
        (scrollEnd)="onScrollEnd()"
      >
        <ng-container *ngFor="let item of requisitions">
          <div class="requi-item">
            <div class="requi-item-header">
              <div class="d-flex align-items-center">
                <div class="skills">
                  <p [sch-tooltip]="item.services.join(', ')">
                    {{ item.services[0] }}
                  </p>

                  <i class="more-icon" [sch-icon]="'more-horizontal-fill'"></i>
                </div>
                <p class="ref">({{ item.referenceId }})</p>
              </div>

              <p class="budget">
                {{ item.budget | currency : item.currency }}
              </p>
            </div>

            <div class="requi-item-body w-full h-7 md:h-8 max-h-7 md:max-h-8">
              <ng-container *ngFor="let task of item.tasks; let i = index">
                <span
                  class="font-[400] w-full my-2 leading-3 text-[11px] lg:text-[12px]"
                >
                  {{ task }}
                  <!-- Only display the separator if this is not the last string -->
                  <span
                    *ngIf="i < item.tasks.length - 1"
                    class="mx-1 font-light opacity-60"
                    >|</span
                  >
                </span>
              </ng-container>
            </div>

            <div class="requi-item-footer">
              <div class="w-[70%]">
                <p class="location">
                  {{ item.location }}
                </p>

                <p class="date">Posted {{ item.createdOn | schTimeAgo }}</p>
              </div>

              <button
                (click)="onclone(item)"
                sch-button
                fill="solid"
                class="clone-button"
              >
                Clone
              </button>
            </div>
          </div>
        </ng-container>
      </section>
    </ng-template>
  </app-general-modal>
</section>
