/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import { AddEditRequisitionComponent } from 'apps/profindar-web/src/app/components/add-edit-requisition/add-edit-requisition.component';
import { RequisitionModalComponent } from '../../../../../features/requisition/components/requisition-modal/requisition-modal.component';

@Component({
  selector: 'app-hire-modal',
  templateUrl: './hire-modal.component.html',
  styleUrls: ['./hire-modal.component.scss'],
})
export class HireModalComponent {
  @Input() professionalUserId: string = '';
  modalRef: any;
  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {
    this.modalService.modalRefComponent().subscribe({
      next: (data) => {
        if (!this.modalRef) {
          // console.log('data', data);
          this.modalRef = data;
        }
      }
    });
  }

  closeModal() {
    this.modalService.close(this.modalRef)
  }

  callMyRequisitionsModal() {
    this.closeModal();
    // setTimeout(() => {
      this.modalService.open(RequisitionModalComponent, {
        data: {
          professionalId: this.professionalUserId,
        },
        ignoreBackdropClick: false,
        modalClass: 'modal-dialog-centered',
      });
    // }, 250);
  }

  hireProfessional() {
    this.closeModal();
    // setTimeout(() => {
    this.modalService.open(AddEditRequisitionComponent, {
      data: {
        professionalId: this.professionalUserId,
      },
      ignoreBackdropClick: false,
      modalClass: 'modal-dialog-centered modal-lg',
    });
  // }, 250);
    
    this.cdr.detectChanges();
  }
}
