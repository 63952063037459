import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import { environment } from '../../../environments/environment';
import { CONSTANT } from '../../constants';
import { of, Subscription } from 'rxjs';
import { AddEditProServiceComponent } from '../../components/add-edit-pro-service/add-edit-pro-service.component';
import { AddEditRequisitionComponent } from '../../components/add-edit-requisition/add-edit-requisition.component';
import { LayoutData, LayoutService } from '../layout.service';
import { SideNavigation } from '../navigation';
import { Navigation, SideNavComponent } from '@seech/layout-ng';
import {
  MessageService,
  NotificationService,
  RevieweeInfo,
  ReviewService,
  User,
} from '@profindar/shared-ng';
import { SessionService } from '../../services/session.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalService } from '../portal.service';
import { MobileNavigation } from '../mobile-nav/mobile-nav.component';
import { bottomSheetAnimation } from '../animations';
import { Router } from '@angular/router';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [bottomSheetAnimation],
})
export class StandardLayoutComponent implements OnInit, AfterViewInit {
  private subscription = new Subscription();
  user: User | null = null;
  cdnBaseUrl = environment.cdnBaseUrl;
  firstSideNavItems = this.getFirstSideNavItems();
  secondSideNavItems = this.getSecondSideNavItems();
  displayIsMobile = true;
  menuItems = this.getMenuItems();
  showMenu = false;
  showCreateSheet = false;
  showFooter = true;
  unreadNotificationCount = 0;
  unreadMessagesCount = 0;
  nameLabel = '';
  revieweeInfo: RevieweeInfo | null = null;
  @ViewChild('sideNav', { static: true }) sideNav!: SideNavComponent;
  portalTemplate?: TemplatePortal;
  addEditProServiceLabel = this.setAddEditProServiceLabel();
  mobileNavigationItems = this.getMobileNavigationItems();
  layoutData?: LayoutData;
  accountWebURL = environment.ACCOUNT_WEB_URL;
  isLoading = false;
  showSideNav = false;

  get username(): string {
    return this.sessionService.getCurrentUsername()!;
  }

  dropDownMenuPosition: ConnectedPosition[] = [
    {
      originX: 'end', //Horizontal position should start at the end of origin element
      originY: 'bottom', //Vertical position should start at the bottom of origin element
      overlayX: 'start', //Menu should be placed at the start of horizontal position
      overlayY: 'top', //Menu should be placed at the start of vertical position
      offsetX: -300,
      offsetY: 10,
    },
  ];
  mobileDropDownMenuPosition = [
    {
      ...this.dropDownMenuPosition[0],
      offsetX: -170,
    },
  ];

  createSheetPosition: ConnectedPosition[] = [
    {
      originX: 'start', //Horizontal position should start at the start of origin element from left to right
      originY: 'top', //Vertical position should start at the top of origin element downwards
      overlayX: 'start', //Sheet should be placed at the start of horizontal position of the origin element
      overlayY: 'bottom', //Sheet should be placed at the bottom of vertical position of the origin element
      offsetX: 0, //Amount of sheet movement from origin element horizontal position from left to right
      offsetY: 0, //Amount of sheet movement from origin element vertical position downwards
    },
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private portalService: PortalService,
    private reviewService: ReviewService,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.layoutService.layoutData.subscribe((data) => {
      if (!this.deviceDisplayIsMobile()) {
        this.layoutData = data;
        this.sideNav.opened = data.showSideNav;
      }
    });
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  ngOnInit(): void {
    this.sideNav.openedChange.subscribe((isOpen) => {
      if (!this.deviceDisplayIsMobile()) {
        this.showSideNav = isOpen;
        this.cdRef.detectChanges();
      }
    });

    this.getUserAsync();
    this.getUnreadMessagesCount();
    this.getUnreadNotificationsCount();
    this.getRevieweeInfo();
    this.portalService.portalTemplate.subscribe((portal) => {
      this.portalTemplate = portal;
      this.cdRef.detectChanges();
    });

    if (this.user) {
      const names = this.user.name.split(' ');
      this.nameLabel = names.map((name) => name[0]).join('');
    }

    const subscription = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state) => {
        this.displayIsMobile = state.matches;
        this.showMenu = false;
        if (this.displayIsMobile && this.layoutData) {
          this.layoutData = {
            ...this.layoutData,
            showFooter: false,
          };
        }
        this.cdRef.detectChanges();
      });

    this.subscription.add(subscription);
  }

  setAddEditProServiceLabel(): string {
    const user = this.sessionService.getCurrentUser();
    return user?.isProfessional ? 'Add New Service' : 'Become a Pro';
  }

  getRevieweeInfo() {
    this.reviewService.getRevieweeSummary().subscribe((revieweeInfo) => {
      this.revieweeInfo = revieweeInfo;
    });
  }

  getUserAsync() {
    const subscription = this.sessionService
      .getCurrentUserAsync()
      .subscribe((user) => {
        this.user = user;
      });
    this.subscription.add(subscription);
  }

  getUnreadMessagesCount() {
    this.messageService.getUserUnReadMessages().subscribe((count) => {
      this.unreadMessagesCount = count;
    });
  }

  getUnreadNotificationsCount() {
    this.notificationService
      .getNotificationCount(CONSTANT.API.NOTIFICATION_FILTER.UNREAD)
      .subscribe((count) => {
        this.unreadNotificationCount = count;
      });
  }

  getMobileNavigationItems(): MobileNavigation[] {
    return [
      {
        icon: 'home',
        label: 'Home',
        route: CONSTANT.ROUTE.HOME,
      },
      {
        icon: 'search',
        label: 'Search',
        action: () => {},
        route: '',
      },
      {
        imageUrl: this.cdnBaseUrl + 'images/add-circle-dark.svg',
        label: 'Create',
        action: () => {
          this.showCreateSheet = !this.showCreateSheet;
        },
        route: '',
      },
      {
        icon: 'person',
        label: 'Profile',
        route: `${CONSTANT.ROUTE.PROFILE}/${this.username}`,
      },
      {
        icon: 'menu',
        label: 'Menu',
        action: () => this.sideNav.open(),
        route: '',
      },
    ];
  }

  getMenuItems(): Navigation[] {
    return [
      {
        icon: 'person',
        label: 'Profile',
        route: `${CONSTANT.ROUTE.PROFILE}/${this.username}`,
      },
      {
        icon: 'bid-outline',
        label: 'My Bids',
        route: CONSTANT.ROUTE.BIDS,
      },
      {
        icon: 'add-mail',
        label: 'Job Invitations',
        route: CONSTANT.ROUTE.JOB_INVITATIONS,
      },
      {
        icon: 'calendar',
        label: 'Calendar',
        route: CONSTANT.ROUTE.CALENDAR,
      },
      {
        icon: 'bookmark',
        label: 'Saved Items',
        route: CONSTANT.ROUTE.BOOKMARKS,
      },
      {
        icon: 'settings',
        label: 'Settings',
        route: CONSTANT.ROUTE.SETTINGS,
      },
    ];
  }

  getFirstSideNavItems(): SideNavigation[] {
    return [
      {
        icon: 'home',
        label: 'Home',
        route: CONSTANT.ROUTE.HOME,
      },
      {
        icon: 'stacks',
        label: 'Jobs',
        route: `${CONSTANT.ROUTE.JOB}/list`,
      },
      {
        icon: 'product',
        label: 'Products',
        route: CONSTANT.ROUTE.PRODUCTS,
      },
      {
        icon: 'professional',
        label: 'Professionals',
        route: `${CONSTANT.ROUTE.PRO}/list`,
      },
    ];
  }

  getSecondSideNavItems(): SideNavigation[] {
    return [
      {
        icon: 'dashboard-rounded',
        label: 'Dashboard',
        route: CONSTANT.ROUTE.DASHBOARD,
      },
      {
        imageUrl: this.cdnBaseUrl + 'images/r-circle.svg',
        label: 'Requisitions',
        route: `${CONSTANT.ROUTE.REQ}`,
      },
      {
        icon: 'briefcase',
        label: 'Projects',
        route: CONSTANT.ROUTE.PROJECTS,
      },
      {
        icon: 'person',
        label: 'Associates',
        route: CONSTANT.ROUTE.ASSOCIATES,
      },
      {
        icon: 'wallet',
        label: 'Payments',
        route: CONSTANT.ROUTE.PAYMENTS,
      },
    ];
  }

  routeToBecomePro() {
    // this.router.navigate(['/home/become-apro'])
    this.modalService.open(AddEditProServiceComponent, {
      ignoreBackdropClick: false,
      modalClass: 'modal-dialog-centered',
    });
  }

  haandleSideNavChange(e: Navigation) {
    if (e.label === this.setAddEditProServiceLabel()) {
      this.routeToBecomePro();
    }
  }

  createRequisitionModal() {
    this.modalService.open(AddEditRequisitionComponent, {
      ignoreBackdropClick: false,
      modalClass: 'modal-dialog-centered modal-lg',
    });
  }

  deviceDisplayIsMobile() {
    return this.breakpointObserver.isMatched(['(max-width: 768px)']);
  }
}
