<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="left d-flex justify-content-start align-items-center">
          <span>
            <span class="pro text-secondary">Pro</span> <span class="findar text-white">Findar</span>
          </span> <span class="by-seech text-white">by SEECH</span> <span class="copy">&copy;</span> <span
            class="year">{{year}}</span>
        </div>
      </div>
      <div class="col-6">
        <div class="right text-white d-flex justify-content-end align-items-center">
          <a [routerLink]="[route.ROOT, route.HOME]">Legal</a> <span class="pipe">|</span> <a
            [routerLink]="[route.ROOT, route.HOME]">How it works</a> <span class="pipe">|</span>
          <a [routerLink]="[route.ROOT, route.HOME]">Feedback</a>
        </div>
      </div>
    </div>
  </div>
</footer>