import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Bid } from '@profindar/shared-ng';
import { ControlsNgModule, Currency } from '@seech/controls-ng';
import { TextFieldModule } from '@angular/cdk/text-field';

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value) {
      const currentDate = new Date();
      const selectedDate = new Date(control.value);

      // Ignore time part of the date for comparison
      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);
      return selectedDate < currentDate ? { pastDate: true } : null;
    }
    return null;
  };
}

export function dateRangeValidator(
  startDateControlName: string,
  endDateControlName: string,
  expiryDateControlName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const startDateControl = formGroup.get(startDateControlName);
    const endDateControl = formGroup.get(endDateControlName);
    // const expiryDateControl = formGroup.get(expiryDateControlName);

    if (!startDateControl) {
      return null;
    }

    const startDate = new Date(startDateControl.value);
    startDate.setHours(0, 0, 0, 0);

    const errors: ValidationErrors = {};

    if (endDateControl && endDateControl.value) {
      const endDate = new Date(endDateControl.value);
      endDate.setHours(0, 0, 0, 0);

      if (endDate < startDate) {
        errors['endDateInvalid'] = true;
      }
    }

    /*if (expiryDateControl && expiryDateControl.value) {
      const expiryDate = new Date(expiryDateControl.value);
      expiryDate.setHours(0, 0, 0, 0);

      if (expiryDate < startDate) {
        errors['expiryDateInvalid'] = true;
      }
    }*/

    if (Object.keys(errors).length === 0) {
      return null;
    }

    return errors;
  };
}

@Component({
  selector: 'app-bid-form',
  standalone: true,
  exportAs: 'bidForm',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlsNgModule,
    TextFieldModule,
  ],
  templateUrl: './bid-form.component.html',
  styleUrls: ['./bid-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
})
export class BidFormComponent implements OnInit, AfterViewInit {
  form!: FormGroup;
  @Input() type: 'professional' | 'client' = 'client';
  @Input({ required: true }) bid!: Bid;
  @Input({ required: true }) currency!:Currency;
  @Input({ required: true }) payRateType?: string;
  @Input() readonly = false;
  @Output() messageFieldHeightChange = new EventEmitter<any>();
  @Input() showMore = false;
  @Input({ required: true }) userIsClient = false;
  validateFormControls = false;
  @ViewChild('messageField') messageField?: ElementRef<HTMLTextAreaElement>;
  defaultMessageFieldHeightInPx = 46;
  messageFieldHeightInPx = this.defaultMessageFieldHeightInPx;

  amount = new FormControl<string | undefined>(undefined, [
    Validators.required,
    Validators.minLength(1),
  ]);
  startDate = new FormControl<string | undefined>(undefined, [
    Validators.required,
    futureDateValidator(),
  ]);
  endDate = new FormControl<string | undefined>(undefined);
  expiresOn = new FormControl<string | undefined>(undefined);
  message = new FormControl<string | undefined>(undefined);

  constructor(
    private currencyPipe: CurrencyPipe,
    private cdRef: ChangeDetectorRef
  ) {}

  validateForm() {
    this.validateFormControls = true;
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.updateForm(this.bid);
  }

  ngAfterViewInit(): void {
    this.message.valueChanges.subscribe((value) => {
      this.messageFieldHeightChange.emit(
        this.messageField!.nativeElement.offsetHeight
      );
    });
  }

  updateMessageFieldHeight(heightInPx: number) {
    this.messageFieldHeightInPx = heightInPx;
    this.cdRef.detectChanges();
  }

  resetMessageFieldHeightToDefault() {
    this.updateMessageFieldHeight(this.defaultMessageFieldHeightInPx);
  }


  updateForm(bid: Bid) {
    this.amount.setValue(bid.amount?.toString());
    this.startDate.setValue(
      bid.startDate ? new Date(bid.startDate!).toDateString() : undefined
    );
    this.endDate.setValue(
      bid.endDate ? new Date(bid.endDate!).toDateString() : undefined
    );
    this.expiresOn.setValue(
      bid.expiresOn ? new Date(bid.expiresOn!).toDateString() : undefined
    );
    this.message.setValue(bid.message);
  }

  initializeForm() {
    this.form = new FormGroup(
      {
        amount: this.amount,
        startDate: this.startDate,
        endDate: this.endDate,
        expiresOn: this.expiresOn,
        message: this.message,
      },
      {
        validators: dateRangeValidator('startDate', 'endDate', 'expiresOn'),
      }
    );
  }

  public get classes(): string {
    const defaultClass = 'form-control p-2';
    const strikeThroughClass = `text-decoration-line-through ${defaultClass}`;

    if (this.userIsClient && this.type === 'professional') {
      return strikeThroughClass;
    }

    if (!this.userIsClient && this.type === 'client') {
      return strikeThroughClass;
    }
    return defaultClass;
  }
}
