<ng-template #sharedContent>
  <div class="details overflow-y-scroll">
    <div
      class="details-first sticky top-0 z-50 py-4 bg-white-100 lg:!flex-nowrap flex-wrap items-start mx-3"
    >
      <div class="" *ngIf="!isFetchingReqServices">
        <div class="d-flex align-items-center justify-content-center w-fit">
          <p
            [sch-tooltip]="services.join(', ')"
            class="job !text-lg text-overflow-elipsis text-overflow-auto-height"
          >
            {{ services.join(', ') }}
          </p>
          <div class="flex items-end mx-1">
            <!-- <i
              [sch-tooltip]="services.join(', ')"
              class="font-extrabold !text-[30px] h-[24px] horizontal-fill"
              [sch-icon]="'more-horizontal-fill'"
            ></i> -->
          </div>
        </div>

        <p class="price">
          {{
            requisitionItem.budget
              | currency : requisitionItem.currency : 'symbol' : '1.0-0'
          }}
        </p>
      </div>

      <div *ngIf="isFetchingReqServices">
        <seech-placeholder
          [width]="'8.125rem'"
          [height]="'3.75rem'"
          [shape]="'rect'"
        ></seech-placeholder>
      </div>

      <div *ngIf="!isFetchingReqServices" class="flex flex-col items-center">
        <div class="flex align-stretch">
          <button
            [disabled]="isBookMarking"
            (click)="bookmark()"
            class="button-save bg-[#EBF7EE] !text-secondary h-9 !w-9"
          >
            <span
              [sch-icon]="
                requisitionItem.isSaved ? 'bookmark-fill' : 'bookmark'
              "
              class="me-0"
            ></span>
          </button>
          <button
            *ngIf="requisitionItem.createdBy !== loggedInUser?.id"
            [ngClass]="{
              'has-bid': !requisitionItem.hasBid,
              'withdraw-bid': requisitionItem.hasBid
            }"
            (click)="openBidDialog(requisitionItem)"
            class="button-hire flex items-center justify-center text-center"
          >
            <span>
              <span
                [sch-icon]="'bid-outline'"
                class="mr-2 text-lg"
              ></span></span
            ><span class="">
              {{ !requisitionItem.hasBid ? 'Bid' : 'View Bid' }}
            </span>
          </button>
        </div>
      </div>

      <div *ngIf="isFetchingReqServices" class="flex flex-col items-center">
        <seech-placeholder
          [width]="'8.125rem'"
          [height]="'3.75rem'"
          [shape]="'rect'"
        ></seech-placeholder>
      </div>
    </div>

    <div class="details-body">
      <div class="details-second mx-3">
        <p class="job-details !font-normal">Job details</p>
      </div>

      <div class="details-third !font-normal mx-3">
        <div class="item">
          <p class="item-title my-2">Budget</p>
          <div *ngIf="requisitionItem.budget" class="item-name">
            {{
              requisitionItem.budget || 0
                | currency : requisitionItem.currency || '' : 'symbol' : '1.0-0'
            }}
            / {{ requisitionItem.payRateType || 'N/A' }}
          </div>

          <div *ngIf="isFetchingReqServices">
            <seech-placeholder
              [width]="'10rem'"
              [height]="'1.25rem'"
              [shape]="'rect'"
            ></seech-placeholder>
          </div>
        </div>
        <div class="item">
          <p class="item-title">Tasks</p>
          <div *ngIf="!isFetchingReqServices" class="my-0">
            <div class="pf-task-summary w-full capitalize">
              <!-- Container for tasks with limited height -->
              <div
                #taskDiv
                [ngClass]="{
                  'h-[55px] overflow-y-hidden': !showAllTasks,
                  'h-fit overflow-y-visible': showAllTasks
                }"
              >
                <ng-container
                  *ngFor="let task of requisitionItem.tasks; let i = index"
                >
                  <span
                    class="font-[400] w-full my-2 leading-3 text-[11px] lg:text-[12px]"
                  >
                    {{ task }}
                  </span>
                  <span
                    *ngIf="i < requisitionItem.tasks.length - 1"
                    class="mx-1 font-light opacity-60"
                    >|</span
                  >
                </ng-container>
              </div>

              <!-- "See more" button placed outside the overflow-hidden container -->
              <div
                *ngIf="showMoreButton"
                class="text-xs text-secondary font-medium cursor-pointer lg:mt-0 mt-2"
                (click)="toggleSeeMore()"
              >
                {{ showAllTasks ? '...See less' : '...See more' }}
              </div>
            </div>
          </div>

          <div *ngIf="isFetchingReqServices">
            <seech-placeholder
              [width]="'100%'"
              [height]="'3.25rem'"
              [shape]="'rect'"
            ></seech-placeholder>
          </div>
        </div>
      </div>

      <div class="details-fourth details-third mx-3">
        <div class="item !mt-0 !mb-3">
          <p class="item-title">Service Delivery</p>
          <div
            *ngIf="requisitionItem.professionalLocation && !isFetchingReqServices"
            class="item-name"
          >
           Professional Location
          </div>
          <div
          *ngIf="requisitionItem.clientLocation && !isFetchingReqServices"
          class="item-name"
          >
          Client Location
          </div>
          <div
            *ngIf="requisitionItem.isRemote && !isFetchingReqServices"
            class="item-name"
          >
            Remote
          </div>
          <div
            *ngIf="requisitionItem.isMobile && !isFetchingReqServices"
            class="item-name"
          >
            Mobile
          </div>

          <div *ngIf="isFetchingReqServices">
            <seech-placeholder
              [width]="'10rem'"
              [height]="'1.25rem'"
              [shape]="'rect'"
            ></seech-placeholder>
          </div>
        </div>

        <p class="title">Location</p>
        <p *ngIf="!isFetchingReqServices" class="address">
          <span [sch-icon]="'location-outline'"></span>
          {{ requisitionItem.location }}.
        </p>
        <div *ngIf="isFetchingReqServices">
          <seech-placeholder
            [width]="'10rem'"
            [height]="'1.25rem'"
            [shape]="'rect'"
          ></seech-placeholder>
        </div>
      </div>

      <div class="details-fifth mx-3">
        <p class="title">Description</p>
        <p *ngIf="!isFetchingReqServices" class="desc">
          {{ requisitionItem.description }}
        </p>

        <div *ngIf="isFetchingReqServices">
          <seech-placeholder
            [width]="'100%'"
            [height]="'3.25rem'"
            [shape]="'rect'"
          ></seech-placeholder>
        </div>
      </div>

      <div class="details-sixth mx-3">
        <div class="flex justify-between">
          <div class="gallery-title">
            <p class="font-medium text-lg">Gallery</p>
          </div>
          <div class="count">
            <p class="text-secondary">(0)</p>
          </div>
        </div>

        <div class="gallery-image-items flex items-center mt-3">
          <!-- <div
            *ngFor="let number of [].constructor(4); let i = index"
            class="img-item w-[80%] text-center mx-2 shadow-sm"
          >
            <div class="g-img h-16">
              <img
                class="h-full object-cover"
                src="{{ cdnBaseUrl }}temp/gallery-item1.png"
                alt="images"
              />
            </div>
            <div class="img-title text-[11px] font-medium p-2">
              <p>Plumbing</p>
            </div>
            <div class="img-action-btn text-[10px] px-2 pb-2">
              <button class="bg-secondary w-full rounded-full text-white-100">
                View
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="isMobile; else nonModalContent">
  <app-general-modal padding="2px" label="">
    <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
  </app-general-modal>
</ng-container>

<!-- Directly display the content when not using a modal -->
<ng-template #nonModalContent>
  <div *ngTemplateOutlet="sharedContent"></div>
</ng-template>
