/* eslint-disable @nx/enforce-module-boundaries */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { User, UserProfileResponse, UserService } from '@profindar/shared-ng';
import { SessionService } from '../../../../services/session.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService, UxNgModule } from '@seech/ux-ng';
import { HireModalComponent } from '../../detail/components/professional-summary-detail/hire-modal/hire-modal.component';
import { ReviewModule } from '../../review/review.module';
import { ComponentsModule } from '../components.module';
import { IconsNgModule } from '@seech/icons-ng';
import { DirectivesModule } from 'apps/profindar-web/src/app/directives';
import { OverallRatingsViewComponent } from '../../review/components/ratings/overall-ratings-view/overall-ratings-view.component';

@Component({
  standalone: true,
  selector: 'app-bio-summary',
  templateUrl: './bio-summary.component.html',
  styleUrls: ['./bio-summary.component.scss'],
  imports: [
    UxNgModule,
    ReviewModule,
    ComponentsModule,
    IconsNgModule,
    DirectivesModule,
  ],
})

export class BioSummaryComponent
  implements OnChanges, AfterViewInit, OnDestroy {
  cdnBaseUrl = environment.cdnBaseUrl;

  @Input() buttonLabel = 'Profile';
  @Input() buttonRoute!: string;
  @Input() username!: string;
  @Input() isProfile = false;
  @Output() viewProfile: EventEmitter<'view' | 'edit'> = new EventEmitter();
  @Output() profileEvent: EventEmitter<UserProfileResponse> =
    new EventEmitter();

  profile?: UserProfileResponse;

  private subscription = new Subscription();

  get badgeCount(): number {
    const displayIsMobile = this.breakpointObserver.isMatched(
      Breakpoints.XSmall
    );
    return displayIsMobile ? 3 : 4;
  }

  constructor(
    private sessionService: SessionService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private router: Router,
    private modalService: ModalService
  ) { }

  ngAfterViewInit(): void {
    if (!this.username) {
      this.username = this.sessionService.getCurrentUsername()!;
      this.buttonRoute = `/profile/${this.username}`;
      this.getUserProfile();
    }
    if (this.isOwner) {
      this.sessionService.getCurrentUserAsync().subscribe((user: User) => {
        this.username = user.username;
        this.getUserProfile();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const username = changes['username'];
    if (username && username.currentValue) {
      this.getUserProfile();
    }
  }

  getUserProfile() {
    this.profile = undefined;
    this.subscription.add(
      this.userService.getUserProfileByUsername(this.username).subscribe((res: UserProfileResponse) => {
          this.profile = res;
          this.profile.badgesSummary = res.badgesSummary.filter(badge => badge.trim() !== ''); //handles empty string
          this.profileEvent.emit(this.profile);
          this.cdr.detectChanges();
        })
    );
  }

  checkForOverflow(property: string, maxLength: number): boolean {
    const list = property.split(', ');
    return list.length > maxLength;
  }

  convertUserProfileToUser(userProfile: UserProfileResponse): User {
    const user: User = <User>{};
    user.name = userProfile.name;
    user.username = userProfile.username;
    user.imageURL = userProfile.imageUrl;
    return user;
  }

  get isOwner(): boolean {
    return this.username === this.sessionService.getCurrentUsername();
  }

  navigateToRoute() {
    this.isOwner
      ? this.router.navigate([`${this.buttonRoute}`])
      : this.hirePro();
  }

  hirePro() {
    this.modalService.open(HireModalComponent, {
      data: {
        professionalUserId: this.profile!.id,
      },
      modalClass: 'modal-dialog-centered',
    });
  }

  viewOverallReview() {
    this.modalService.open(OverallRatingsViewComponent, {
      data: {
        name: this.profile!.name,
        reviewRating: this.profile!.reviewScore,
        numberOfReviews: this.profile!.reviewCount,
        revieweeId: this.profile!.id,
        roleId: this.profile!.roleId
      },
      modalClass: 'modal-dialog-centered',
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
