<nav class="side-nav-items">
  <ul class="d-flex flex-column gap-4">
    <li *ngFor="let navigation of navigationItems" class="d-flex gap-2 align-items-center">
      <img class="svg-icon" *ngIf="navigation.imageUrl; else icon" [src]="navigation.imageUrl" width="20px" height="20px">
      <ng-template #icon>
        <i [sch-icon]="navigation.icon!" [sch-badge]="navigation.showBadge === true"> </i>
      </ng-template>
      <a (click)="selectedChanged.emit(navigation)"
        [routerLink]="navigation.route">{{navigation.label}}</a>
    </li>
  </ul>
</nav>
