/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { PaymentInstallment } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface PaymentInstallmentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param paymentInstallments 
     */
    paymentInstallmentApproveAllPaymentInstallment(paymentInstallments: Array<PaymentInstallment>, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param paymentInstallmentId 
     * @param isApprove 
     */
    paymentInstallmentApprovePaymentInstallment(paymentInstallmentId: string, isApprove: boolean, extraHttpRequestParams?: any): Observable<PaymentInstallment>;

    /**
     * 
     * 
     * @param paymentInstallmentId 
     */
    paymentInstallmentApprovePaymentInstallmentAll(paymentInstallmentId: string, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param paymentInstallmentId 
     */
    paymentInstallmentCancelPaymentApproval(paymentInstallmentId: string, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param paymentInstallmentId 
     */
    paymentInstallmentCancelPaymentRequest(paymentInstallmentId: string, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    paymentInstallmentGetPaymentInstallment(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param projectId 
     * @param page 
     * @param size 
     */
    paymentInstallmentGetPaymentInstallmentByProjectId(projectId: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param projectId 
     * @param page 
     * @param size 
     */
    paymentInstallmentGetPaymentInstallmentHistoryByProjectId(projectId: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param paymentInstallmentId 
     */
    paymentInstallmentRequestPayment(paymentInstallmentId: string, extraHttpRequestParams?: any): Observable<PaymentInstallment>;

    /**
     * 
     * 
     * @param paymentInstallmentId 
     */
    paymentInstallmentRequestPaymentInstallment(paymentInstallmentId: string, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

    /**
     * 
     * 
     * @param paymentInstallments 
     */
    paymentInstallmentRequestScheduleById(paymentInstallments: Array<PaymentInstallment>, extraHttpRequestParams?: any): Observable<Array<PaymentInstallment>>;

}
