import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { HttpRequestOptions, SKIP_OPTION, SignupRequest, User } from '../../../../models';
// import * as Generated from '../../../../generated/api-client';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  // private accountClient: Generated.AccountClient;

  constructor(private http: HttpClient) {
    // this.accountClient = new Generated.AccountClient(http, '');
  }

  /**
   * Initiates a login request.
   * @returns An Observable that emits a response indicating the success of the login operation.
   */
  // login() {
  //   return this.accountClient.login();
  // }
  login(option: HttpRequestOptions) {
    const context = (option.context ?? new HttpContext()).set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
    return this.http.get<User>('/api/Account/login', { headers: option.headers, context, observe: 'response' });
  }

  /**
   * Initiates a signup request.
   * @param SignupRequest The user information to be used for signup.
   * @returns An Observable that emits a response indicating the success of the signup operation.
   */
  // signup(signupRequest: SignupRequest) {
  //   return this.accountClient.signup(new Generated.SignupRequest(signupRequest));
  // }

  signup(body: SignupRequest, option: HttpRequestOptions) {
    const context = (option.context ?? new HttpContext()).set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
    return this.http.post<string>('/api/Account/signup', body, { headers: option.headers, context, observe: 'response' });
  }
}
