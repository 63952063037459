/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { LocalNotificationMessage } from '../model/models';
import { Notification } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface NotificationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param page 
     * @param size 
     * @param filterBy 
     * @param searchQuery 
     */
    notificationGetNotification(page?: number, size?: number, filterBy?: string, searchQuery?: string, extraHttpRequestParams?: any): Observable<Array<LocalNotificationMessage>>;

    /**
     * 
     * 
     * @param filterBy 
     */
    notificationGetNotificationCount(filterBy?: string, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     */
    notificationUpdateLastViewedNotification(extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param notificationId 
     */
    notificationViewNotification(notificationId: string, extraHttpRequestParams?: any): Observable<Notification>;

}
