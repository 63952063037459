
export const BID_STATUS = {
    OPEN: { NAME: 'open', COLOR: '#0D82EF' },
    ACCEPTED: { NAME: 'accepted', COLOR: '#009429' },
    DECLINED: { NAME: 'declined', COLOR: '#EF360D' },
  };
  
  export const PROJECT_STATUS = {
    ALL: { NAME: 'all', COLOR: '#FFCF5C' },
    ACTIVE: { NAME: 'active', COLOR: '#0D82EF' },
    PAUSED: { NAME: 'paused', COLOR: '#6F52ED' },
    ABANDONED: { NAME: 'abandoned', COLOR: '#EF360D' },
    COMPLETED: { NAME: 'completed', COLOR: '#009429' },
    TOTAL: { NAME: 'total', COLOR: '#28147c' }
  };

  export const PROJECT_TASK_STATUS = {
    // NOT_STARTED, IN_PROGRESS, DONE
  };

  export const REQUIREMENT_STATUS = {
    // PENDING, SATISFIED, DECLINED, AWAITING
  };

  export const REQUISITION_STATUS = {
    ALL: { NAME: 'all', COLOR: '#FFCF5C' },
    OPEN: { NAME: 'open', COLOR: '#0D82EF' },
    CONTINGENT: { NAME: 'contingent', COLOR: '#EF790D' },
    PAUSED: { NAME: 'paused', COLOR: '#6F52ED'},
    CLOSED: { NAME: 'closed', COLOR: '#EF360D' },
    AWARDED: { NAME: 'awarded', COLOR: '#009429' }
  };
  
  export const PAYMENT_INSTALLMENT_STATUS = {
    // PAID, PENDING, DECLINED, APPROVED, PROCESSING
  };