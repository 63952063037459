import { Pipe, PipeTransform } from '@angular/core';
import { UserMessageOption } from '../../../../../models';

@Pipe({
  name: 'countUnreadMessages',
  standalone: true,
})
export class CountUnreadMessagesPipe implements PipeTransform {
  transform(value: UserMessageOption[] = []): number {
    const unReadList = value.filter(x => !x.isRead);
    return unReadList.length;
  }
}
