<div class="menu bg-body">
  <ul class="list-group">

    <a [href]="accountWebURL" target="_blank" class="list-group-item list-group-item-action">
      <i [sch-icon]="'card'" class="menu-icon"></i> <span class="ms-2 menu-label">Seech account</span>
    </a>

    <a *ngFor="let item of menuItems" [routerLink]="[item.route !== '' && item.route]"
      (click)="menuItemClick.emit(item)" class="list-group-item list-group-item-action">
      <i [sch-icon]="item.icon!" class="menu-icon"></i> <span class="ms-2 menu-label">{{item.label}}</span>
    </a>

    <hr>
    <a (click)="logOut.emit()" class="list-group-item list-group-item-action text-danger">
      <i [sch-icon]="'logout'" class="menu-icon text-danger"></i> <span
        class="ms-2 menu-label text-danger">Logout</span>
    </a>
  </ul>
</div>
