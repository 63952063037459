/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Review } from '../model/models';
import { ReviewRequest } from '../model/models';
import { ReviewResponse } from '../model/models';
import { RevieweeInfo } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ReviewServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param reviewRequests 
     */
    reviewCreate(reviewRequests: Array<ReviewRequest>, extraHttpRequestParams?: any): Observable<ReviewResponse>;

    /**
     * 
     * 
     * @param projectId 
     * @param page 
     * @param size 
     * @param orderBy 
     */
    reviewGetCurrentUserReviewByProjectId(projectId: string, page?: number, size?: number, orderBy?: string, extraHttpRequestParams?: any): Observable<Array<Review>>;

    /**
     * 
     * 
     * @param reviewerId 
     * @param orderBy 
     */
    reviewGetCurrentUserReviewByReviewerId(reviewerId: string, orderBy?: string, extraHttpRequestParams?: any): Observable<Array<Review>>;

    /**
     * 
     * 
     */
    reviewGetRevieweeSummary(extraHttpRequestParams?: any): Observable<RevieweeInfo>;

    /**
     * 
     * 
     * @param revieweeId 
     * @param badgeId 
     * @param page 
     * @param size 
     * @param orderBy 
     */
    reviewGetReviews(revieweeId: string, badgeId?: string, page?: number, size?: number, orderBy?: string, extraHttpRequestParams?: any): Observable<Array<Review>>;

    /**
     * 
     * 
     * @param reviewerId 
     * @param projectId 
     */
    reviewGetReviewsByReviewerIdProjectId(reviewerId: string, projectId: string, extraHttpRequestParams?: any): Observable<Array<Review>>;

}
