import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ComponentsModule } from '../../general/components/components.module';
import { DashboardHomeComponent } from './components/dashboard-home/dashboard-home.component';
import { IconsNgModule } from '@seech/icons-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { VisualizationsNgModule } from '@seech/visualizations-ng';
import { ScrollEndNotifierDirective, UxNgModule } from '@seech/ux-ng';
import { DashboardServicesComponent } from './components/dashboard-services/dashboard-services.component';
import { ItemsModule } from '../../general/items/items.module';
import { DetailModule } from '../../general/detail/detail.module';
import { InitialsPipe } from '@profindar/shared-ng';
import { BioSummaryComponent } from '../../general/components/bio-summary/bio-summary.component';
import { EmptyStateComponent } from '../../../components/empty-state/empty-state.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardHomeComponent,
    DashboardServicesComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ComponentsModule,
    IconsNgModule,
    ControlsNgModule,
    VisualizationsNgModule,
    UxNgModule,
    ItemsModule,
    DetailModule,
    ScrollEndNotifierDirective,
    InitialsPipe,
    BioSummaryComponent,
    EmptyStateComponent
  ],
})
export class DashboardModule {}
