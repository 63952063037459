/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectPaymentSchedule } from './projectPaymentSchedule';
import { PaymentInstallment } from './paymentInstallment';
import { PaymentScheduleRelationship } from './paymentScheduleRelationship';


export interface PaymentSchedule { 
    projectPaymentSchedule?: ProjectPaymentSchedule;
    id: string;
    status?: string;
    createdOn: Date;
    createdBy: string;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    paymentInstallments: Array<PaymentInstallment>;
    paymentScheduleRelationshipNewPaymentSchedules: Array<PaymentScheduleRelationship>;
    paymentScheduleRelationshipOldPaymentSchedules: Array<PaymentScheduleRelationship>;
}

