import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ProfessionalSummaryItemComponent } from './professional-summary-item/professional-summary-item.component';
import { UserItemComponent } from './user-item/user-item.component';
import { ProjectItemComponent } from './project-item/project-item.component';
import { RequisitionItemComponent } from './requisition-item/requisition-item.component';
import { ServiceItemComponent } from './service-item/service-item.component';
import { TooltipDirective, UxNgModule } from '@seech/ux-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { ReviewModule } from '../review/review.module';
import { ItemsCardLayoutComponent } from './items-card/items-card-layout.component';
import { ButtonDirective } from '@seech/controls-ng';
import { SharedModule } from '../../shared/shared.module';
import { TimeAgoPipe } from '@seech/core-ng';
import { InitialsPipe, SplitStringPipe } from '@profindar/shared-ng';
import { TaskSummaryComponent } from '../detail/components/task-summary/task-summary.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { SummaryItemCheckboxComponent } from './professional-summary-item/summary-item-checkbox/summary-item-checkbox.component';

const COMPONENTS = [
  ProfessionalSummaryItemComponent,
  UserItemComponent,
  ProjectItemComponent,
  RequisitionItemComponent,
  ServiceItemComponent,
  ItemsCardLayoutComponent,
  SummaryItemCheckboxComponent
];
@NgModule({
  imports: [
    CommonModule,
    TooltipDirective,
    UxNgModule,
    ReviewModule,
    IconsNgModule,
    ButtonDirective,
    NgOptimizedImage,
    SharedModule,
    TimeAgoPipe,
    InitialsPipe,
    TaskSummaryComponent,
    SplitStringPipe,
    FormsModule,
    RouterModule,
    ComponentsModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ItemsModule {}
