import { Injectable } from '@angular/core';
import { BidResponseRequest, BidService as BidServiceClient } from '../../../../generated';
import { BidRequest } from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class BidService {
  constructor(private bidServiceClient: BidServiceClient) {}

  /**
   * Withdraws a bid with the specified ID.
   * @param bidId The ID of the bid to be withdrawn.
   * @returns An Observable indicating the result of the bid withdrawal.
   */
  withdrawBid(bidId: string, observe?: 'body') {
    return this.bidServiceClient.bidWithdrawBid(bidId, observe);
  }

  /**
   * Submits a bid to the server.
   * @param bid The bid to be submitted, encapsulated in a `Bid` object.
   * @returns An Observable that resolves to a FileResponse or null,
   * indicating the result of the bid submission.
   */
  bid(bid: BidRequest) {
    return this.bidServiceClient.bidBid(bid);
  }

  /**
   * Retrieves the response for a submitted bid.
   * @param bid The bid for which the response is requested.
   * @returns An Observable that resolves to a FileResponse or null,
   * indicating the server's response to the bid.
   */
  bidResponse(bid: BidResponseRequest) {
    return this.bidServiceClient.bidBidResponse(bid);
  }

  /**
   * Fetches bids by a specific requisition ID.
   * @param requisitionId The ID of the requisition to fetch bids for.
   * @param reqId An additional request ID for the operation, possibly for tracking or logging.
   * @returns An Observable that resolves to a FileResponse or null,
   * containing the bids related to the given requisition ID.
   */
  getBidsByRequisitionId(requisitionId: string) {
    return this.bidServiceClient.bidGetBidsByRequisitionId(requisitionId);
  }

  /**
   * @param page
   * @param size
   * @param query
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getBidsWithRequisition(page?: number, size?: number, query?: string) {
    return this.bidServiceClient.bidGetBidsWithRequisition(page, size, query);
  }

  /**
   * @param requisitionId
   *  @param professionalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getBidSessionForRequisition(requisitionId: string, professionalId: string) {
    return this.bidServiceClient.bidGetBidSessionForRequisition(
      requisitionId,
      professionalId
    );
  }
}
