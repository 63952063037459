/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Associate } from '../model/models';
import { AssociateProject } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface AssociateServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    associateGetAssociates(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Associate>>;

    /**
     * 
     * 
     * @param associateId 
     * @param page 
     * @param size 
     * @param serviceCount 
     */
    associateGetProjectsByAssociateId(associateId: string, page?: number, size?: number, serviceCount?: number, extraHttpRequestParams?: any): Observable<Array<AssociateProject>>;

}
