import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-detail-dialog',
  templateUrl: './service-detail-dialog.component.html',
  styleUrls: ['./service-detail-dialog.component.scss'],
})
export class ServiceDetailDialogComponent {
  @Input({required: true}) viewer!: string;
}
