import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, Coordinates, SignupRequest } from '@profindar/shared-ng';
import { UtilitiesService } from '@seech/core-ng';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  authWebUrl: string = environment.AUTH_WEB_URL;
  signupRequest: SignupRequest = { location: 'New York City, USA', coordinates: <Coordinates>{ coords: '40.7128,-74.0060' } };
  signupToken: string;
  returnUrl: string;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private accountService: AccountService,
    @Inject(PLATFORM_ID) private platformId: Object) {

    this.signupToken = this.route.snapshot.queryParams['token'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (!this.signupToken) {
      const redirectUrl = this.utilitiesService.getOrigin() + (this.returnUrl ? '&returnUrl=' + this.returnUrl : '') + '&action=signup';
      const fullAuthWebUrl = this.authWebUrl + '/login?redirect=' + encodeURIComponent(redirectUrl);

      if (isPlatformBrowser(this.platformId)) {
        window.location.href = fullAuthWebUrl;
      } else {
        // Handle server-side logic for redirects if needed.
        // You might set HTTP headers or do nothing.
      }
    }
  }

  ngOnInit() {
    this.getUserCoordinate();
  }

  getUserCoordinate() {
    // this.geolocationService.GetCurrentPosition().subscribe((coordinate) => {
    //   this.geolocationService.GetGeocode(coordinate, 'coordinates', true).subscribe((result) => {
    //     this.signupRequest.coordinates = coordinate;
    //     this.signupRequest.location = result?.address;
        this.signup();
    //   });

    // });
  }

  signup() {
    if (this.signupToken) {
      this.loading = true;
      this.accountService.signup(this.signupRequest, { headers: { authorization: this.signupToken } })
        .subscribe({
          next: (response) => {
            this.router.navigate(['/login'], { queryParams: { action: 'account-setup' }, queryParamsHandling: "merge" });
          },
          error: (error) => {
            alert('Unable to create account.'); // TODO : Refactor to use alert or toast service
          },
          complete: () => {
            this.loading = false;
          }
        });
    }
  }
}
