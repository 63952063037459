import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardHomeComponent } from './components/dashboard-home/dashboard-home.component';
import { DashboardServicesComponent } from './components/dashboard-services/dashboard-services.component';
import { PaymentsComponent } from '../payments/payments.component';
import { CONSTANT } from '@profindar/shared-ng';

const routes: Routes = [
  { path: CONSTANT.ROUTE.ROOT, component: DashboardHomeComponent },
  {
    path: CONSTANT.ROUTE.SERVICES,
    component: DashboardServicesComponent,
  },
  {
    path: `${CONSTANT.ROUTE.PAYMENTS}`,
    component: PaymentsComponent,
  },
  {
    path: '**',
    redirectTo: `/${CONSTANT.ROUTE.DASHBOARD}/`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
