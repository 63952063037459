import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CreateEditEventComponent } from '../create-edit-event/create-edit-event.component';
import { ModalService } from '@seech/ux-ng';
import { Activity, CONSTANT, Calendar, CalendarService, CalendarServiceService, DateRange, JobCard, ProfileService } from '@profindar/shared-ng';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { ActivitySummaryComponent } from '../activity-summary/activity-summary.component';

@Component({
  selector: 'app-calendar-day-view',
  templateUrl: './calendar-day-view.component.html',
  styleUrls: ['./calendar-day-view.component.scss'],
})
export class CalendarDayViewComponent implements OnInit, OnDestroy {
  @Output() switchView = new EventEmitter<'day' | 'week'>();
  daysOfTheWeek = CONSTANT.DAYS_OF_THE_WEEK;
  hoursInADay = CONSTANT.HOURS_IN_A_DAY;
  currentDate: Date = new Date();
  sub: Subscription = new Subscription();
  calendarProjects!: Calendar[];
  @Input() userId!: string;
  @Input() isGuest!: boolean;

  constructor(
    private modalService: ModalService,
    private calendarService: CalendarServiceService,
    private calendarApiService: CalendarService,
    private profileService: ProfileService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.getCalendarProjects();
  }
  
  getCalendarProjects(){
    const startDate = this.currentDate;
    const endDate = this.currentDate;
    const endpoint = this.isGuest ? this.profileService.getCalendarProjects(this.userId, startDate, endDate)
    : this.calendarApiService.getCurrentUserCalendarProjects(startDate, endDate);
    this.sub.add(
      endpoint.subscribe((res: Calendar[]) => {
        this.calendarProjects = res;
      })
    );
  }

  get displayIsMobile(){
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  get gridTemplateRows(): string {
    const rowSize = this.displayIsMobile ? '65px' : '140px';
    return `45px repeat(${this.calendarProjects?.length ?? 3}, ${rowSize})`;
  }

  openCreateEventModal(action: 'create' | 'view', projectId?: string, data?: Activity) {
    const modalRef = this.modalService.open(CreateEditEventComponent, {
      data: '',
      modalClass: 'modal-dialog-centered',
    });
    modalRef.component.eventView = action;
    modalRef.component.data = data;
    modalRef.component.projectId = projectId;
  }

  openTimelineModal(id: string) {
    const modalRef = this.modalService.open(ActivitySummaryComponent, {
      modalClass: 'modal-dialog-centered',
    });
    
    modalRef.component.projectId = id;
  }

  goToNextDay() {
    this.currentDate = this.calendarService.goToNextDay(this.currentDate);
  }
  goToPreviousDay() {
    this.currentDate = this.calendarService.goToPreviousDay(this.currentDate);
  }

  plotJobOnCalendar(hour: number, project: Calendar): JobCard | null {
    const date = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      hour
    );
    const d = new Date(this.currentDate);
    const currentViewDateRange: DateRange = {
      start: new Date(new Date(d).setHours(0, 0, 0, 0)),
      end: new Date(new Date(d).setHours(23, 59, 59, 9999)),
    };
    return this.calendarService.plotJobOnCalendar(
      date,
      project,
      'day',
      currentViewDateRange
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
