<!-- items-card-layout.component.html -->
<div class="items-card-layout border py-2 px-2  gap-[10px] rounded-[5px]">
  <div class="card-image-section" [style.width]="imageWidth">
    <ng-content select="[card-image-section]"></ng-content>
  </div>

  <div class="card-texts-section flex items-center" [style.width]="textsWidth">
    <ng-content select="[card-texts-section]"></ng-content>
  </div>

  <div class="card-action-section h-fit flex items-center" [style.width]="actionsWidth">
    <ng-content select="[card-actions-section]"></ng-content>
  </div>
</div>
