/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ProjectTaskRequest } from '../model/models';
import { RequestedTask } from '../model/models';
import { RequestedTask2 } from '../model/models';
import { RequestedTaskGroup } from '../model/models';
import { RequisitionTaskRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface TaskServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param requestedTask 
     */
    taskCreateRequisitionTask(requestedTask: RequisitionTaskRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param id 
     */
    taskDeleteRequisitionTask(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param projectId 
     */
    taskGetGroupedProjectTasks(projectId: string, extraHttpRequestParams?: any): Observable<Array<RequestedTaskGroup>>;

    /**
     * 
     * 
     * @param projectId 
     */
    taskGetTasksByProjectId(projectId: string, extraHttpRequestParams?: any): Observable<Array<RequestedTask2>>;

    /**
     * 
     * 
     * @param requisitionId 
     */
    taskGetTasksByRequisitionId(requisitionId: string, extraHttpRequestParams?: any): Observable<Array<RequestedTask>>;

    /**
     * 
     * 
     * @param requestedTask 
     */
    taskUpdateRequisitionTask(requestedTask: RequestedTask, extraHttpRequestParams?: any): Observable<RequestedTask>;

    /**
     * 
     * 
     * @param request 
     */
    taskUpdateRequisitionTaskRank(request: RequisitionTaskRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param request 
     */
    taskUpdateTaskStatusAndRank(request: ProjectTaskRequest, extraHttpRequestParams?: any): Observable<{}>;

}
