/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeometryFactory } from './geometryFactory';
import { PrecisionModel } from './precisionModel';
import { Point } from './point';
import { Envelope } from './envelope';


export interface Geometry { 
    factory?: GeometryFactory;
    userData?: object;
    srid: number;
    precisionModel?: PrecisionModel;
    numGeometries: number;
    isSimple: boolean;
    isValid: boolean;
    area: number;
    length: number;
    centroid?: Point;
    interiorPoint?: Point;
    pointOnSurface?: Point;
    envelope?: Geometry;
    envelopeInternal?: Envelope;
    isRectangle: boolean;
}

