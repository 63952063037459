/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ProfessionalSummary } from '../model/models';
import { Project } from '../model/models';
import { ProjectOfAbandonedProjectData } from '../model/models';
import { ProjectOfActiveProjectData } from '../model/models';
import { ProjectOfCompletedProjectData } from '../model/models';
import { ProjectOfPausedProjectData } from '../model/models';
import { ProjectOfProjectInterface } from '../model/models';
import { ProjectResponse } from '../model/models';
import { RequestedTask2 } from '../model/models';
import { StatusSummaryResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProjectServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param id 
     */
    projectGetAbandonedProjectByIdForParticipant(id: string, extraHttpRequestParams?: any): Observable<ProjectOfAbandonedProjectData>;

    /**
     * 
     * 
     * @param id 
     */
    projectGetActiveProjectByIdForParticipant(id: string, extraHttpRequestParams?: any): Observable<ProjectOfActiveProjectData>;

    /**
     * 
     * 
     * @param id 
     */
    projectGetCompletedProjectByIdForParticipant(id: string, extraHttpRequestParams?: any): Observable<ProjectOfCompletedProjectData>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     * @param serviceCount 
     * @param projectStatus 
     */
    projectGetCurrentUserProjects(query?: string, page?: number, size?: number, serviceCount?: number, projectStatus?: string, extraHttpRequestParams?: any): Observable<Array<ProjectResponse>>;

    /**
     * 
     * 
     * @param service 
     * @param page 
     * @param size 
     */
    projectGetMatchedProfessionals(service: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProfessionalSummary>>;

    /**
     * 
     * 
     * @param id 
     */
    projectGetPausedProjectByIdForParticipant(id: string, extraHttpRequestParams?: any): Observable<ProjectOfPausedProjectData>;

    /**
     * 
     * 
     */
    projectGetProjectCountByStatus(extraHttpRequestParams?: any): Observable<Array<StatusSummaryResponse>>;

    /**
     * 
     * 
     * @param id 
     */
    projectGetProjectDetailsById(id: string, extraHttpRequestParams?: any): Observable<ProjectOfProjectInterface>;

    /**
     * 
     * 
     * @param projectId 
     */
    projectGetRequestedTaskByProjectId(projectId: string, extraHttpRequestParams?: any): Observable<Array<RequestedTask2>>;

    /**
     * 
     * 
     * @param requisitionId 
     */
    projectReverseProjectCreation(requisitionId: string, extraHttpRequestParams?: any): Observable<Project>;

    /**
     * 
     * 
     * @param projectId 
     * @param projectStatusId 
     */
    projectUpdateProjectByStatusId(projectId: string, projectStatusId: string, extraHttpRequestParams?: any): Observable<Project>;

}
