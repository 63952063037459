import { Route } from '@angular/router';
import { CONSTANT } from './constants';
import { DashboardComponent } from './modules/features/dashboard';
import { LoginComponent } from './auth/components/login/login.component';
import { SignupComponent } from './auth/components/signup/signup.component';
import { AppResolver } from './resolvers/app.resolver';
import { layoutGuard } from './layout/guards/layout.guard';
import { LogoutComponent } from './auth/components/logout/logout.component';

export const protectedRoutes: Route[] = [
  {
    path: CONSTANT.ROUTE.HOME,
    canMatch: [layoutGuard],
    data: {
      layout: {
        showSideNav: false,
      },
    },
    loadChildren: () =>
      import('./modules/features/home/home.module').then((x) => x.HomeModule),
  },
  {
    path: `${CONSTANT.ROUTE.PROFILE}/:username`,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/profile/profile.module').then(
        (x) => x.ProfileModule
      ),
  },

  {
    path: CONSTANT.ROUTE.DASHBOARD,
    component: DashboardComponent,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/dashboard/dashboard.module').then(
        (x) => x.DashboardModule
      ),
  },

  {
    path: CONSTANT.ROUTE.ASSOCIATES,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/associates/associates.module').then(
        (x) => x.AssociatesModule
      ),
  },

  {
    path: CONSTANT.ROUTE.PAYMENTS,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/payments/payments.module').then(
        (x) => x.PaymentsModule
      ),
  },

  {
    path: CONSTANT.ROUTE.CALENDAR,
    canMatch: [layoutGuard],
    data: {
      layout: {
        showSideNav: false,
      },
    },
    loadChildren: () =>
      import('./modules/features/calendar/calendar.module').then(
        (x) => x.CalendarModule
      ),
  },

  {
    path: CONSTANT.ROUTE.JOB,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/job/job.module').then((x) => x.JobModule),
  },

  {
    path: CONSTANT.ROUTE.MESSAGE,
    canMatch: [layoutGuard],
    data: {
      layout: {
        showSideNav: false,
        showFooter: false,
      },
    },
    loadChildren: () =>
      import('./modules/features/message/message.module').then(
        (x) => x.MessageModule
      ),
  },

  {
    path: CONSTANT.ROUTE.NOTIFICATION,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/notification/notification.module').then(
        (x) => x.NotificationModule
      ),
  },

  {
    path: CONSTANT.ROUTE.PROJECT,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/projects/projects.module').then(
        (x) => x.ProjectsModule
      ),
  },

  {
    path: CONSTANT.ROUTE.SEARCH,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/search/search.module').then(
        (x) => x.SearchModule
      ),
  },
  {
    path: CONSTANT.ROUTE.USER,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/user/user.module').then((x) => x.UserModule),
  },
  {
    path: CONSTANT.ROUTE.REQUISITION,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/requisition/requisition.module').then(
        (x) => x.RequisitionModule
      ),
  },
  {
    path: CONSTANT.ROUTE.PRO,
    canMatch: [layoutGuard],
    loadChildren: () =>
      import('./modules/features/professional/professional.module').then(
        (x) => x.ProfessionalModule
      ),
  },

  // otherwise redirect to home
  { path: '**', redirectTo: CONSTANT.ROUTE.HOME, pathMatch: 'full' }, // route not found
];

export const authRoutes: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'logout',
    component: LogoutComponent,
  },

  {
    path: 'signup',
    component: SignupComponent,
  },

  {
    path: '',
    redirectTo: '/login',
    resolve: { action: AppResolver.resolve },
    pathMatch: 'full',
  }, // redirect happens in the AppResolver
];
