import { Pipe, PipeTransform } from '@angular/core';
import { UserSummary } from '../../models';

@Pipe({
  name: 'concatenateUserNames',
  standalone: true,
})
export class ConcatenateUserNamesPipe implements PipeTransform {
  transform(value: UserSummary[] | undefined, userId: string): string {
    if(!value) return '';
    const nameList = value.filter(x => x.id !== userId).map(x => x.name);

    return nameList.join(', ');
  }
}
