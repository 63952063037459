<div class="meeting">
    <ng-container *ngIf="data; else loadingState">
        <div class="meeting-top">
            <span class="profile-pic" [appUserImage]="data.client"></span>
    
            <div class="info">
                <p class="name"> {{ data.client.name }} </p>
                <p class="job">{{ data.description }}</p>
            </div>
    
            <span [sch-icon]="'close'" class="close" (click)="closeModal()"></span>
        </div>
    
        <div class="meeting-body">
            <div class="item">
                <p>Client:</p>
                <p class="text-dark"> {{ data.client.name }} </p>
            </div>
            <div class="item">
                <p>Project:</p>
                <p class="text-dark"> {{ data.description }} </p>
            </div>
            <div class="item">
                <p>Time:</p>
                <p class="text-dark"> {{ (data.startedOn | date:'shortTime') + '- ' + (data.proposedEndDate | date:'shortTime') }} </p>
            </div>
            <div class="item">
                <!-- <p>Phone:</p> -->
                <p>Email:</p>
                <p class="text-dark"> {{ data.client.email }} </p>
            </div>
            <div class="item">
                <p>Address:</p>
                <p class="text-dark"> {{ data.location }} </p>
            </div>
            <div class="item">
                <p>Starts:</p>
                <p class="text-dark"> {{ data.startedOn | date:'mediumDate' }} </p>
            </div>
            <div class="item">
                <p>Ends:</p>
                <p class="text-dark"> {{ data.proposedEndDate | date:'mediumDate' }} </p>
            </div>
        </div>
    
        <div class="meeting-bottom">
            <button sch-button shape="rounded" class="!bg-secondary !shadow-none !capitalize">View Project</button>
        </div>
    </ng-container>

    <ng-template #loadingState>
        <div class="meeting-loading">
            <seech-spinner id="project-loader" [color]="'secondary'">
          </seech-spinner>
        </div>
    </ng-template>
</div>
