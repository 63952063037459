/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrecisionModels } from './precisionModels';


export interface PrecisionModel { 
    isFloating: boolean;
    maximumSignificantDigits: number;
    scale: number;
    gridSize: number;
    precisionModelType: PrecisionModels;
}
export namespace PrecisionModel {
}


