/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BlobMetaData { 
    isPrimary?: boolean;
    newUpload?: boolean;
    id: string;
    appId?: string;
    parentId: string;
    tempContainer?: string;
    blobCategory: string;
    url?: string;
    originalName?: string;
    modifiedName?: string;
    extension?: string;
    createdOn: Date;
    createdBy: string;
    title?: string;
    altText?: string;
    caption?: string;
    isDeleted?: boolean;
}

