<div>
  <hr class="divider" />
  <div class="w-100 mobile-navigation bg-primary-dark">

    <div class="w-100 d-flex justify-content-between h-100">
      <ng-container *ngFor="let navigation of navigationItems">

        <a *ngIf="navigation.route" [routerLink]="navigation.route" [routerLinkActive]="'link-active'"
          class="d-flex flex-column align-items-center" (click)="itemClick.emit(navigation)">
          <img *ngIf="navigation.imageUrl; else icon" [src]="navigation.imageUrl" width="20px" height="20px">
          <ng-template #icon>
            <i [sch-icon]="navigation.icon!" class="icon"></i>
          </ng-template>
          <span class="label">{{navigation.label}}</span>
        </a>

        <button *ngIf="navigation.action" class="d-flex flex-column align-items-center"
          (click)="navigation.action(); itemClick.emit(navigation)">
          <img *ngIf="navigation.imageUrl; else icon" [src]="navigation.imageUrl" width="20px" height="20px">
          <ng-template #icon>
            <i [sch-icon]="navigation.icon!" class="icon"></i>
          </ng-template>
          <span class="label">{{navigation.label}}</span>
        </button>

      </ng-container>

    </div>
  </div>
</div>
