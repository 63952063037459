import { ChangeDetectionStrategy, Component} from '@angular/core';
import { SideNavItemsComponent } from '../side-nav-items/side-nav-items.component';

@Component({
  selector: 'app-mobile-side-nav-items',
  templateUrl: './mobile-side-nav-items.component.html',
  styleUrls: ['./mobile-side-nav-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSideNavItemsComponent extends SideNavItemsComponent {
}
