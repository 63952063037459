
export interface Project {
    title: string[];
    price: number; // Assuming 'price' is a numeric value. If it includes currency symbols, consider using string instead
    address: string;
    description: string[];
    count?:number
  }
  export interface ProjectConfig {
    currency?: string;
    active?: boolean;
  }

  export interface ProjectItem {
    icon: string;
    iconLabel: string;
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    status: string | any;
    location: string;
    description: string[]
    price: number | string; // Use number if you handle price numerically, or string if it includes currency symbols or units
    startDate: string; // Or Date, if you prefer to work with Date objects
    verified?: boolean; // Optional property based on your HTML, assuming not all projects might have this property
  }
  export interface ProfileProjectItem {
    title: string;
    location: string;
    description: string;
    price: number | string; // Use number if you handle price numerically, or string if it includes currency symbols or units
    startDate: string; // Or Date, if you prefer to work with Date objects
  }
  export interface ProjectItemConfig {
    buttonLabel?: string;
    currency?: string; // Assuming currency might be optional and used for formatting the price
  }
