/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Message } from './message';
import { Participant } from './participant';
import { UserMessageOption } from './userMessageOption';
import { ProjectThread } from './projectThread';


export interface Thread { 
    id: string;
    createdOn: Date;
    updatedOn: Date;
    isStandalone?: boolean;
    blockedOn?: Date;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    messages: Array<Message>;
    participants: Array<Participant>;
    projectThreads: Array<ProjectThread>;
    userMessageOptions: Array<UserMessageOption>;
}

