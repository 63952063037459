/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequirementAnswer } from './requirementAnswer';
import { ProjectRole } from './projectRole';
import { Requisition } from './requisition';


export interface Requirement { 
    projectRole: ProjectRole;
    id: string;
    requisitionId: string;
    question: string;
    isOptional?: boolean;
    createdOn: Date;
    createdBy: string;
    createdForRole: string;
    requirementAnswers: Array<RequirementAnswer>;
    requisition: Requisition;
}

