/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { Coordinates } from './coordinates';


export interface RequisitionResponse { 
    id: string;
    referenceId?: string;
    budget?: number;
    createdBy: string;
    createdOn: Date;
    proposedStartDate?: Date;
    proposedEndDate?: Date;
    expirationDate?: Date;
    professionalLocation?: boolean;
    clientLocation?: boolean;
    isRemote?: boolean;
    isMobile?: boolean;
    currency?: string;
    location?: string;
    description?: string;
    hasBid: boolean;
    isSaved: boolean;
    payRateType?: string;
    status?: string;
    statusId: string;
    services: Array<string>;
    tasks: Array<string>;
    coordinates: Coordinates;
    geolocation?: Geometry;
}

