import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CONSTANT } from '../../../constants';
import { User } from '@profindar/shared-ng';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent {
  @Output() readonly menuClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly profileClick: EventEmitter<void> =
    new EventEmitter<void>();
  @Input() user: User | null = null;
  @Input({ required: true }) nameLabel = '';
  @Input({ required: true }) unreadNotificationCount = 0;
  @Input({ required: true }) unreadMessagesCount = 0;
  route = CONSTANT.ROUTE;
}
