import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '@profindar/shared-ng';

@Component({
  selector: 'app-create-bottom-sheet',
  templateUrl: './create-bottom-sheet.component.html',
  styleUrls: ['./create-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateBottomSheetComponent {
  @Input({ required: true }) user: User | null = null;
  @HostBinding('style.width')
  width = '100%';
  @Output() readonly sheetItemClick: EventEmitter<void> =
    new EventEmitter<void>();
  cdnBaseUrl = environment.cdnBaseUrl;
  @Output() readonly newProjectClick: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() readonly becomeAProClick: EventEmitter<void> =
    new EventEmitter<void>();
}
