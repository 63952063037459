/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { User } from './user';
import { ProfessionalSkill } from './professionalSkill';
import { Coordinates } from './coordinates';
import { ProfessionalBadge } from './professionalBadge';
import { PayRateType } from './payRateType';
import { Service } from './service';


export interface ProfessionalService { 
    coordinates: Coordinates;
    totalProjectByStatus: { [key: string]: number; };
    payRateType: PayRateType;
    service: Service;
    id: string;
    serviceId: string;
    description?: string;
    isRemote?: boolean;
    professionalLocation?: boolean
    clientLocation?: boolean
    isMobile?: boolean;
    currency?: string;
    rate?: number;
    rateTypeId?: string;
    reviewScore?: number;
    reviewCount?: number;
    location?: string;
    geolocation?: Geometry;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    createdOn: Date;
    createdBy: string;
    createdByNavigation: User;
    professionalBadges: Array<ProfessionalBadge>;
    professionalSkills: Array<ProfessionalSkill>;
}

