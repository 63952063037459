/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Activity } from '../model/models';
import { ActivityRequest } from '../model/models';
import { Calendar } from '../model/models';
import { ProjectResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CalendarServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param activity 
     */
    calendarAddProjectActivity(activity: ActivityRequest, extraHttpRequestParams?: any): Observable<Activity>;

    /**
     * 
     * 
     * @param id 
     */
    calendarDeleteProjectActivity(id: string, extraHttpRequestParams?: any): Observable<Activity>;

    /**
     * 
     * 
     * @param startDate 
     * @param endDate 
     */
    calendarGetCurrentUserCalendarProjects(startDate?: Date, endDate?: Date, extraHttpRequestParams?: any): Observable<Array<Calendar>>;

    /**
     * 
     * 
     * @param id 
     */
    calendarGetEventProjectDetails(id: string, extraHttpRequestParams?: any): Observable<ProjectResponse>;

    /**
     * 
     * 
     * @param activity 
     */
    calendarUpdateProjectActivity(activity: ActivityRequest, extraHttpRequestParams?: any): Observable<Activity>;

}
