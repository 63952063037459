import { dev } from '@profindar/shared-ng';

export const environment = {
  ...dev.environment,

  // all additional properties should come after this line
  AUTH_API_URL: 'https://dev-api-auth-seech.azurewebsites.net/api/',
  AUTH_WEB_URL: 'https://dev-auth.seech.com',
  ACCOUNT_WEB_URL: 'https://dev-account.seech.com',
  cdnBaseUrl: 'https://dev-cdn.profindar.com/web/',
  realTimeHubUrl: 'https://dev-api-profindar.azurewebsites.net/centralsocket',
  BASE_API_URL: 'https://dev-api-profindar.azurewebsites.net',
  CLIENT_ID: 'FFE5ADB6-3751-49E4-ADC6-A782B0E80152',
  GOOGLE_API_KEY: 'AIzaSyBXozDBQk-HzM3s9bzeTc5XfvVGaYLNvnA',
  GOOGLE_ANALYTICS_ID: '',
};
