export * from './account.service';
import { AccountService } from './account.service';
export * from './account.serviceInterface';
export * from './associate.service';
import { AssociateService } from './associate.service';
export * from './associate.serviceInterface';
export * from './bid.service';
import { BidService } from './bid.service';
export * from './bid.serviceInterface';
export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './calendar.serviceInterface';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './dashboard.serviceInterface';
export * from './global.service';
import { GlobalService } from './global.service';
export * from './global.serviceInterface';
export * from './home.service';
import { HomeService } from './home.service';
export * from './home.serviceInterface';
export * from './lookup.service';
import { LookupService } from './lookup.service';
export * from './lookup.serviceInterface';
export * from './message.service';
import { MessageService } from './message.service';
export * from './message.serviceInterface';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './notification.serviceInterface';
export * from './paymentInstallment.service';
import { PaymentInstallmentService } from './paymentInstallment.service';
export * from './paymentInstallment.serviceInterface';
export * from './paymentSchedule.service';
import { PaymentScheduleService } from './paymentSchedule.service';
export * from './paymentSchedule.serviceInterface';
export * from './professionalService.service';
import { ProfessionalServiceService } from './professionalService.service';
export * from './professionalService.serviceInterface';
export * from './professionalSummary.service';
import { ProfessionalSummaryService } from './professionalSummary.service';
export * from './professionalSummary.serviceInterface';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './profile.serviceInterface';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './project.serviceInterface';
export * from './requirement.service';
import { RequirementService } from './requirement.service';
export * from './requirement.serviceInterface';
export * from './requisition.service';
import { RequisitionService } from './requisition.service';
export * from './requisition.serviceInterface';
export * from './review.service';
import { ReviewService } from './review.service';
export * from './review.serviceInterface';
export * from './saved.service';
import { SavedService } from './saved.service';
export * from './saved.serviceInterface';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './settings.serviceInterface';
export * from './status.service';
import { StatusService } from './status.service';
export * from './status.serviceInterface';
export * from './task.service';
import { TaskService } from './task.service';
export * from './task.serviceInterface';
export * from './user.service';
import { UserService } from './user.service';
export * from './user.serviceInterface';
export const APIS = [AccountService, AssociateService, BidService, CalendarService, DashboardService, GlobalService, HomeService, LookupService, MessageService, NotificationService, PaymentInstallmentService, PaymentScheduleService, ProfessionalServiceService, ProfessionalSummaryService, ProfileService, ProjectService, RequirementService, RequisitionService, ReviewService, SavedService, SettingsService, StatusService, TaskService, UserService];
