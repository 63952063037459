/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSummary } from './userSummary';
import { Bid } from './bid';
import { RequisitionResponse } from './requisitionResponse';


export interface BidResponse { 
    professionalBid: Bid;
    clientBid: Bid;
    professionalUserInfo: UserSummary;
    requisitionResponse: RequisitionResponse;
}

