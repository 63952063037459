import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { CanActivateGuard } from './guards/can-activate.guard';
import { Router } from '@angular/router';
import { FeatureFlagService } from '@seech/core-ng';
import { AuthService } from '../services/auth.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthRoutingModule
  ],
  providers: [
    {
      provide: 'canActivateGuard',
      useFactory: CanActivateGuard.guard,
      deps: [Router, AuthService, FeatureFlagService]
    }
  ]
})
export class AuthModule { }
