/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AssociateProject { 
    id: string;
    currency?: string;
    projectStatus?: string;
    budget?: number;
    referenceId?: string;
    projectServices?: Array<string>;
}

