import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconsNgModule } from '@seech/icons-ng';
import { UxNgModule } from '@seech/ux-ng';

@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss'],
  standalone: true,
  imports: [CommonModule, IconsNgModule, UxNgModule],
})
export class TaskSummaryComponent {
  @Input() tasks: string[] = [];
  //Below are the css supported properties for overflow
  @Input() overflowY: 'scroll' | 'hidden' | 'visible' | 'auto' = 'hidden';
  @Input() displayLimit: number = 8;
  @Input() autoHeight: boolean = false;

  displayTasks(): string {
    return this.tasks.join(', ');
  }
}
