<ng-container *ngIf="profile; else loadingState">
  <div class="profile-info">
    <div class="profile-info-left">
      <div class="img-container">
        <span class="profile-pic" [appUserImage]="convertUserProfileToUser(profile)" (click)="viewProfile.emit('view')"></span>
  
        <ng-container *ngIf="isOwner && isProfile">
          <div class="edit" (click)="viewProfile.emit('edit')">
            <i [sch-icon]="'edit-box'" class="edit-icon"></i>
            <p class="edit-text"> Edit </p>
          </div>
        </ng-container>
      </div>
  
      <div class="details">
        <div class="d-flex align-items-center gap-2">
          <p class="details-name"> {{ profile.name }}</p>
  
          <i [sch-icon]="'star-badge-fill'" class="badge"></i>
        </div>
  
        <div class="d-flex align-items-start gap-2" 
        *ngIf="profile.servicesSummary as servicesSummary">
          <p class="details-job"> {{ servicesSummary.slice(0, 2).join(', ') }} </p>
          <i [sch-icon]="'more-horizontal-fill'" class="details-icon" 
          *ngIf="servicesSummary.length > 2"></i>
        </div>
  
        <div class="d-flex align-items-center gap-1" *ngIf="profile.coordinates?.location">
          <i [sch-icon]="'location-outline'" class="location-icon"></i> 
          <p class="details-location"> {{ profile.coordinates.location }} </p>
        </div>
  
        <div class="badge-container" *ngIf="profile.badgesSummary as badgesSummary">
          <ng-container *ngFor="let badge of badgesSummary | slice:0:badgeCount">
            <app-badge [label]="badge"></app-badge>
          </ng-container>
        </div>
      </div>
    </div>
  
    <div class="profile-info-right">
      <button
        size="lg"
        sch-button
        fill="solid"
        [id]="'back'"
        class="!shadow-none"
        (click)="navigateToRoute()"
      >
        <p class="d-none d-sm-flex"> {{ isOwner ? 'View' : 'Hire' }} {{ buttonLabel }}</p>
        <p class="d-flex d-sm-none">{{ isOwner ? buttonLabel : 'Hire' }}</p>
      </button>
  
      <span>Member since {{ profile.createdOn | date:'yyyy' }}</span>
  
      <p class="d-flex align-items-center mb-1 pointer" (click)="viewOverallReview()">
        <app-profindar-star-rating [rate]="profile.reviewScore" [disabled]="true"></app-profindar-star-rating>
        <span>({{ profile.reviewCount }})</span>
      </p>
      <span>Completed Jobs: {{ profile.totalCompletedJobs }} </span>
    </div>
  </div>
</ng-container>

<ng-template #loadingState>
  <div class="profile-info">
    <div class="profile-info-left">
      <div class="img-container">
        <span class="profile-pic border-0">
          <seech-placeholder [width]="'100%'" [height]="'100%'" [shape]="'circle'"></seech-placeholder>
        </span>
      </div>
  
      <div class="details">
        <div class="d-flex align-items-center gap-2">
          <p class="details-name"> 
            <seech-placeholder [width]="'120px'" [height]="'20px'"></seech-placeholder>
          </p>
        </div>
  
        <div class="d-flex align-items-start gap-2">
          <p class="details-job"> 
            <seech-placeholder [width]="'100px'" [height]="'16px'"></seech-placeholder>
          </p>
        </div>
  
        <div class="d-flex align-items-center gap-1">
          <p class="details-location"> 
            <seech-placeholder [width]="'150px'" [height]="'14px'"></seech-placeholder>
          </p>
        </div>
  
        <div class="badge-container">
          <ng-container *ngFor="let badge of [0, 1, 2, 3] | slice:0:badgeCount">
            <seech-placeholder [width]="'50px'" [height]="'20px'"></seech-placeholder>
          </ng-container>
        </div>
      </div>
    </div>
  
    <div class="profile-info-right">
      <seech-placeholder [width]="'140px'" [height]="'25px'"></seech-placeholder>
  
      <span>
        <seech-placeholder [width]="'110px'" [height]="'12px'"></seech-placeholder>
      </span>
  
      <p class="d-flex align-items-center mb-1">
        <seech-placeholder [width]="'90px'" [height]="'12px'"></seech-placeholder>
      </p>
  
      <span>
        Completed Jobs: <seech-placeholder [width]="'20px'" [height]="'12px'"></seech-placeholder>
      </span>
    </div>
  </div>
</ng-template>
