/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-profindar-star-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true,
    },
  ],
})
export class StarRatingComponent implements OnInit, ControlValueAccessor {
  @Input() rate: number = 0;
  @Input() starCount: number = 5;
  @Input() reviewCount?: string;
  @Input() disabled: boolean = false; 

  stars: number[] = [];

  private onChange: (value: number) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnInit(): void {
    this.stars = Array(this.starCount).fill(0).map((x, i) => i + 1);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.rate = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(value: number): void {
    this.rate = value;
    this.onChange(value);
    this.onTouched();
  }

  setRating(star: number): void {
    if (this.rate === star) {
      this.onValueChange(0); // Reset to zero if the same star is clicked
    } else {
      this.onValueChange(star);
    }
  }
}
