<div class="details" [class.for-modal]="isModal">
    <div class="details-first">
        <div>
            <p class="job">Plumbing</p>

            <app-profindar-star-rating [rate]="4" reviewCount="(25 Reviews)" [disabled]="true"></app-profindar-star-rating>

            <div class="badge-container">
                <app-badge backgroundColor="#0D82EF" label="In Demand"></app-badge>
                <app-badge backgroundColor="#2FC17B" label="Experienced"></app-badge>
                <app-badge backgroundColor="#28147C" label="Reliable"></app-badge>
            </div>
        </div>
        <div class="flex align-stretch">
            <button class="button-save" [sch-icon]="'bookmark'"></button>
            <button class="button-hire"> {{ viewer === pageViewer.OWNER ? 'Edit' : 'Hire' }} </button>
        </div>
    </div>

    <div class="details-body" [class.details-body-modal]="isModal">

        <div class="details-second">
            <p class="job-details">Service Information</p>
        </div>

        <div class="details-third">
            <div class="item">
                <p class="item-title">Pay rate</p>
                <div class="item-name">$1200 / Hour</div>
            </div>
            <div class="item">
                <p class="item-title">Skills</p>
                <div class="item-list">
                    <div class="item-name" *ngFor="let skills of skills | slice:0:6">{{ skills }}</div>
                    <div class="item-more" *ngIf="skills.length > 6">... more ({{skills.length - 6}})</div>
                </div>
            </div>
        </div>

        <div class="details-fourth">
            <div class="item">
                <p class="item-title">Job type</p>
                <div class="item-list">
                    <div class="item-name">Onsite</div>
                    <div class="item-name">Remote</div>

                </div>
            </div>

            <p class="title">Location</p>
            <p class="address">
                <span [sch-icon]="'location-fill'"></span>
                Lagos Island, Roadster Avenue.
            </p>
        </div>

        <div class="details-fifth">
            <p class="title">Description</p>
            <p class="desc">
                We're looking for a skilled Plumbing Pro to enhance our building water work Our agency, must be able to
                work fast and deliver before deadli-ne, If you fit in this job post description we want to work with
                you.
            </p>
        </div>

        <div class="details-sixth">
            <p class="title">
                Experience
            </p>

            <div class="categories">
                <button class="category active">
                    <div class="icon-wrapper">
                        <span [sch-icon]="'folder'"></span>
                    </div>
                    Active (1)
                </button>
                <button class="category completed">
                    <div class="icon-wrapper">
                        <span [sch-icon]="'folder'"></span>
                    </div>
                    Completed (12)
                </button>
                <button class="category abandoned">
                    <div class="icon-wrapper">
                        <span [sch-icon]="'folder'"></span>
                    </div>
                    Abandoned (3)
                </button>
            </div>

            <div class="d-flex justify-content-between align-items-center title">
                <p>Gallery</p>
                <p class="text-secondary">(+3)</p>
            </div>

            <div class="gallery">
                <ng-container *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8] | slice:0:galleryCount">
                    <div class="gallery-item">
                        <img src="{{cdnBaseUrl}}temp/gallery-item1.png" alt="images">
                        <div class="body">
                            <p class="body-title">Plumbing</p>
                            <button class="body-btn">view</button>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>

    </div>
</div>